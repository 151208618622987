import { combineReducers } from 'redux'
import searchFormReducer from './searchFormReducer'
import filtersReducer from './filtersReducer'
import flightReducer from './flightReducer'
import toastReducer from './toastReducer'
import loadingReducer from './loadingReducer'
import popupReducer from './popupReducer'
import saveBookingReducer from './saveBookingReducer'
import listFlightReducer from './listFlightReducer'
import listBookingReducer from './listBookingReducer'
import useProfileReducer from './useProfileReducer'
import saveTemplateReducer from './saveTemplateReducer'

import flightEnquiryReducer from './flightEnquiryReducer'
import listUserReducer from './listUserReducer'
import listTemplateNamesReducer from './listTemplateNamesReducer'
import manageTemplateReducer from './manageTemplateReducer'
import authReducer from './authReducer'
import validateBookingReducer from './validateBookingReducer'
import updateBookingReducer from './updateBookingReducer'
import saveDraftReducer from './saveDraftReducer'
import listDraftsReducer from './listDraftsReducer'
import listQuoteReducer from './listQuoteReducer'
import saveCommodityReducer from './saveCommodityReducer'
import saveAirportMasterReducer from './saveAirportMasterReducer'
import saveServiceMasterReducer from './saveServiceMasterReducer'
import saveProductMasterReducer from './saveProductMasterReducer'
import saveSccMasterReducer from './saveSccMasterReducer'
import saveTermsMasterReducer from './saveTermsMasterReducer'
import saveUldTypeMasterReducer from './saveUldTypeMasterReducer'
import savePivotInfoMasterReducer from './savePivotInfoMasterReducer'
import saveSccIncompatibleMasterReducer from './saveSccIncompatibleMasterReducer'
import saveStationScheduleMasterReducer from './saveStationScheduleMasterReducer'
import saveRegionMasterReducer from './saveRegionMasterReducer'
import saveCountryMasterReducer from './saveCountryMasterReducer'
import csnDashboardReducer from './csnDashboardReducer'
import aviBookingReducer from './aviBookingReducer'
import dgBookingReducer from './dgBookingReducer'
import saveEmbargoMasterReducer from './saveEmbargoMasterReducer'
import seasonalBookingReducer from './seasonalBookingReducer'
import gsaAgentReducer from './gsaAgentReducer'

const rootReducer = () =>
	combineReducers({
		auth: authReducer,
		listFlight: listFlightReducer,
		searchForm: searchFormReducer,
		filters: filtersReducer,
		flight: flightReducer,
		toast: toastReducer,
		loader: loadingReducer,
		popup: popupReducer,
		saveBooking: saveBookingReducer,
		listBooking: listBookingReducer,
		flightEnquiry: flightEnquiryReducer,
		listUser: listUserReducer,
		userProfile: useProfileReducer,
		saveTemplate: saveTemplateReducer,
		saveDraft: saveDraftReducer,
		listTemplateNames: listTemplateNamesReducer,
		listDrafts: listDraftsReducer,
		manageTemplate: manageTemplateReducer,
		validateBooking: validateBookingReducer,
		updateBooking: updateBookingReducer,
		listQuotes: listQuoteReducer,
		saveCommodity: saveCommodityReducer,
		saveAirportMaster: saveAirportMasterReducer,
		saveServiceMaster: saveServiceMasterReducer,
		saveProductMaster: saveProductMasterReducer,
		saveSccMaster: saveSccMasterReducer,
		saveTermsMaster: saveTermsMasterReducer,
		saveUldTypeMaster: saveUldTypeMasterReducer,
		savePivotWeightInfoMaster: savePivotInfoMasterReducer,
		saveSccIncompatibilityMaster: saveSccIncompatibleMasterReducer,
		saveStationScheduleMaster: saveStationScheduleMasterReducer,
		saveRegionMaster: saveRegionMasterReducer,
		saveCountryMaster: saveCountryMasterReducer,
		csnDashboard: csnDashboardReducer,
		aviBooking: aviBookingReducer,
		dgBooking: dgBookingReducer,
		saveEmbargoMaster: saveEmbargoMasterReducer,
		seasonalBooking: seasonalBookingReducer,
		gsaAgent: gsaAgentReducer,

	})

export default rootReducer
