import React from 'react'
import { connect } from 'react-redux'
import SecureRoute from './SecureRoute'
import { showPopup } from '../../../redux/actions/popupActions'
import { clearAuth, setAccessToken, setRefreshToken } from '../../../redux/actions/authAction'
import { enableOrDisableLoading } from '../../../redux/actions/loadingIndicatorActions'
import { setAgentDetails } from '../../../redux/actions/gsaAgentAction'


const SecureRouteContainer = ({
	path,
	component,
	principal,
	refreshToken,
	accessToken,
	setRefreshTokenToState,
	setAccessTokenToState,
	clearAuthFromState,
	triggerLoading,
	showErrorPopup,
	page,
	gsaAgentData,
	setToGsaAgent
}) => {
	return (
		<SecureRoute
			path={path}
			component={component}
			principal={principal}
			refreshToken={refreshToken}
			accessToken={accessToken}
			setRefreshToken={setRefreshTokenToState}
			setAccessToken={setAccessTokenToState}
			clearAuth={clearAuthFromState}
			triggerLoading={triggerLoading}
			showErrorPopup={showErrorPopup}
			page={page}
			setToGsaAgent={setToGsaAgent}
			gsaAgentData={gsaAgentData}
		/>
	)
}

const mapStateToProps = ({ auth, gsaAgent }) => ({
	principal: auth.principal,
	refreshToken: auth.refreshToken,
	accessToken: auth.accessToken,
	gsaAgentData : gsaAgent
})

const mapDispatchToProps = (dispatch) => ({
	setRefreshTokenToState: (refreshToken) => {
		dispatch(setRefreshToken(refreshToken))
	},
	setAccessTokenToState: (accessToken) => {
		dispatch(setAccessToken(accessToken))
	},
	clearAuthFromState: () => {
		dispatch(clearAuth())
	},
	triggerLoading: (isLoading) => {
		dispatch(enableOrDisableLoading(isLoading))
	},
	showErrorPopup: (
		header,
		message,
		primaryBtnLbl,
		acceptFn,
		secondaryBtnLbl,
		rejectFn
	) => {
		dispatch(
			showPopup(
				header,
				message,
				primaryBtnLbl,
				acceptFn,
				secondaryBtnLbl,
				rejectFn
			)
		)
	},
	setToGsaAgent: (agentDetails) => {
		dispatch(setAgentDetails(agentDetails))
	}
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SecureRouteContainer)
