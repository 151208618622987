import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Alert } from '../../../common/Alert'
import { Dropdown } from '../../../common/DropDown'
import { UseInput } from '../../../common/UseInput'
import {
	DIMENSION_UNIT_SELECT_ITEMS,
	LANGUAGES,
	MILESTONES,
	WEIGHT_UNIT_SELECT_ITEMS,
	USER_PRIVLILEGES,
	PAGE_PRIMARY_CATEGORY,
	PAGE_SUB_CATEGORY1,
} from '../../../constants'
import { COUNTRY_CODES } from '../../../constants/countryCodes'
import { loadUserProfile } from '../../../redux/actions/userProfileActions'
import { updateUser } from '../../../services/userProfileService'
import LoadingIndicator from '../../LoadingIndicator'
import CountryCodeDropdown from '../CountryCodeDropdown/index'
import {
	addAllNotifications,
	getCountryCode,
	getLoggedInUserProfile,
	getPhoneNo,
	getUserId,
	handleMilestone,
	validateMobileCountryCode,
	validateMobileNumber,
	validateName,
	validatePhoneCountryCode,
	validatePhoneNumber,
	getAccountType,
	getPrivileges,
	getRoles,
} from '../userManagementUtil'
import { setToLocalStorage } from '../../../utils/StorageUtils'
import sessionKeys from '../../../constants/sessionKeys'
import { sort, getLanguageCode } from '../../../utils/common'
import MESSAGES from '../../../constants/Messages'
import { useTranslation } from 'react-i18next'
import { MULTILANGUAGE_SUPPORT_ENABLED } from '../../../configs/featureControlConfig'
import {  addError, pushPageData } from '../../../utils/analytics'
import pageNames from '../../../constants/pageNames'


	const UserProfile = ({ userProfileResponse, getBookingDetails ,userProfileError}) => {
	const { t, i18n } = useTranslation(['labels', 'errors'])

	const [userProfile, setUserProfile] = useState(null)
	const [sortedCountryCodes, setSotedCountryCodes] = useState([])
	const {
		value: firstName,
		setValue: setFirstName,
		bind: bindFirstName,
		reset: resetFirstName,
	} = UseInput('')
	const {
		value: lastName,
		setValue: setLastName,
		bind: bindLastName,
		reset: resetLastName,
	} = UseInput('')
	const {
		value: phoneNumber,
		setValue: setPhoneNumber,
		bind: bindPhoneNumber,
		reset: resetPhoneNumber,
	} = UseInput('')
	const {
		value: mobileNumber,
		setValue: setMobileNumber,
		bind: bindMobileNumber,
		reset: resetMobileNumber,
	} = UseInput('')
	
	const [weightUnit, setWeightUnit] = useState('')
	const [error, setError] = useState(null)

	const [alertMessage, setAlertMessage] = useState('')
	const [createSuccess, setCreateSuccess] = useState(false)
	// const [userType, setUserType] = useState('')
	const [phContryCode, setPhContryCode] = useState('')
	const [mobContryCode, setMobContryCode] = useState('')
	const [milestones, setMilestones] = useState({})
	const [notifications, setNotifications] = useState([])
	const [disableSaveButton, setDisableSaveButton] = useState(true)
	const [privileges, setPrivileges] = useState({
		bookingPrivilege: false,
		capturePrivilege: false,
		dashBoardPrivilege: false,
		informaticsPrivilege: false,
		allotmentPrivilege: false,
	})
	const [preferences, setPrefences] = useState({
		weightUnit: 'K',
		dimensionUnit: 'C',
		language: 'en_US',
	})
	
	const [isLoading, setIsLoading] = useState(false)
	const [isEditable, setIsEditable] = useState(false)

	const [language, setLanguage] = useState('')
	const [dimensionUnit, setDimensionUnit] = useState('')
	
	useEffect(() => {
		getBookingDetails(getUserId())
		setSotedCountryCodes(sort(COUNTRY_CODES))
		
	}, [])



	useEffect(() => {
		if (userProfileResponse) {
			setUserProfileDetails(userProfileResponse.userDetails)
			if (
				userProfileResponse.userDetails &&
				userProfileResponse.userDetails.userId
			) {
				setToLocalStorage(
					sessionKeys.USER_DETAILS,
					JSON.stringify(userProfileResponse.userDetails)
				)
			}
			setIsEditable(false)
			pushPageData(
				pageNames.USER_PROFILE,
				PAGE_PRIMARY_CATEGORY.USER_PROFILE,
				PAGE_SUB_CATEGORY1.USER_MANAGEMENT
			)
		}
		if (userProfileError) {
			addError(
				'ER229',
				i18n.getResource('en', 'errors', MESSAGES.USER_MAPPED_ERROR)
			)
			pushPageData(
				pageNames.USER_PROFILE,
				PAGE_PRIMARY_CATEGORY.USER_PROFILE,
				PAGE_SUB_CATEGORY1.USER_MANAGEMENT
			)
		}
	}, [userProfileResponse,userProfileError])

	useEffect(() => {
		if (notifications) {
			if (addAllNotifications(notifications)) {
				setMilestones((prevState) => ({
					...prevState,
					milestone1: true,
				}))
			} else {
				setMilestones((prevState) => ({
					...prevState,
					milestone1: false,
				}))
			}
		}
	}, [notifications])

	const setUserProfileDetails = (type) => {
		const userDetails = userProfileResponse.userDetails
		setUserProfile(userDetails)
		setPhoneNumber(getPhoneNo(userDetails.telephoneNo))
		setPhContryCode(getCountryCode(userDetails.telephoneNo))
		setMobileNumber(getPhoneNo(userDetails.mobileNo))
		setMobContryCode(getCountryCode(userDetails.mobileNo))

		if (userDetails.preference) {
			setWeightUnit(userDetails.preference.weightUnit)
			setDimensionUnit(userDetails.preference.dimensionUnit)
			setPrefences((prevState) => ({
				...prevState,
				weightUnit: userDetails.preference.weightUnit,
				dimensionUnit: userDetails.preference.dimensionUnit,
				language: MULTILANGUAGE_SUPPORT_ENABLED
					? userDetails.preference.language
					: 'en_US',
			}))
			MULTILANGUAGE_SUPPORT_ENABLED
				? setLanguage(userDetails.preference.language)
				: setLanguage('en_US')
		}
		if (userDetails.roles && userDetails.roles.length) {
			getPrivileges(userDetails.roles, privileges, setPrivileges)
		}

		setFirstName(userDetails.firstName)
		setLastName(userDetails.lastName)

		if (
			userDetails.notificationDetails &&
			userDetails.notificationDetails.notificationAction
		) {
			const notificationMilestones =
				userDetails.notificationDetails.notificationAction
			setNotifications(userDetails.notificationDetails.notificationAction)
			MILESTONES.forEach(function (milestone, index, array) {
				if (type && type === 'reset') {
					if (notificationMilestones.includes(milestone.label)) {
						setMilestones((prevState) => ({
							...prevState,
							[milestone.id]: true,
						}))
					} else {
						setMilestones((prevState) => ({
							...prevState,
							[milestone.id]: false,
						}))
					}
				} else {
					if (notificationMilestones.includes(milestone.label)) {
						setMilestones((prevState) => ({
							...prevState,
							[milestone.id]: true,
						}))
					}
				}
			})
		}
	}

	const getRequest = () => {
		const loggedInUserProfile = getLoggedInUserProfile()
		const roles = getRoles(privileges)
		const requestBody = {
			requestId: Math.floor(Math.random() * 89999 + 10000),
			userDetails: {
				userId: userProfile.userId,
				firstName,
				middleName: userProfile.middleName,
				lastName,
				email: userProfile.email,
				telephoneNo:
					phContryCode !== '' && phoneNumber !== ''
						? `${phContryCode} ${phoneNumber}`
						: null,
				mobileNo:
					mobContryCode !== '' && mobileNumber !== ''
						? `${mobContryCode} ${mobileNumber}`
						: null,
				userType: userProfile.userType,
				//roleGroup: userProfile.roleGroup,
				agentCode: loggedInUserProfile.agentCode,
				customerCode: loggedInUserProfile.customerCode,
				companyName: userProfile.companyName,
				companyCode: userProfile.companyCode,
				address: userProfile.address,
				city: userProfile.city,
				state: userProfile.state,
				country: userProfile.country,
				postalCode: userProfile.postalCode,
				stationCode: userProfile.stationCode,
				defaultAirportCode: userProfile.defaultAirportCode,
				preference: preferences,
				roles: roles.length ? roles : null,
				notificationDetails: {
					notificationAction: notifications,
					notificationEmail: [userProfile.email],
					notificationPhoneNo:
						mobContryCode && mobileNumber
							? [`${mobContryCode} ${mobileNumber}`]
							: null,
				},
			},
		}
		return requestBody
	}

	const validateForm = async () => {
		const fNameValid = await validateName(
			firstName,
			'firstName',
			setError,
			error
		)
		const lNameValid = await validateName(lastName, 'lastName', setError, error)
		const phNoValid = await validatePhoneNumber(
			phoneNumber,
			phContryCode,
			setError,
			error
		)
		const mobNoValid = await validateMobileNumber(
			mobileNumber,
			mobContryCode,
			setError,
			error
		)
		const phnCountryCodeValid = validatePhoneCountryCode(
			phContryCode,
			phoneNumber,
			setError
		)
		const mobCountryCodeValid = validateMobileCountryCode(
			mobContryCode,
			mobileNumber,
			setError
		)

		if (
			fNameValid &&
			lNameValid &&
			phNoValid &&
			mobNoValid &&
			phnCountryCodeValid &&
			mobCountryCodeValid
		) {
			setIsLoading(true)
			updateUser(getRequest())
				.then((response) => {
					setIsLoading(false)
					if (response.errorDetails && response.errorDetails.length > 0) {
						setAlertMessage(
							`${MESSAGES.USER_PROFILE_SAVE_FAILED} - ${response.errorDetails[0].errorDescription}`,
							
							addError('ER095', i18n.getResource('en', 'errors', MESSAGES.USER_PROFILE_SAVE_FAILED))
							
							)
						setCreateSuccess(false)
						window.scrollTo(0, 0)
						getBookingDetails(getUserId())
					} else {
						setAlertMessage(MESSAGES.USER_PROFILE_SAVE_SUCCESS)
						setCreateSuccess(true)
						window.scrollTo(0, 0)
						getBookingDetails(getUserId())
						setDisableSaveButton(true)
						setIsEditable(false)
						MULTILANGUAGE_SUPPORT_ENABLED &&
							i18n.changeLanguage(
								getLanguageCode(
									preferences?.language ? preferences.language : 'en_US'
								)
							)
						document.title = t('COMMON.EY_CARGO_PORTAL')
					}
				})
				.catch((error) => {
					setIsLoading(false)
					setAlertMessage(MESSAGES.USER_PROFILE_SAVE_FAILED)
					addError('ER095', i18n.getResource('en', 'errors', MESSAGES.USER_PROFILE_SAVE_FAILED))
					setCreateSuccess(false)
					window.scrollTo(0, 0)
					getBookingDetails(getUserId())
				})
		} else {
			console.log('has error', error)
		}
	}

	const handleSubmit = (evt) => {
		evt.preventDefault()
		validateForm()
	}

	const resetNotificationsIfEmpty = () => {
		let notificationMilestones = []

		if (userProfile.notificationDetails) {
			notificationMilestones =
				userProfile.notificationDetails.notificationAction
		}
		if (!notificationMilestones.length) {
			setNotifications([])
			MILESTONES.forEach(function (milestone, index, array) {
				setMilestones((prevState) => ({
					...prevState,
					[milestone.id]: false,
				}))
			})
		}
	}

	const handleCancel = () => {
		setIsEditable(false)
		setDisableSaveButton(true)
		setError(null)
		resetNotificationsIfEmpty()
		setUserProfileDetails('reset')
		// getBookingDetails(getUserId())
	}

	const removeError = (event) => {
		const id = event.target.id + ''
		setError((prevState) => ({ ...prevState, [id]: '' }))
	}

	const hasError = (key) => {
		if (error !== null && error[key] && error[key] !== '') {
			return true
		} else {
			return false
		}
	}

	const onChangePhCountryCode = (event) => {
		setPhContryCode(event.value)
	}

	const onChangeMobCountryCode = (event) => {
		setMobContryCode(event.target.value)
	}

	const handleChangeMilestone = (event) => {
		setDisableSaveButton(false)
		handleMilestone(event, notifications, setNotifications, setMilestones)
	}

	const onChangeLanguage = (event) => {
		setDisableSaveButton(false)
		setPrefences({ ...preferences, language: event.target.value }) 
	}

	const onChangeWeightUnit = (event) => {
		setDisableSaveButton(false)
		setPrefences({ ...preferences, weightUnit: event.target.value })
	}

	const onChangeDimensionUnit = (event) => {
		setDisableSaveButton(false)
		setPrefences({ ...preferences, dimensionUnit: event.target.value })
	}

	const handleAlertClose = () => {
		setAlertMessage('')
		setCreateSuccess(false)
	}

	const handleEdit = () => {
		setIsEditable(true)
	}

	const isCancelEnabled = () => {
		const isCancelEnabled = isEditable || !disableSaveButton
		return isCancelEnabled
	}

	const triggerPhoneCodeValidation = () => {
		validatePhoneCountryCode(phContryCode, phoneNumber, setError)
	}

	const triggerMobPhoneCodeValidation = () => {
		validateMobileCountryCode(mobContryCode, mobileNumber, setError)
	}

	return (
		<div className='user-profile-panel'>
			{alertMessage !== '' && (
				<Alert
					isError={!createSuccess}
					showAlert={true}
					message={t(alertMessage, { ns: 'errors' })}
					onAlertClose={handleAlertClose}
					setTimeout={true}
				/>
			)}
			{isLoading ? <LoadingIndicator /> : <></>}
			{userProfile && (
				<div>
					<div className='form-row mt-5 d-none d-lg-flex'>
						<div className='col-12 col-md-12 form-item pl-0 pr-0'>
							<div className='company-info'>
								<div className='form-row company-name-inner-wrapper'>
									<span className='col-md-3'>
										{userProfile.companyName !== null && (
											<p className='form-info line-height-0'>
												{userProfile.companyName}
											</p>
										)}
									</span>
									<span className='col-md-3'>
										{userProfile.customerCode !== null && (
											<p className='form-info-right'>
												{t('COMMON.AGT_CODE')} :
												<span className='font-weight-bold pl-1'>
													{userProfile.customerCode}
												</span>
											</p>
										)}
									</span>
								</div>

								{userProfile.address !== null && (
									<p className='form-info'>{userProfile.address}</p>
								)}

								<p className='form-info'>
									{userProfile.city ? userProfile.city : ''}
								</p>
								<p className='form-info'>
									{userProfile.country ? userProfile.country : ''}
									{userProfile.postalCode ? ', ' + userProfile.postalCode : ''}
								</p>
							</div>
						</div>
					</div>
					<div className='form-row mt-5 mb-3'>
						<h3 className='col-12 col-lg-8 mt-0 page-sub-head'>
							{t('COMMON.USER_INFO')}
						</h3>
					</div>
					<div>
						<div className='user-info'>
							<div className='form-row'>
								<div className='col-12 text-right'>
									<span className='link edit-link-profile' onClick={handleEdit}>
										{t('COMMON.EDIT')}
									</span>
								</div>
							</div>
							<div className='form-row'>
								<div
									className='col-12 col-lg-3 form-item form-item--text form-item--disabled'
									data-module='molecules/form-item/FormItem'
								>
									<label className='form-item__label'>
										{t('COMMON.USER_ID')}
									</label>
									<input
										type='text'
										readOnly
										className='form-item__field'
										value={userProfile.userId}
										maxLength='25'
									/>
								</div>

								{!isEditable && (
									<div
										className='col-12 col-lg-3 form-item form-item--text form-item--disabled'
										data-module='molecules/form-item/FormItem'
									>
										<label className='form-item__label'>
											{t('COMMON.NAME')}
										</label>
										<input
											type='text'
											readOnly
											className='form-item__field'
											value={`${userProfile.firstName ? userProfile.firstName : ''} ${userProfile.lastName ? userProfile.lastName : ''}`}
											maxLength='20'
										/>
									</div>
								)}
								<div
									className='col-12 col-lg-3 form-item form-item--text form-item--disabled'
									data-module='molecules/form-item/FormItem'
								>
									<label className='form-item__label'>
										{t('COMMON.EMAIL')}
									</label>
									<input
										type='text'
										readOnly
										className='form-item__field'
										value={userProfile.email}
										maxLength='100'
									/>
								</div>
								<div
									className='col-12 col-lg-3 form-item form-item--text form-item--disabled'
									data-module='molecules/form-item/FormItem'
								>
									<label className='form-item__label'>
										{t('COMMON.ACCOUNT_TYPE')}
									</label>
									<input
										type='text'
										readOnly
										className='form-item__field'
										value={t(getAccountType(userProfile))}
										maxLength='100'
									/>
								</div>
							</div>
							{isEditable && (
								<div className='form-row custom-mt-4'>
									<div
										className='col-12 col-lg-6 col-lg-4 form-item form-item--text'
										data-module='molecules/form-item/FormItem'
									>
										<label className='form-item__label'>
											{t('COMMON.FIRST_NAME')}
										</label>
										<input
											type='text'
											id='firstName'
											{...bindFirstName}
											className='form-item__field'
											maxLength='20'
											aria-invalid={hasError('firstName')}
											onFocus={removeError}
											onBlur={(e) => {
												validateName(firstName, 'firstName', setError, error)
											}}
										/>
										<span className='form-item__error'>
											{error && error['firstName']
												? t(error['firstName'], { ns: 'errors' })
												: ''}
										</span>
									</div>
									<div
										className='col-12 col-lg-6 col-lg-4 form-item form-item--text'
										data-module='molecules/form-item/FormItem'
									>
										<input
											type='text'
											id='lastName'
											{...bindLastName}
											className='form-item__field'
											maxLength='20'
											aria-invalid={hasError('lastName')}
											onFocus={removeError}
											onBlur={(e) => {
												validateName(lastName, 'lastName', setError, error)
											}}
										/>
										<label className='form-item__label'>
											{t('COMMON.LAST_NAME')}
										</label>
										<span className='form-item__error'>
											{error && error['lastName']
												? t(error['lastName'], { ns: 'errors' })
												: ''}
										</span>
									</div>
								</div>
							)}

							<div className='form-row custom-mt-5'>
								{isEditable ? (
									<div
										className={`col-12 col-lg-6 col-lg-4 form-item form-item--text`}
										data-module='molecules/form-item/FormItem'
									>
										<label className='form-item__label'>
											{t('COMMON.TEL')}
										</label>

										<div className='form-row'>
											<div
												className={`col-4 col-lg-4 pl-1
											 ${error && error['phContryCode'] ? 'red-border' : ''}`}
											>
												{/* <Dropdown
													id='phContryCode'
													className='cust-dropdown'
													value={phContryCode}
													options={sort(COUNTRY_CODES)}
													onFocus={removeError}
													onChange={onChangePhCountryCode}
													defaultText='-'
													readOnly={!isEditable}
													ariaInvalid={hasError('phContryCode')}
													filter = {true}
													onBlur={(e) => {
														validatePhoneCountryCode(
															phContryCode,
															phoneNumber,
															setError
														)
													}}
												/> */}
												<CountryCodeDropdown
													id='phContryCode'
													name='phContryCode'
													countryCodes={sortedCountryCodes}
													userCountryCode={phContryCode}
													setCountryCode={setPhContryCode}
													triggerCodeValidation={triggerPhoneCodeValidation}
													setError={setError}
													removeError={removeError}
												/>
												<span className='form-item__error'>
													{error && error['phContryCode']
														? t(error['phContryCode'], { ns: 'errors' })
														: ''}
												</span>
											</div>
											<div className='col-8 col-lg-8'>
												<input
													id='phoneNumber'
													type='text'
													{...bindPhoneNumber}
													className='form-item__field'
													maxLength='25'
													readOnly={!isEditable}
													aria-invalid={hasError('phoneNumber')}
													onFocus={removeError}
													onBlur={(e) => {
														validatePhoneNumber(
															phoneNumber,
															phContryCode,
															setError,
															error
														)
													}}
												/>
												<span className='form-item__error'>
													{error && error['phoneNumber']
														? t(error['phoneNumber'], { ns: 'errors' })
														: ''}
												</span>
											</div>
										</div>
									</div>
								) : (
									<div
										className={`col-12 col-lg-3 form-item form-item--text`}
										data-module='molecules/form-item/FormItem'
									>
										<label className='form-item__label'>
											{t('COMMON.TEL')}
										</label>

										<div className='form-row'>
											<div className='col-12'>
												<div
													className='form-item form-item--text'
													data-module='molecules/form-item/FormItem'
												>
													<input
														id='telephoneNumber'
														//value={`${phContryCode}${phoneNumber}`}
														value={
															phoneNumber
																? `${phContryCode}${phoneNumber}`
																: '-'
														}
														type='text'
														name='telephonenNumber'
														className='form-item__field'
														readOnly
													/>
												</div>
											</div>
										</div>
									</div>
								)}

								{isEditable ? (
									<div
										className='col-12 col-lg-4 form-item form-item--text'
										data-module='molecules/form-item/FormItem'
									>
										<label className='form-item__label'>
											{t('COMMON.MOB')}
										</label>

										<div className='form-row'>
											<div
												className={`col-4 col-lg-4 pl-1
											 ${error && error['mobContryCode'] ? 'red-border' : ''}`}
											>
												{/* <Dropdown
													id='mobContryCode'
													className='cust-dropdown'
													value={mobContryCode}
													options={COUNTRY_CODES}
													onFocus={removeError}
													onChange={onChangeMobCountryCode}
													defaultText='-'
													readOnly={!isEditable}
													ariaInvalid={hasError('mobContryCode')}
													onBlur={(e) => {
														validateMobileCountryCode(
															mobContryCode,
															mobileNumber,
															setError
														)
													}}
												/> */}
												<CountryCodeDropdown
													id='mobContryCode'
													name='mobContryCode'
													autoCompleteType='MOB_COUNTRY_CODE'
													countryCodes={sortedCountryCodes}
													userCountryCode={mobContryCode}
													setCountryCode={setMobContryCode}
													triggerCodeValidation={triggerMobPhoneCodeValidation}
													setError={setError}
													removeError={removeError}
												/>
												<span className='form-item__error'>
													{error && error['mobContryCode']
														? t(error['mobContryCode'], { ns: 'errors' })
														: ''}
												</span>
											</div>
											<div className='col-8 col-lg-8'>
												<input
													id='mobileNumber'
													type='text'
													{...bindMobileNumber}
													className='form-item__field'
													maxLength='25'
													readOnly={!isEditable}
													aria-invalid={hasError('mobileNumber')}
													onFocus={removeError}
													onBlur={(e) => {
														validateMobileNumber(
															mobileNumber,
															mobContryCode,
															setError,
															error
														)
													}}
												/>
												<span className='form-item__error'>
													{error && error['mobileNumber']
														? t(error['mobileNumber'], { ns: 'errors' })
														: ''}
												</span>
											</div>
										</div>
									</div>
								) : (
									<div
										className='col-12 col-lg-3 form-item form-item--text'
										data-module='molecules/form-item/FormItem'
									>
										<label className='form-item__label'>
											{t('COMMON.MOB')}
										</label>
										<div className='form-row'>
											<div className='col-12'>
												<div
													className='form-item form-item--text'
													data-module='molecules/form-item/FormItem'
												>
													<input
														id='mobNumber'
														//value={`${mobContryCode}${mobileNumber}`}
														value={
															mobileNumber
																? `${mobContryCode}${mobileNumber}`
																: '-'
														}
														type='text'
														name='mobNumber'
														className='form-item__field'
														readOnly
													/>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>

							<div className='form-row custom-mt-5'>
								<div className='col-12 col-lg-12 custom-pl-3 user-privileges'>
									<label className='form-item__label'>
										{t('UM.PRIVILEGES')}
									</label>
									{userProfileResponse &&
									userProfileResponse.userDetails &&
									userProfileResponse.userDetails.roles &&
									userProfileResponse.userDetails.roles.length ? (
										<div className='form-row mt-3'>
											{privileges.bookingPrivilege &&
												privileges.capturePrivilege &&
												privileges.dashBoardPrivilege &&
												privileges.allotmentPrivilege &&
												privileges.informaticsPrivilege && (
													<div className='col-12 col-md-3'>
														<div
															className='form-item form-item--text'
															data-module='molecules/form-item/FormItem'
														>
															<input
																id='all'
																value={t('COMMON.ALL')}
																type='text'
																name='all'
																className='form-item__field privilege-tick-mark'
																readOnly
															/>
														</div>
													</div>
												)}
											{privileges.bookingPrivilege && (
												<div className='col-12 col-lg-3'>
													<div
														className='form-item form-item--text'
														data-module='molecules/form-item/FormItem'
													>
														<input
															id='bookingPrivilege'
															value={t('COMMON.BKG')}
															type='text'
															name='bookingPrivilege'
															className='form-item__field privilege-tick-mark'
															readOnly
														/>
													</div>
												</div>
											)}

											{privileges.capturePrivilege && (
												<div className='col-12 col-lg-3'>
													<div
														className='form-item form-item--text'
														data-module='molecules/form-item/FormItem'
													>
														<input
															id='capturePrivilege'
															value={t('COMMON.DATA_CAPTURE')}
															type='text'
															name='capturePrivilege'
															className='form-item__field privilege-tick-mark'
															readOnly
														/>
													</div>
												</div>
											)}

											{privileges.dashBoardPrivilege && (
												<div className='col-12 col-lg-3'>
													<div
														className='form-item form-item--text'
														data-module='molecules/form-item/FormItem'
													>
														<input
															id='dashBoardPrivilege'
															value={t('UM.DASHBOARD_DEFAULT_PRVLG')}
															type='text'
															name='dashBoardPrivilege'
															className='form-item__field privilege-tick-mark'
															readOnly
														/>
													</div>
												</div>
											)}

											{privileges.informaticsPrivilege && (
												<div className='col-12 col-lg-3'>
													<div
														className='form-item form-item--text'
														data-module='molecules/form-item/FormItem'
													>
														<input
															id='informaticsPrivilege'
															value={t('UM.IFOGRPCS_PRVLG')}
															type='text'
															name='informaticsPrivilege'
															className='form-item__field privilege-tick-mark'
															readOnly
														/>
													</div>
												</div>
											)}

											{privileges.allotmentPrivilege && (
												<div className='col-12 col-lg-3'>
													<div
														className='form-item form-item--text'
														data-module='molecules/form-item/FormItem'
													>
														<input
															id='allotmentPrivilege'
															value={t('UM.ALLOTMNT_PRVLG')}
															type='text'
															name='allotmentPrivilege'
															className='form-item__field privilege-tick-mark'
															readOnly
														/>
													</div>
												</div>
											)}
										</div>
									) : (
										<div className='form-row mt-3'>-</div>
									)}
								</div>
							</div>
						</div>
					</div>

					<div>
						<div className='form-row custom-mt-5 mb-3'>
							<h3 className='col-12 col-md-12 mt-0 page-sub-head'>
								{t('COMMON.PREF')}
							</h3>
						</div>

						<div className='form-row mt-1'>
							<div className='lang-selector col-12 col-lg-4 form-item pl-2'>
								<Dropdown
									className='cust-dropdown'
									value={preferences.language}
									options={LANGUAGES}
									label={t('COMMON.LANG')}
									onChange={onChangeLanguage}
									disabled={!MULTILANGUAGE_SUPPORT_ENABLED}
								></Dropdown>
							</div>
						</div>
						<div className='form-row custom-mt-5'>
							<div className='col-6 col-lg-2 form-item pl-2'>
								<Dropdown
									className='cust-dropdown'
									value={preferences.weightUnit}
									options={WEIGHT_UNIT_SELECT_ITEMS}
									label={t('COMMON.WT')}
									onChange={onChangeWeightUnit}
								></Dropdown>
							</div>
							<div className='col-6 col-lg-2 form-item'>
								<Dropdown
									className='cust-dropdown'
									value={preferences.dimensionUnit}
									options={DIMENSION_UNIT_SELECT_ITEMS}
									label={t('COMMON.DIM')}
									onChange={onChangeDimensionUnit}
								></Dropdown>
							</div>
						</div>
						<div>
							<div className='form-row custom-mt-5 mb-3'>
								<h3 className='col-12 col-md-12 mt-0 page-sub-head notification'>
									{t('COMMON.NOTIFICATION')}
								</h3>
							</div>
							<div className='form-row mt-3 notifications-info'>
								<div className='col-12 col-lg-12'>
									<div className='form-row notify_list'>
										<div className='col-12 col-lg-auto'>
											<div
												className='form-item form-item--checkbox'
												data-module='molecules/form-item/FormItem'
											>
												<input
													id='milestone1'
													checked={milestones.milestone1}
													value='All'
													type='checkbox'
													name='milestone1'
													className='form-item__field'
													onChange={handleChangeMilestone}
												/>
												<label
													htmlFor='milestone1'
													className='form-item__label'
												>
													{t('COMMON.ALL')}
												</label>
											</div>
										</div>
										<div className='col-12 col-lg-auto'>
											<div
												className='form-item form-item--checkbox'
												data-module='molecules/form-item/FormItem'
											>
												<input
													id='milestone2'
													checked={milestones.milestone2}
													value='Accepted'
													type='checkbox'
													name='milestone2'
													className='form-item__field'
													onChange={handleChangeMilestone}
												/>
												<label
													htmlFor='milestone2'
													className='form-item__label'
												>
													{t('COMMON.ACCEPTED')}
												</label>
											</div>
										</div>
										<div className='col-12 col-lg-auto'>
											<div
												className='form-item form-item--checkbox'
												data-module='molecules/form-item/FormItem'
											>
												<input
													id='milestone3'
													checked={milestones.milestone3}
													value='Departed'
													type='checkbox'
													name='milestone3'
													className='form-item__field'
													onChange={handleChangeMilestone}
												/>
												<label
													htmlFor='milestone3'
													className='form-item__label'
												>
													{t('COMMON.DEPARTED')}
												</label>
											</div>
										</div>
										<div className='col-12 col-lg-auto'>
											<div
												className='form-item form-item--checkbox'
												data-module='molecules/form-item/FormItem'
											>
												<input
													id='milestone4'
													checked={milestones.milestone4}
													value='Arrived'
													type='checkbox'
													name='milestone4'
													className='form-item__field'
													onChange={handleChangeMilestone}
												/>
												<label
													htmlFor='milestone4'
													className='form-item__label'
												>
													{t('COMMON.ARRIVED')}
												</label>
											</div>
										</div>
										<div className='col-12 col-lg-auto'>
											<div
												className='form-item form-item--checkbox'
												data-module='molecules/form-item/FormItem'
											>
												<input
													id='milestone5'
													checked={milestones.milestone5}
													value='Notified'
													type='checkbox'
													name='milestone5'
													className='form-item__field'
													onChange={handleChangeMilestone}
												/>
												<label
													htmlFor='milestone5'
													className='form-item__label'
												>
													{t('COMMON.NOTIFIED')}
												</label>
											</div>
										</div>
										<div className='col-12 col-lg-auto'>
											<div
												className='form-item form-item--checkbox'
												data-module='molecules/form-item/FormItem'
											>
												<input
													id='milestone6'
													checked={milestones.milestone6}
													value='Delivered'
													type='checkbox'
													name='milestone6'
													className='form-item__field'
													onChange={handleChangeMilestone}
												/>
												<label
													htmlFor='milestone6'
													className='form-item__label'
												>
													{t('COMMON.DELIVERED')}
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='form-row custom-mt-5'>
						<div className='cta_wrap flex-column-reverse flex-lg-column custom-ml-1 custom-mt-3'>
							<button
								className='button'
								onClick={handleSubmit}
								disabled={!isEditable && disableSaveButton}
							>
								{t('COMMON.SAVE')}
							</button>
							{isCancelEnabled() && (
								<button
									className='button_cancel button secondary custom-ml-4'
									onClick={handleCancel}
								>
									{t('COMMON.CANCEL')}
								</button>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
const mapStateToProps = ({ userProfile }) => ({
	userProfileResponse: userProfile.response,
	userProfileError: userProfile.error,
})
const mapDispatchToProps = (dispatch) => ({
	getBookingDetails: (userCode) => {
		dispatch(loadUserProfile(userCode))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)
