import { emailPattern,zipCodePattern, FORM_FIELDS, SHIPPING_TYPE, addressPattern, suppleCusInfoPattern } from '../../constants'
import {
	validateAlpha,
	validateAlphaNumeric,
	validatePhoneNuberPattern,
	validateNumericPattern,
	validateDecimalPattern,
} from '../UserManagement/userManagementUtil'
import {
	validateOrigin,
	validateDestination,
	validateShippingDate,
	validateShippingItem,
} from '../SearchPanel/formUtils'
import { saveAWB, saveAcceptTnC } from '../../services/dataCaptureService'
import {
	validateMixBooking,
	validateLooseDetails,
	validateULDDetails,
	validateLineItemWeights,
} from '../SearchPanel/formUtils'
import { set } from 'js-cookie'
import { notZero } from '../../utils/common'
import MESSAGES from '../../constants/Messages'
import { addError } from '../../utils/analytics'
import i18n from '../../i18n'
const REQUIRED_FIELD_ERROR = MESSAGES.FIELD_REQUIRED
const ALPHANUMERIC_FIELD_ERROR = MESSAGES.ENTER_ALPHANUMERIC
const ALPHA_FIELD_ERROR = MESSAGES.ENTER_ALPHABETIC
const NUMERIC_FIELD_ERROR = MESSAGES.ENTER_NUMERIC
const DECIMAL_FIELD_ERROR = MESSAGES.VALUE_SHOULD_BE_DECIMAL
const LENGTH_FIELD_ERROR = MESSAGES.HS_CODE_SIX_IN_LEGTH
const ADRESS_FIELD_ERROR = MESSAGES.ENTER_VALID_ADDRESS
const HS_MAX_LENGTH_FIELD_ERROR = 'ER221'
const MIN_LENGTH_FIELD_ERROR = 'The field should be atleast %LENGTH% characters'
const t = i18n.t



export const validateAWBPattern = (awbNo) => {
	if (awbNo && awbNo.length === 8 && validateNumericRequired(awbNo)) {
		return true
	} else {
		return false
	}
}

export const validateAWBPrefixPattern = (awbNo, setError) => {
	if (awbNo && awbNo.length === 3 && validateNumericRequired(awbNo, setError)) {
		return true
	} else {
		return false
	}
}

export const validateAWBDocNo = (awbDocumentNo, setError) => {
	if (validateAWBPattern(awbDocumentNo)) {
		return true
	} else {
		setError((prevState) => ({
			...prevState,
			awbDocumentNo: MESSAGES.VALID_AWB_NUMBER,
		}))
		return false
	}
}

export const validateAWBPrefix = (prefix, setError) => {
	if (validateAWBPrefixPattern(prefix, setError)) {
		return true
	} else {
		setError((prevState) => ({
			...prevState,
			awbPrefix: MESSAGES.EMPTY_AWB_PREFIX,
		}))
		return false
	}
}

export const validateRequired = (value, setError, key, fieldName) => {
	if (value &&
		!(typeof value === 'string' || value instanceof String)
		) {
			return true
	} else if (value && value.trim().length) {
		return true
	} else {
		if (fieldName && fieldName !== '') {
			setError((prevState) => ({
				//...prevState,
				//[key]: REQUIRED_FIELD_ERROR,
				...prevState,
				[key]: t('ER271', {
					key: fieldName,
					ns: 'errors',
				}),
			}))
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: REQUIRED_FIELD_ERROR,
			}))
		}
		return false
	}
}

export const validateAlphaNumericIfPresent = (value, setError, key) => {
	if (value && value.trim() !== '') {
		if (validateAlphaNumeric(value)) {
			return true
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: ALPHANUMERIC_FIELD_ERROR,
			}))
			return false
		}
	} else {
		return true
	}
}

export const validateSuppleCusInfoIfPresent = (value, setError, key) => {
	if (value && value.trim() !== '') {
		if (suppleCusInfoPattern.test(value)) {
			return true
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: MESSAGES.ALPHA_NUMERIC_SPCL_CHAR,
			}))
			return false
		}
	} else {
		return true
	}
}

export const validateLength = (value, setError, key) => {
	if (value && value !== '') {
		if (value.length < 6) {
			setError((prevState) => ({
				...prevState,
				[key]: LENGTH_FIELD_ERROR,
			}))
			return false
		} else if (value.length > 18) {
			setError((prevState) => ({
				...prevState,
				[key]: HS_MAX_LENGTH_FIELD_ERROR,
			}))
			return false
		}
	} else {
		return true
	}
}

export const validateFieldLengthIfPresent = (
	value,
	setError,
	key,
	maxLength,
	minLength,
	length
) => {
	const t = i18n.t

	if (value && value !== '') {
		if (length && value.length !== length) {
			setError((prevState) => ({
				...prevState,
				[key]: t('ER170', {
					length: length,
					ns: 'errors',
				}),
			}))
			return false
		} else if (minLength && value.length < minLength) {
			setError((prevState) => ({
				...prevState,
				[key]: t('ER224', {
					length: minLength,
					ns: 'errors',
				}),
			}))
			return false
		} else if (maxLength && value.length > maxLength) {
			setError((prevState) => ({
				...prevState,
				[key]: t('ER222', {
					length: maxLength,
					ns: 'errors',
				}),
			}))
			return false
		} else {
			return true
		}
	} else {
		return true
	}
}

export const validateAlphaNumericRequired = (value, setError, key, fieldName) => {
	if (value && value.trim() !== '') {
		if (validateAlphaNumeric(value)) {
			return true
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: ALPHANUMERIC_FIELD_ERROR,
			}))
			return false
		}
	} else {
		if (fieldName && fieldName !== '') {
			setError((prevState) => ({
				//...prevState,
				//[key]: REQUIRED_FIELD_ERROR,
				...prevState,
				[key]: t('ER271', {
					key: fieldName,
					ns: 'errors',
				}),
			}))
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: REQUIRED_FIELD_ERROR,
			}))
		}
		return false
	}
}

export const validateAddressRequired = (value, setError, key) => {
	if (value && value.trim() !== '') {
		if (!addressPattern.test(value)) {
			setError((prevState) => ({
				...prevState,
				[key]: ADRESS_FIELD_ERROR,
			}))
		} else {
			return true
		}
	} else {

		setError((prevState) => ({
			...prevState,
			[key]: REQUIRED_FIELD_ERROR,
		}))

		return false
	}
}

export const validateAddressIfPresent = (value, setError, key) => {
	if (value && value.trim() !== '') {
		if (!addressPattern.test(value)) {
			setError((prevState) => ({
				...prevState,
				[key]: ADRESS_FIELD_ERROR,
			}))
		} else {
			return true
		}
	} else {
		return true
	}
}

export const validateNumericIfPresent = (value, setError, key) => {
	
	if (value !== null && value !== undefined && value !== '') {
		
		if (validateNumericPattern(value)) {
			return true
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: NUMERIC_FIELD_ERROR,
			}))
			return false
		}
	} else {
		return true
	}
}

export const validateNumericRequired = (value, setError, key) => {
	
	if (value !== null && value !== undefined && value !== '') {
		if (validateAlphaNumeric(value)) {
			return true
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: NUMERIC_FIELD_ERROR,
			}))
			return false
		}
	} else {
		setError((prevState) => ({
			...prevState,
			[key]: REQUIRED_FIELD_ERROR,
		}))
		return false
	}
}

export const validateDecimalIfPresent = (value, setError, key) => {
	if (value !== null && value !== undefined && value !== '') {
		if (validateDecimalPattern(value)) {
			return true
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: DECIMAL_FIELD_ERROR,
			}))
			return false
		}
	} else {
		return true
	}
}
export const validateDecimalRequired = (value, setError, key, fieldName) => {
	if (value !== null && value !== undefined && value !== '') {
		if (validateDecimalPattern(value)) {
			return true
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: DECIMAL_FIELD_ERROR,
			}))
			return false
		}
	} else {
		if (fieldName && fieldName !== '') {
			setError((prevState) => ({
				//...prevState,
				//[key]: REQUIRED_FIELD_ERROR,
				...prevState,
				[key]: t('ER271', {
					key: fieldName,
					ns: 'errors',
				}),
			}))
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: REQUIRED_FIELD_ERROR,
			}))
		}
		return false
	}
}

export const validateAlphaIfPresent = (value, setError, key) => {
	if (value && value.trim() !== '') {
		if (validateAlpha(value)) {
			return true
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: ALPHA_FIELD_ERROR,
			}))
			return false
		}
	} else {
		setError((prevState) => ({ ...prevState, [key]: '' }))
		return true
	}
}

export const validateAlphaRequired = (value, setError, key, fieldName) => {
	if (value && value.trim() !== '') {
		if (validateAlpha(value)) {			
			return true
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: ALPHA_FIELD_ERROR,
			}))
			return false
		}
	} else {
		if (fieldName && fieldName !== '') {
			setError((prevState) => ({
				//...prevState,
				//[key]: REQUIRED_FIELD_ERROR,
				...prevState,
				[key]: t('ER271', {
					key: fieldName,
					ns: 'errors',
				}),
			}))
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: REQUIRED_FIELD_ERROR,
			}))
		}
		return false
	}
}

export const hasError = (key, error) => {
	if (error !== null && error[key] && error[key] !== '') {
		return true
	} else {
		return false
	}
}

export const removeError = (event, setError) => {
	const id = event.target.id + ''
	setError((prevState) => ({ ...prevState, [id]: '' }))
}

export const validatePhoneNumberRequired = (value, setError, key) => {
	if (value && value.trim() !== '') {
		if (validatePhoneNuberPattern(value)) {
			return true
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: MESSAGES.ENTER_VALID_PHN,
			}))
			return false
		}
	} else {
		setError((prevState) => ({
			...prevState,
			[key]: REQUIRED_FIELD_ERROR,
		}))
		return false
	}
}

export const validatePhoneNumberIfPresent = (value, setError, key) => {
	if (value && value.trim() !== '') {
		if (validatePhoneNuberPattern(value)) {
			return true
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: MESSAGES.ENTER_VALID_PHN,
			}))
			return false
		}
	} else {
		return true
	}
}

export const validateEmailRequired = (email, setError, key) => {
	if (email && email.trim() !== '') {
		if (emailPattern.test(email)) {
			return true
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: MESSAGES.ENTER_VALID_MAIL,
			}))
			return false
		}
	} else {
		setError((prevState) => ({
			...prevState,
			[key]: REQUIRED_FIELD_ERROR,
		}))
		return false
	}
}

export const validateEmailIfPresent = (email, setError, key) => {
	if (email && email.trim() !== '') {
		if (emailPattern.test(email)) {
			return true
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: MESSAGES.ENTER_VALID_MAIL,
			}))
			return false
		}
	} else {
		return true
	}
}

export const validateZipCodeIfPresent = (zipCode, setError, key) => {
	if (zipCode && zipCode.trim() !== '') {
		if (zipCodePattern.test(zipCode)) {
			return true
		} else {
			setError((prevState) => ({
				...prevState,
				[key]: MESSAGES.ENTER_VALID_ZIP_CODE,
			}))
			return false
		}
	} else {
		return true
	}
}

export const validateSlacPieces = (value, setError, pieces) => {
	if (validateNumericIfPresent(value, setError, 'slacPieces')) {
		if (
			value &&
			value !== null &&
			value !== '' &&
			pieces &&
			pieces !== null &&
			pieces !== '' &&
			parseInt(value) < parseInt(pieces)
		) {
			setError((prevState) => ({
				...prevState,
				slacPieces: MESSAGES.VALID_SLAC_PCS,
			}))
			return false
		} else {
			return true
		}
	} else {
		return false
	}
}

export const validateChargeableWeight = (
	value,
	setError,
	initChargeableWeight
) => {
	if (validateDecimalRequired(value, setError, 'chargeableWeight')) {
		if (
			value 
			// &&
			// initChargeableWeight 
			// &&
			// parseFloat(initChargeableWeight) <= parseFloat(value)
		) {
			setError &&
				setError((prevState) => ({ ...prevState, chargeableWeight: '' }))
			return true
		} else {
			setError((prevState) => ({
				...prevState,
				chargeableWeight: MESSAGES.VALID_CH_WT,
			}))
			return false
		}
	} else {
		return false
	}
}

export const handleHsCode = (val, setError,hsCodeRequired) => {
	const statuses = []
	if (hsCodeRequired) {
		const requiredStatus = validateRequired(val, setError, 'hsCode')
		statuses.push(requiredStatus);

		if (!requiredStatus) return statuses;
	}

	if (val && val !== '') {
		const hccs = val.split(',')
		
		hccs.forEach((element) => {
			let status = validateAlphaNumericIfPresent(element, setError, 'hsCode')
			statuses.push(status)
			status = validateLength(element, setError, 'hsCode')
			statuses.push(status)
		})
		return statuses
	}
}

export const validateForm = (awbDetails, setError, initChargeableWeight, calculatedTotalWeight, bookingDetails, validationRule) => {
	let status = true
	const err1 = validateOrigin(awbDetails.origin)
	if (err1) {
		status = false
		setError((prevState) => ({
			...prevState,
			[FORM_FIELDS.ORIGIN]: err1,
		}))
	}
	const err2 = validateDestination(awbDetails.origin, awbDetails.destination)
	if (err2) {
		status = false
		setError((prevState) => ({
			...prevState,
			[FORM_FIELDS.DESTINATION]: err2,
		}))
	}
	const err3 = validateShippingDate(awbDetails.shippingDate)
	if (err3) {
		status = false
		setError((prevState) => ({
			...prevState,
			[FORM_FIELDS.SHIPPING_DATE]: err3,
		}))
	}
	const err4 = validateShippingItem(awbDetails.shippingItemCode)
	if (err4) {
		status = false
		setError((prevState) => ({
			...prevState,
			[FORM_FIELDS.SHIPPING_ITEM]: err4,
		}))
	}

	let statuses = [status]
	let isValid = validateNumericIfPresent(
		awbDetails.totalPieces,
		setError,
		'pieces'
	)
	statuses.push(isValid)

	const isNotZero = notZero(awbDetails.totalPieces)
	if (!isNotZero) {
		statuses.push(false)
		setError((prevState) => ({
			...prevState,
			pieces: MESSAGES.INVALID_PIECES,
		}))
	}

	isValid = validateSlacPieces(
		awbDetails.slacPieces,
		setError,
		awbDetails.totalPieces
	)
	statuses.push(isValid)

	isValid = validateDecimalIfPresent(awbDetails.totalWeight, setError, 'weight')
	statuses.push(isValid)

	isValid = validateDecimalIfPresent(
		awbDetails.displayVolume,
		setError,
		'volume'
	)
	statuses.push(isValid)

	isValid = validateChargeableWeight(
		awbDetails.chargeableWeightInSelectedUnit,
		setError,
		initChargeableWeight
	)
	statuses.push(isValid)

	isValid = validateDecimalRequired(
		awbDetails.chargeableWeightInSelectedUnit,
		setError,
		'chargeableWeight'
	)
	statuses.push(isValid)

	if (awbDetails.shippingType === SHIPPING_TYPE.LOOSE) {
		if (awbDetails.looseDetails &&
			awbDetails.totalWeight &&
			calculatedTotalWeight &&
			calculatedTotalWeight * 1 !== awbDetails.totalWeight * 1
		) {
			const tempLooseDetails = JSON.parse(JSON.stringify(awbDetails.looseDetails)).map(
				(dimDetails) => {
					return { ...dimDetails, weight: null }
				}
			)
			awbDetails.looseDetails = tempLooseDetails
		}
	}

	statuses = [
		...statuses,
		...validateRouting(awbDetails, setError),
		...validateParticipants(awbDetails, setError),
		...validateNotifyList(awbDetails, setError),
		...validateRateDetails(awbDetails, setError),
		...validateCustomsDetails(awbDetails, setError, bookingDetails, validationRule),
		...validateExecutionDetails(awbDetails, setError),
		...validateAdditionalCargo(awbDetails, setError),
		...validateShipmentDescriptionData(awbDetails, setError),
	]


	return statuses
}

const validateAdditionalCargo = (awbDetails, setError) => {
	let statuses = []
	if (awbDetails.shippingType === SHIPPING_TYPE.LOOSE) {
		let looseDetailsErrors = validateLooseDetails(awbDetails.looseDetails)
		/*looseDetailsErrors = validateLineItemWeights(
			awbDetails.looseDetails,
			looseDetailsErrors
		)*/
		const hasError = looseDetailsErrors.some((item) => (item ? true : false))
		if (hasError) {
			setError((prevState) => ({
				...prevState,
				[FORM_FIELDS.LOOSE_ITEMS]: looseDetailsErrors,
			}))
			statuses.push(false)
		}
	} else if (awbDetails.shippingType === SHIPPING_TYPE.ULD) {
		const ULDDetailsErrors = validateULDDetails(awbDetails.ULDDetails)
		const hasError = ULDDetailsErrors.some((item) => (item ? true : false))
		if (hasError) {
			setError((prevState) => ({
				...prevState,
				[FORM_FIELDS.ULD_ITEMS]: ULDDetailsErrors,
			}))
			statuses.push(false)
		}
		if (awbDetails.expandAccordian) {
			const mixBookingErrors = validateMixBooking(awbDetails.mixLooseDetails)
			const hasMixBookingError = mixBookingErrors.some((item) =>
				item ? true : false
			)
			if (hasMixBookingError) {
				setError((prevState) => ({
					...prevState,
					[FORM_FIELDS.LOOSE_ITEMS]: mixBookingErrors,
				}))
				statuses.push(false)
			}
		}
	}
	return statuses
}

const validateRouting = (awbDetails, setError) => {
	const { awbRouting } = awbDetails
	let statuses = []
	let isValid = true
	if (awbRouting) {
		awbRouting.forEach(function (route, index) {
			if (index === 0) {
				isValid = validateAlphaRequired(
					route.airport,
					setError,
					`airportCodeRoute${index}`,
					'airport code'
				)
				statuses.push(isValid)

				isValid = validateAlphaNumericRequired(
					route.carrierCode,
					setError,
					`carrierCodeRoute${index}`,
					'carrier code'
				)
				statuses.push(isValid)
			} else {
				isValid = validateAlphaIfPresent(
					route.airport,
					setError,
					`airportCodeRoute${index}`,
					'airport code'
				)
				statuses.push(isValid)
				isValid = validateAlphaNumericIfPresent(
					route.carrierCode,
					setError,
					`carrierCodeRoute${index}`,
					'carrier code'
				)
				statuses.push(isValid)
			}
			if (route.airport !== '' && route.airport.trim().length !== 3) {
				setError((prevState) => ({
					...prevState,
					[`airportCodeRoute${index}`]: 'ER223',
				}))
				statuses.push(false)
			}

			if (route.carrierCode !== '' && route.carrierCode.trim().length !== 2) {
				setError((prevState) => ({
					...prevState,
					[`carrierCodeRoute${index}`]: MESSAGES.ENTER_VALID_CARRIER_CODE,
				}))
				statuses.push(false)
			}
		})
	}
	return statuses
}

/**
 * Validate Participants fields
 *
 * @param {*} awbDetails
 * @param {*} setError
 */
const validateParticipants = (awbDetails, setError) => {
	const shipperDetails = awbDetails.shipperDetails
	const consigneeDetails = awbDetails.consigneeDetails
	let statuses = []

	let isValid = validateRequired(
		shipperDetails.name,
		setError,
		'shipperName',
		t('COMMON.NAME')
	)
	statuses.push(isValid)

	isValid = validateRequired(
		shipperDetails.address,
		setError,
		'shipperAddress',
		t('COMMON.ADDRS')
	)
	statuses.push(isValid)

	isValid = validateRequired(shipperDetails.city, setError, 'shipperCity', t('COMMON.CITY'))
	statuses.push(isValid)

	isValid = validateAlphaIfPresent(shipperDetails.state, setError, 'shipperState')
	statuses.push(isValid)

	isValid = validateRequired(
		shipperDetails.countryCode,
		setError,
		'shipperCountry'
	)
	statuses.push(isValid)

	isValid = validateZipCodeIfPresent (
		shipperDetails.zipCode,
		setError,
		'shipperZipCode'
	)
	statuses.push(isValid)

	//UAEP388 - Remove validation for account number to allow alphanumeric and special chars  
	/*isValid = validateAlphaNumericIfPresent(
		shipperDetails.accountNumber,
		setError,
		'shipperAccountNumber'
	)
	statuses.push(isValid)*/

	isValid = validatePhoneNumberIfPresent(
		shipperDetails.telephoneNo,
		setError,
		'shipperTelephone'
	)
	statuses.push(isValid)

	isValid = validateEmailIfPresent(
		shipperDetails.emailAddress,
		setError,
		'shipperEmail'
	)
	statuses.push(isValid)

	isValid = validateRequired(
		consigneeDetails.name,
		setError,
		'consigneeName',
		t('COMMON.NAME')
	)
	statuses.push(isValid)

	isValid = validateRequired(
		consigneeDetails.address,
		setError,
		'consigneeAddress',
		t('COMMON.ADDRS')
	)
	statuses.push(isValid)

	isValid = validateRequired(
		consigneeDetails.city,
		setError,
		'consigneeCity',
		t('COMMON.CITY')
	)
	statuses.push(isValid)

	isValid = validateAlphaIfPresent(
		consigneeDetails.state,
		setError,
		'consigneeState'
	)
	statuses.push(isValid)

	isValid = validateRequired(
		consigneeDetails.countryCode,
		setError,
		'consigneeCountry'
	)
	statuses.push(isValid)

	isValid = validateZipCodeIfPresent (
		consigneeDetails.zipCode,
		setError,
		'consigneeZipCode'
	)
	statuses.push(isValid)

	//UAEP388 - Remove validation for account number to allow alphanumeric and special chars  
	/*isValid = validateAlphaNumericIfPresent(
		consigneeDetails.accountNumber,
		setError,
		'consigneeAccountNumber'
	)
	statuses.push(isValid)*/

	isValid = validatePhoneNumberIfPresent(
		consigneeDetails.telephoneNo,
		setError,
		'consigneeTelephone'
	)
	statuses.push(isValid)

	isValid = validateEmailIfPresent(
		consigneeDetails.emailAddress,
		setError,
		'consigneeEmail'
	)
	statuses.push(isValid)

	return statuses
}

/**
 * Validate notification details.
 *
 * @param {
 * } awbDetails
 * @param {*} setError
 */
const validateNotifyList = (awbDetails, setError) => {
	const { shipperDetails, consigneeDetails, notifyList } = awbDetails
	let statuses = []
	let isValid = true

	if (
		!notifyList.notifyShipper.checked &&
		!notifyList.notifyConsignee.checked &&
		!notifyList.notifyOther.checked
	) {
		setError((prevState) => ({
			...prevState,
			notifyList: MESSAGES.SELECT_NOTIFICATION_OPTION,
		}))
		statuses.push(false)
	} else {
		if (
			notifyList.notifyShipper.checked &&
			shipperDetails.emailAddress.trim() === '' &&
			shipperDetails.telephoneNo.trim() === ''
		) {
			setError((prevState) => ({
				...prevState,
				notifyShipper: MESSAGES.SHIPPER_NOTIFICATION_MSG,
			}))
			statuses.push(false)
		} else {
			setError((prevState) => ({
				...prevState,
				notifyShipper: '',
			}))
		}

		if (
			notifyList.notifyConsignee.checked &&
			consigneeDetails.emailAddress.trim() === '' &&
			consigneeDetails.telephoneNo.trim() === ''
		) {
			setError((prevState) => ({
				...prevState,
				notifyConsignee: MESSAGES.CONSGN_NOTIFICATION_MSG,
			}))
			statuses.push(false)
		} else {
			setError((prevState) => ({
				...prevState,
				notifyConsignee: '',
			}))
		}
		if (notifyList.notifyOther.checked) {

			if ((notifyList.notifyOther.name && notifyList.notifyOther.name !== '') ||
				(notifyList.notifyOther.telephoneNo && notifyList.notifyOther.telephoneNo !== '') ||
				(notifyList.notifyOther.email && notifyList.notifyOther.email !== '') ||
				(notifyList.notifyOther.address && notifyList.notifyOther.address !== '') ||
				(notifyList.notifyOther.city && notifyList.notifyOther.city !== '') ||
				(notifyList.notifyOther.country && notifyList.notifyOther.country !== '')) {
				
				isValid = validateAlphaRequired(notifyList.notifyOther.name, setError, 'notifyName')
				statuses.push(isValid)
				isValid = validatePhoneNumberIfPresent(
					notifyList.notifyOther.telephoneNo,
					setError
				)
				statuses.push(isValid)
				isValid = validateEmailIfPresent(notifyList.notifyOther.email, setError)
				statuses.push(isValid)
				if (
					notifyList.notifyOther.email.trim() === '' &&
					notifyList.notifyOther.telephoneNo.trim() === ''
				) {
					setError((prevState) => ({
						...prevState,
						notifyOther: 'ER220',
					}))
					statuses.push(false)
				} else {
					setError((prevState) => ({
						...prevState,
						notifyOther: '',
					}))
				}

				isValid = validateAddressRequired(notifyList.notifyOther.address, setError, 'notifyOtherAddress')
				statuses.push(isValid)

				isValid = validateAlphaRequired(notifyList.notifyOther.city, setError, 'notifyOtherCity')
				statuses.push(isValid)

				isValid = validateRequired(notifyList.notifyOther.country, setError, 'notifyOtherCountry')
				statuses.push(isValid)

			} else {
				setError((prevState) => ({
					...prevState,
					notifyOther: '',
				}))
				setError((prevState) => ({
					...prevState,
					notifyName: '',
				}))
				setError((prevState) => ({
					...prevState,
					notifyTelephone: '',
				}))
				setError((prevState) => ({
					...prevState,
					notifyEmail: '',
				}))
				setError((prevState) => ({
					...prevState,
					notifyOtherAddress: '',
				}))
				setError((prevState) => ({
					...prevState,
					notifyOtherCity: '',
				}))
				setError((prevState) => ({
					...prevState,
					notifyOtherCountry: '',
				}))
			}

		}
	}
	return statuses
}

/**
 * Validate rate details
 *
 * @param {*} awbDetails
 * @param {*} setError
 */
const validateRateDetails = (awbDetails, setError) => {
	const awbRateLineDetails = awbDetails.awbRateLineDetails
	let statuses = []

	let isValid = validateDecimalRequired(
		awbRateLineDetails.rate,
		setError,
		'rate'
	)

	statuses.push(isValid)

	isValid = validateDecimalRequired(
		awbRateLineDetails.netCharge,
		setError,
		'netCharge'
	)

	statuses.push(isValid)

	isValid = validateRequired(
		awbRateLineDetails.rateClassCode,
		setError,
		'rateClass'
	)

	statuses.push(isValid)

	if (
		awbRateLineDetails.otherCharges &&
		awbRateLineDetails.otherCharges.length
	) {
		isValid = validateRequired(
			awbRateLineDetails.otherCharges[0].prepaidCollectIndicator,
			setError,
			`subChargePaymentType`
		)
		statuses.push(isValid)

		awbRateLineDetails.otherCharges.forEach(function (info, index) {
			isValid = validateRequired(
				info.otherChargeCode,
				setError,
				`subchargeCode${index}`
			)
			statuses.push(isValid)

			/*isValid = validateRequired(
				info.chargeHeadName,
				setError,
				`chargeHead${index}`
			)
			statuses.push(isValid)*/

			isValid = validateDecimalRequired(
				info.chargeAmount,
				setError,
				`subCharge${index}`
			)
			statuses.push(isValid)

			isValid = validateRequired(
				info.chargeEntitlementCodeParty,
				setError,
				`dueParty${index}`
			)
			statuses.push(isValid)
		})
	}

	return statuses
}

/**
 * Validate customs details
 *
 * @param {*} awbDetails
 * @param {*} setError
 */
const validateCustomsDetails = (awbDetails, setError, bookingDetails, validationRule) => {
	const awbChargeDeclaration = awbDetails.awbChargeDeclaration
	const customsInformationDetails = awbDetails.customsInformationDetails
	const accountingInformationDetails = awbDetails.accountingInformationDetails

	let hsCodeRequired = false
	if (validationRule && validationRule.length > 0) {
		const isDestinationInBookingDetails = bookingDetails?.bookingDetails.bookingFlightDetails.some(
			(flightDetail) =>
				validationRule.some(
					(rule) => rule.airports?.includes(flightDetail?.segmentDestination)
				)
		);

		const isDestinationInAwbDetails = awbDetails?.awbRouting?.some(
			(routingDetail) =>
				validationRule.some(
					(rule) => rule.airports?.includes(routingDetail?.airport)
				)
		);

		hsCodeRequired = isDestinationInBookingDetails || isDestinationInAwbDetails;
	}
	let statuses = []

	let isValid = validateDecimalIfPresent(
		awbChargeDeclaration.declaredValueForCustoms,
		setError,
		'dvCustoms'
	)
	statuses.push(isValid)

	isValid = validateDecimalIfPresent(
		awbChargeDeclaration.declaredValueForCarriage,
		setError,
		'dvCarriage'
	)
	statuses.push(isValid)

	const hsCodeValid = handleHsCode(awbDetails.hccs, setError,hsCodeRequired, 'hsCode')
	if (hsCodeValid) {
		statuses = [...statuses, ...hsCodeValid]
	}
	isValid = validateAlphaNumericIfPresent(
		awbDetails.handlingInfo,
		setError,
		'handlingInfo'
	)
	statuses.push(isValid)

	if (accountingInformationDetails && accountingInformationDetails.length) {
		accountingInformationDetails.forEach(function (info, index) {
			if (
				isNotBlank(info.accountingInformationIdentifier) ||
				isNotBlank(info.accountingInformation)
			) {
				isValid = validateRequired(
					info.accountingInformationIdentifier,
					setError,
					`accountInfoId${index}`
				)
				statuses.push(isValid)

				isValid = validateAlphaNumericRequired(
					info.accountingInformation,
					setError,
					`accountingInformation${index}`
				)
				statuses.push(isValid)
			}
		})
	}

	if (customsInformationDetails && customsInformationDetails.length) {
		customsInformationDetails.forEach(function (info, index) {
			if (
				isNotBlank(info.countryCode) ||
				isNotBlank(info.customsInformationId) ||
				isNotBlank(info.informationId) ||
				isNotBlank(info.supplementaryCustomsInformation)
			) {
				isValid = validateAlphaRequired(
					info.countryCode,
					setError,
					`isoCountryCode${index}`
				)
				statuses.push(isValid)

				isValid = validateFieldLengthIfPresent(
					info.countryCode,
					setError,
					`isoCountryCode${index}`,
					null,
					null,
					2
				)
				statuses.push(isValid)

				isValid = validateAlphaNumericRequired(
					info.informationId,
					setError,
					`informationID${index}`
				)
				statuses.push(isValid)

				isValid = validateFieldLengthIfPresent(
					info.informationId,
					setError,
					`informationID${index}`,
					null,
					null,
					3
				)
				statuses.push(isValid)

				isValid = validateAlphaNumericRequired(
					info.customsInformationId,
					setError,
					`customsInfoId${index}`
				)
				statuses.push(isValid)

				isValid = validateFieldLengthIfPresent(
					info.customsInformationId,
					setError,
					`customsInfoId${index}`,
					2,
					1,
					null
				)
				statuses.push(isValid)

				isValid = validateSuppleCusInfoIfPresent(
					info.supplementaryCustomsInformation,
					setError,
					`suppCustomsInfo${index}`
				)
				statuses.push(isValid)
			}
		})
	}

	return statuses
}

/**
 * Validate exection details
 *
 * @param {*} awbDetails
 * @param {*} setError
 */
const validateExecutionDetails = (awbDetails, setError) => {
	const awbExecutionDetails = awbDetails.awbExecutionDetails
	let statuses = []

	let isValid = validateAlphaRequired(
		awbExecutionDetails.issuedBy,
		setError,
		'issuedBy',
		t('CAPTURE_AWB.ISSUED_BY')
	)
	statuses.push(isValid)

	isValid = validateRequired(
		awbExecutionDetails.issueDate,
		setError,
		'issueDate'
	)
	statuses.push(isValid)

	isValid = validateAlphaRequired(
		awbExecutionDetails.issuedPlace,
		setError,
		'issuedPlace',
		t('CAPTURE_AWB.ISSUED_PLC')
	)
	statuses.push(isValid)

	return statuses
}

const validateShipmentDescriptionData = (awbDetails, setError) => {
	const shipmentDescriptionData = awbDetails.shipmentDescriptionData
	let statuses = []
	let isValid = validateRequired(
		shipmentDescriptionData.shipmentDescription,
		setError,
		'shipmentDescription',
		'Shipment description'
	)
	statuses.push(isValid)
	return statuses
}

const isNotBlank = (value) => {
	if (value && value.trim() !== '') {
		return true
	} else {
		return false
	}
}

const getErrorDataList = (errorData) => {
	let errorDataList = []
	errorData.forEach((item) => {
		errorDataList.push("<br>" + getErrorDataItem(item))
	})
	return errorDataList

}

const getErrorDataItem = (item) => {
	if (item) {
		const words = item.split(" ");
		const newStr = words.slice(1).join(" ");
		return newStr
	} else {
		return item
	}
}

export const handleSaveCaptureAwb = async (
	awbDetails,
	setStatus,
	setAlertMessage,
	setIsLoading,
	multipleSccList,
	handleErrorPopup,
	detachSpotRate,
	skipACIValidation,
) => {
	const t = i18n.t
	awbDetails.multipleSccList = multipleSccList
	if (typeof detachSpotRate == 'boolean' && detachSpotRate) {
		awbDetails.detachSpotRate = detachSpotRate
	}

	if (typeof skipACIValidation == 'boolean' && skipACIValidation) {
		awbDetails.ignoreContentCheck = skipACIValidation
	} /**else {
		awbDetails.ignoreContentCheck = false
	}**/
	const data = await saveAWB(awbDetails)
		.then((response) => {

			setIsLoading(false)
			if (response && response.shipmentResponseDetails) {
				return response
			} else {
				//setIsLoading(false)
				if (
					response &&
					response.errorDetails &&
					(response.errorDetails.errorDescription || response.errorDetails.errorCode)
				) {
					return response.errorDetails
				} else {
					return null
				}
			}
		})
		.catch((error) => {
			console.log(error)
			setIsLoading(false)
			setStatus(false)
			setAlertMessage(MESSAGES.AWB_SAVE_FAILED)
			addError(
				MESSAGES.AWB_SAVE_FAILED,
				i18n.getResource('en', 'errors', MESSAGES.AWB_SAVE_FAILED)
			)
			return null
		})
	let status = false
	if (data && !data.errorDescription && !data.errorCode) {
		setAlertMessage(MESSAGES.AWB_SAVE_SUCCESS)
		setStatus(true)
		status = true
	} else if (data && (data.errorDescription || data.errorCode)) {
		setStatus(false)
		const message = getiCargoError(data)
		addError(MESSAGES.AWB_SAVE_FAILED, message)
		if (data.errorCode && data.errorCode === 'tariff.freight.spotraterequest.parametermismatch') {
			handleErrorPopup(message)
		} else if (data.errorCode && data.errorCode === 'operations.shipment.autocontentcheck.errorcodes.warning') {
			handleErrorPopup(message, data.errorData)
		}  else if (data.errorCode && data.errorCode === 'operations.shipment.autocontentcheck.errorcodes.error') {
			let errorDataList = getErrorDataList(data.errorData)
			setAlertMessage(message +  errorDataList.join('\r\n'))

		} else{
			if (data.errorData && data.errorData.length > 0) {
				let errorDataList = getErrorDataList(data.errorData)
				setAlertMessage(message + errorDataList.join('\r\n'))
			} else {
				setAlertMessage(message)
			}
		}
	} else {
		setStatus(false)
		setAlertMessage(MESSAGES.AWB_SAVE_FAILED)
		addError(
			MESSAGES.AWB_SAVE_FAILED,
			i18n.getResource('en', 'errors', MESSAGES.AWB_SAVE_FAILED)
		)
	}
	setIsLoading(false)
	return status
}

const getiCargoError = (data) => {
	if (data.errorCode) {
		if (data.errorCode === 'operations.shipment.weight.greater') {
			return (t(MESSAGES.AWB_SAVE_FAILED_VALID_ERROR, { ns: 'errors' }) +
				t(MESSAGES.VALID_CH_WT, { ns: 'errors' }))
		} else if (data.errorCode === 'tariff.freight.spotraterequest.parametermismatch') {
			return (t(MESSAGES.SPOT_RATE_DATA_MISMATCH, {
					errorData: data.errorData && data.errorData.length > 0 ? data.errorData[(data.errorData.length - 1)] : '',
					ns: 'errors',
				}))

		} else if (data.errorCode === 'operations.shipment.autocontentcheck.errorcodes.warning') {
			return (t(MESSAGES.ACI_VALIDATION_WARNING, { ns: 'errors' }))
		} else if (data.errorCode === 'operations.shipment.autocontentcheck.errorcodes.error') {
			return (t(MESSAGES.ACI_VALIDATION_ERROR, { ns: 'errors' }))
		} else {
			return (t(MESSAGES.AWB_SAVE_FAILED_VALID_ERROR, { ns: 'errors' }) + data.errorDescription)
		}
	} else {
		return (t(MESSAGES.AWB_SAVE_FAILED_VALID_ERROR, { ns: 'errors' }) + data.errorDescription)
	}

}

export const handleSaveAcceptTnC = async (flag, setIsLoading) => {
	const data = await saveAcceptTnC(flag)
		.then((response) => {
			// setIsLoading(false)
			if (response && !response.errorCode) {
				return response
			} else {
				return null
			}
		})
		.catch((error) => {
			console.log(error)
			setIsLoading(false)

			return null
		})
	setIsLoading(false)
	return data
}
