import { GSA_AGENT_SELECT } from '../constants'

export const setAgentDetails = (agentDetails) => ({
	type: GSA_AGENT_SELECT.SET_AGENT_DETAILS,
	agentDetails,
})

export const clearAgentSelection = () => ({
	type: GSA_AGENT_SELECT.CLEAR,
})
