import { Toast } from 'primereact/toast'
import React, { useEffect, useRef, useState,Suspense } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import envConfigs from '../../configs/envConfigs'
import sessionKeys from '../../constants/sessionKeys'
import { sessionLogout } from '../../services/authService'
import { clearItemFromSessionStorage } from '../../utils/StorageUtils'
import Callback from '../Auth/Callback'
import SecureRoute from '../Auth/SecureRoute'
import Booking from '../Booking'
import CaptureAWBRoot from '../CaptureAWB'
import Dashboard from '../Dashboard'
import FlightEnquiry from '../FlightEnquiry'
import Footer from '../Footer'
import LoadingIndicator from '../LoadingIndicator'
import Login from '../Login'
import ManageBookingRoot from '../ManageBooking'
import ManageTemplate from '../ManageTemplate'
import PageHeading from '../PageHeading'
import Popup from '../Popup'
import UserManagement from '../UserManagement'
import { PAGES, USER_PRIVLILEGES, GSA_USER_CONST } from '../../constants'
import AdminContainer from '../Admin/AdminContainer'
import ScheduedBooking from '../PermanentBooking'
import CsnDashboard from '../CsnDashboard'
import ReportsRoot from '../Reports/ReportsRoot'
import ScheduleVisualization from '../ScheduleVisualization'
import GsaUserAgentSelect from '../GsaUserAgentSelect'


//import ReportsMenu from '../Reports'

const Root = ({
	isLoading,
	toastDetails,
	setSearchFormDataToState,
	clearAuthFromState,
	principal,
	clearGsaAgentDetails
}) => {
	const myToast = useRef(null)
	const history = useHistory()
	const location = useLocation()

	const { logOutUrl } = envConfigs
	const [logOutService, setLogOutService] = useState(null)

	const initiateLogout = async () => {
		clearAuthFromState()
		clearGsaAgentDetails()
		await sessionLogout()
		if (logOutUrl && logOutUrl !== '') {
			setLogOutService(logOutUrl)
		}
		setTimeout(() => {
			return history.push('/login')
		}, 1000)
	}

	useEffect(() => {
		if (toastDetails && toastDetails.severity) {
			myToast.current.show({ ...toastDetails, life: 4000 })
		}
	}, [toastDetails])

	useEffect(() => {
		if (!location.pathname.startsWith('/booking')) {
			clearItemFromSessionStorage(sessionKeys.SEARCH_FORM_DATA_BOOKING)
			clearItemFromSessionStorage(sessionKeys.SELECTED_FLIGHT_BOOKING)
			setSearchFormDataToState(null)
		}
	}, [location])

	return (
		<>
			{isLoading ? <LoadingIndicator /> : <></>}
			<Suspense fallback={<LoadingIndicator />}>
			<Toast ref={myToast} />
			<Popup />
			<PageHeading />			
			<Switch>
				<Route exact path='/login' component={Login} />
				<Route
					exact
					path='/'
					render={() => {
						setTimeout(() => {
							if (principal && principal.roles && principal.roles.length) {
								if (
									principal.roles.includes(USER_PRIVLILEGES.EY_ADMIN_PRIVILEGE)
								) {
									history.push('/admin-master')
								} else if (
									principal.roles.includes(USER_PRIVLILEGES.DASHBOARD_PRIVILEGE)
								) {
									history.push('/dashboard')
								} else if (
									principal.roles.includes(USER_PRIVLILEGES.BOOKING_PRIVILEGE)
								) {
									history.push('/booking')
								} else {
									history.push('/booking')
								}
							} else {
								history.push('/login')
							}
						}, 1000)
					}}
				/>
				<SecureRoute path='/booking' component={Booking} page={PAGES.BOOKING} />
				<SecureRoute
					path='/manage-booking'
					component={ManageBookingRoot}
					page={PAGES.BOOKING}
				/>
				<Route path='/flight-enquiry' component={FlightEnquiry} />
				<SecureRoute
					path='/admin-master'
					component={AdminContainer}
					page={PAGES.ADMIN}
				/>
				<SecureRoute 
					path='/user-management' 
					component={UserManagement}
					page={PAGES.USERMANAGEMENT}
				/>
				<SecureRoute
					path='/manage-template'
					component={ManageTemplate}
					page={PAGES.BOOKING}
				/>
				<SecureRoute
					path='/permanent-booking'
					component={ScheduedBooking}
					page={PAGES.BOOKING}
				/>
				<SecureRoute
					path='/csn-dashboard'
					component={CsnDashboard}
					page={PAGES.CSNDASHBOARD}
				/>
				<SecureRoute
					path='/capture-awb'
					component={CaptureAWBRoot}
					page={PAGES.CAPTURE}
				/>
				<SecureRoute
					path='/dashboard'
					component={Dashboard}
					page={PAGES.DASHBOARD}
				/>
				<SecureRoute
					path='/reports'
					component={ReportsRoot}
					page={PAGES.REPORTS}
				/>
				<SecureRoute
					path='/schedule-visualization'
					component={ScheduleVisualization}
					page={PAGES.SCHEDULEVISUALIZATION}
				/>
				<SecureRoute
					path='/user-select'
					component={GsaUserAgentSelect}
					page={PAGES.GSAUSERAGENTSELECT}
				/>
				<Route path='/callback' component={Callback} />
				<Route
					path='/logout'
					render={() => {
						initiateLogout()
					}}
				/>
			</Switch>
			<Footer />
			</Suspense>
			{logOutService && (
				<div>
					<iframe
						src={logOutService}
						width='0'
						height='0'
						title='logout'
					></iframe>
				</div>
			)}
		</>
	)
}

export default Root
