import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import {
	AUTOCOMPLETE_TYPE,
	WEIGHT_UNIT_SELECT_ITEMS_WO_TON,
	VOLUME_UNITS_SELECT_ITEMS,
	SCC_CODES,
	PHARMA_SHIPPING_TYPE,
	FRESH_FORWARD_PRODUCT,
	PHARMA_PRODUCT,
	SHIPPING_TYPE,
	getInitLooseItemDetail,
	getInitULDItemDetail,
	FORM_FIELDS,
	PRINT_TYPES,
	KG_TO_POUND_VALUE,
	KG_TO_TON_VALUE,
	WEIGHT_UNIT,
} from '../../../constants'
import { ALLOWABLE_BOOKING_DATE } from '../../../configs/appConfigs'
import { handleTilteableAndStackable } from '../../SearchPanel/formUtils'
import { checkSccCompatibility } from '../../../services/searchPanelServices'
import AirportAutocomplete from '../../AirportAutocomplete'
import { Calendar } from 'primereact/calendar'
import ShippingItemAutocomplete from '../../ShippingItemAutocomplete'
import SccAutoComplete from '../../SccAutoComplete'
import { SHIPPING_ITEM_PLACEHOLDER } from '../../../constants/Placeholders'
import { Dropdown } from '../../../common/DropDown'
import RatesDetails from './RatesDetails'
import CustomsDetails from './CustomsDetails'
import ExecutionDetails from './ExecutionDetails'
import AdditionalCargoDetails from './AdditionalCargoDetails'
import ParticipantDetails from './ParticipantDetails'
import { Accordion, AccordionTab } from 'primereact/accordion'
import SccListDisplay from '../../SccListDisplay'
import {
	validateAlphaIfPresent,
	validateDecimalIfPresent,
	hasError,
	removeError,
	validateForm,
	validateSlacPieces,
	validateNumericIfPresent,
	validateAlphaRequired,
	validateAlphaNumericRequired,
	handleSaveCaptureAwb,
	validateChargeableWeight,
	validateRequired,
	validateAlphaNumericIfPresent,
} from '../captureAwbUtils'
import { printAWB } from '../../../services/dataCaptureService'
import { openURLEncodedBase64NewTab } from '../printAwbUtils'
import { getAwbPrintRequest } from '../../../utils/RequestBodyUtils'
import SplitButton from '../../../common/SplitButton'
import MESSAGES from '../../../constants/Messages'

import {
	calculateWeightFromKg,
	convertToLowerCase,
	notZero,
} from '../../../utils/common'
import { Message } from 'primereact/message'
import { useTranslation } from 'react-i18next'
import { addError } from '../../../utils/analytics'

const BookingDetails = ({
	shippingItems,
	awbDetails,
	setAwbDetails,
	error,
	setError,
	setIsLoading,
	setStatus,
	setAlertMessage,
	initChargeableWeight,
	setShipmentPrefix,
	setMasterDocumentNumber,
	handleTabChange,
	accordionsActive,
	setAccordionsActive,
	initialLoading,
	setInitialLoading,
	enableButtons,
	setEnableButtons,
	setNotificationMessage,
	setNotificationType,
	setPopupDetails,
	bookingDetails,
	validationRule
}) => {
	//for multilingual
	const { t, i18n } = useTranslation(['labels', 'errors'])
	let shipmentDescriptionData = awbDetails.shipmentDescriptionData

	const history = useHistory()
	const handlePrint = async (shipmentIdentifierDetails, printName) => {
		let printResponse
		try {
			printResponse = await printAWB(shipmentIdentifierDetails, printName)
			if (printResponse.status === 'S') {
				openURLEncodedBase64NewTab(printResponse.printDetail)
			} else {
				//set alet message
			}
		} catch (err) {
			//set alet message
			addError('PRINT_AWB_FAILED', err)
		}
	}

	const items = [
		{
			label: t('CAPTURE_AWB.L-PRINT'),
			command: (e) => {
				handlePrint(
					awbDetails.awb.shipmentIdentifierDetails,
					PRINT_TYPES.AWB_L_PRINT
				)
			},
		},
		{
			label: t('CAPTURE_AWB.RECPT'),
			command: (e) => {
				handlePrint(
					awbDetails.awb.shipmentIdentifierDetails,
					PRINT_TYPES.CAP_AWBPRINT
				)
			},
		},
	]

	const [calculatedTotalWeight, setCalculatedTotalWeight] = useState(0)
	const [actualTotalIneItemWeight, setActualTotalIneItemWeight] = useState(0)
	const [totalPivoteWeightInKg, setTotalPivoteWeightInKg] = useState(0)
	const [showNotifyList, setShowNotifyList] = useState(false)
	const [ammendRate, setAmmendRate] = useState(false)
	const [showDetails, setShowDetails] = useState(false)
	const [sccCombinationError, setSccCombinationError] = useState('')
	const [calculatedInitWeight, setCalculatedInitWeight] = useState('')

	const [sccCompatibilityError, setSccCompatibilityError] = useState('')

	const [saveStatus, setSaveStatus] = useState(false)
	// const [enableButtons, setEnableButtons] = useState(false)
	const [isAllStackable, setAllStackable] = useState(
		awbDetails ? awbDetails.isAllStackable : true
	)
	const [isAllTiltable, setAllTiltable] = useState(
		awbDetails ? awbDetails.isAllTiltable : true
	)

	const [sccWarning, setSccWarning] = useState(null)
	const [additionalInfoData, setAdditionalInfoData] = useState(
		awbDetails && awbDetails.additionalInfo
			? awbDetails.additionalInfo
			: {
				type: null,
				pharma: {},
			}
	)

	const [aciError, setAciError] = useState(false)

	const [awbRoutingData, setAwbRoutingData] = useState(null)

	let initShippingItem
	const getInitShippingItem = () => {
		if (initShippingItem && initShippingItem.current) {
			return initShippingItem.current
		} else {
			return awbDetails ? awbDetails.shippingItemCode : null
		}
	}
	initShippingItem = useRef(getInitShippingItem())

	useEffect(() => {
		if (awbDetails.chargeableWeight) {
			if (awbDetails.weightUnit === WEIGHT_UNIT.KILOGRAM) {
				setAwbDetails((prevState) => ({
					...prevState,
					chargeableWeightInSelectedUnit: awbDetails.chargeableWeight,
				}))
			} else {
				setAwbDetails((prevState) => ({
					...prevState,
					chargeableWeightInSelectedUnit: calculateWeightFromKg(
						awbDetails.chargeableWeight,
						awbDetails.weightUnit
					),
				}))
			}
		}
	}, [awbDetails.chargeableWeight])

	useEffect(() => {
		if (awbDetails.totalPieces) {
			setError((prevState) => ({ ...prevState, pieces: '' }))
		}
	}, [awbDetails.totalPieces])

	const clearError = (key) => {
		const tempError = error ? { ...error } : null
		if (tempError) {
			tempError[key] = null
			setError(tempError)
		}
	}

	const handleSccCompatibilityCheck = async (sccList) => {
		try {
			const responseBody = await checkSccCompatibility(sccList)

			if (responseBody && !responseBody.compatible) {
				showSccCompatibilityError(responseBody.message)
			}
			return responseBody.compatible
		} catch (error) {
			//getToastError('Compatibility Check Error ' + error)
		}
	}

	const checkShippingTypeCombinationScc = (scc) => {
		let validCombination = true
		if (
			additionalInfoData.pharma.shippingPharmaType ==
			PHARMA_SHIPPING_TYPE.PASSIVE
		) {
			if (
				scc === SCC_CODES.PIP ||
				scc === SCC_CODES.ACT ||
				scc === SCC_CODES.FRO
			) {
				validCombination = false
			}
		}
		if (
			additionalInfoData.pharma.shippingPharmaType ==
			PHARMA_SHIPPING_TYPE.ACTIVE
		) {
			if (scc === SCC_CODES.PIP) {
				validCombination = false
			}
		}

		if (
			additionalInfoData.pharma.shippingPharmaType ==
			PHARMA_SHIPPING_TYPE.HYBRID
		) {
			if (scc === SCC_CODES.ACT) {
				validCombination = false
			}
		}
		return validCombination
	}
	const checkDryIceCombinationScc = (scc) => {
		let validCombination = true
		if (
			additionalInfoData.pharma.isDryIceAdded == 'NO' &&
			scc == SCC_CODES.ICE
		) {
			validCombination = false
		}
		return validCombination
	}
	const checkTemperatureCombinationScc = (scc) => {
		let validCombination = true
		let temperatureArray = [
			SCC_CODES.TMP1,
			SCC_CODES.TMP2,
			SCC_CODES.TMP3,
			SCC_CODES.FRO,
		]
		if (temperatureArray.includes(scc)) {
			temperatureArray.forEach(function (element) {
				if (
					scc !== element &&
					(awbDetails.additionalSccs
						.map((item) => item.scc)
						.includes(element) ||
						awbDetails.appliedSccs.map((item) => item.scc).includes(element))
				) {
					validCombination = false
				}
			})
		}
		return validCombination
	}
	const [sccCodeEntered, setSccCodeEntered] = useState('');

	const handleSccChange = (e) => {
		setSccCodeEntered(e?.target?.value)
	}

	const handleAddAdditinalScc = async (scc) => {

		let compatible
		const selectedSccList = [
			...awbDetails.shipmentSccs,
			...awbDetails.appliedSccs,
			...awbDetails.additionalSccs,
			...awbDetails.appliedServices,
		].map((item) => item.scc)

		if (!selectedSccList.length && scc) {
			//setAdditionalSccs([...additionalSccs, scc])
			setAwbDetails((prevState) => ({
				...prevState,
				shipmentSccs: [...awbDetails.shipmentSccs, scc],
			}))
		} else if (selectedSccList.length && !selectedSccList.includes(scc.scc)) {
			compatible = await handleSccCompatibilityCheck({
				selectedItems: selectedSccList,
				newItem: scc.scc,
			})

			if (additionalInfoData.pharma.shippingPharmaType && compatible) {
				if (
					!checkShippingTypeCombinationScc(scc.scc) ||
					!checkDryIceCombinationScc(scc.scc) ||
					!checkTemperatureCombinationScc(scc.scc)
				) {
					showCombinationError(
						scc.scc,
						additionalInfoData.pharma.shippingPharmaType
					)
				} else {
					//setAdditionalSccs([...additionalSccs, scc])
					setAwbDetails((prevState) => ({
						...prevState,
						additionalSccs: [...awbDetails.additionalSccs, scc],
					}))
					if (
						scc.scc === SCC_CODES.ICE &&
						additionalInfoData.pharma.tempratureRange
					) {
						setAdditionalInfoData({
							...additionalInfoData,
							pharma: { ...additionalInfoData.pharma, isDryIceAdded: 'YES' },
						})
					}
					if (
						scc.scc === SCC_CODES.ACT &&
						(awbDetails.shippingItem.product == FRESH_FORWARD_PRODUCT ||
							awbDetails.shippingItem.product == PHARMA_PRODUCT)
					) {
						setAdditionalInfoData({
							...additionalInfoData,
							pharma: {
								...additionalInfoData.pharma,
								shippingPharmaType: PHARMA_SHIPPING_TYPE.ACTIVE,
							},
						})
					}
				}
			} else if (compatible) {
				//setAdditionalSccs([...additionalSccs, scc])
				setAwbDetails((prevState) => ({
					...prevState,
					additionalSccs: [...awbDetails.additionalSccs, scc],
				}))
			}
			setSccWarning(null)
		} else {
			setSccWarning(MESSAGES.SELECTED)
		}
	}

	const handleMultipleScc = async () => {
		let multipleSccList = []
		if (sccCodeEntered && Array.isArray(sccCodeEntered)) {
			const selectedSccList = [
				...awbDetails.shipmentSccs,
				...awbDetails.appliedSccs,
				...awbDetails.additionalSccs,
				...awbDetails.appliedServices,
			].map((item) => item.scc)
			let sccCodes = sccCodeEntered.split(',')
			if (sccCodes && sccCodes.length > 0) {
				sccCodes.forEach((scc) => {
					if (scc && scc.trim() !== '' && scc.length === 3) {
						const sccData = {
							scc: scc.trim().toUpperCase(),
							sccDescription: null,
						}
						if (!selectedSccList.length) {
							multipleSccList.push(sccData)

						} else if (selectedSccList.length && !selectedSccList.includes(scc)) {
							multipleSccList.push(sccData)
						}
					}
				})
			}

		}
		return multipleSccList
	}

	const handleRemoveScc = (scc) => {
		if (awbDetails.additionalSccs.map((item) => item.scc).includes(scc.scc)) {
			//setAdditionalSccs(additionalSccs.filter((item) => item.scc !== scc.scc))
			setAwbDetails((prevState) => ({
				...prevState,
				additionalSccs: awbDetails.additionalSccs.filter(
					(item) => item.scc !== scc.scc
				),
			}))
		}
		if (awbDetails.appliedServices.map((item) => item.scc).includes(scc.scc)) {
			//setAppliedServices(appliedServices.filter((item) => item.scc !== scc.scc))
			setAwbDetails((prevState) => ({
				...prevState,
				appliedServices: awbDetails.appliedServices.filter(
					(item) => item.scc !== scc.scc
				),
			}))
		}
	}

	const showCombinationError = (selectedScc, pharmaType) => {
		let convertedPharmaType = convertToLowerCase(pharmaType)
		setSccCombinationError(
			t(MESSAGES.COMPATIBILITY_ERROR, {
				SCC1: selectedScc,
				SCC2: convertedPharmaType,
				ns: 'errors',
			})
		)
		setTimeout(function () {
			setSccCombinationError('')
		}, 3000)
	}

	const showSccCompatibilityError = (errorMsg) => {
		setSccCompatibilityError(errorMsg)
		addError('SCC_COMPATIBILITY_ERROR', errorMsg)
		setTimeout(function () {
			setSccCompatibilityError('')
		}, 3000)
	}

	const setSelectedOrigin = (origin) => {
		setAwbDetails((prevState) => ({
			...prevState,
			origin: origin,
		}))
	}
	const setSelectedDestination = (destination) => {
		setAwbDetails((prevState) => ({
			...prevState,
			destination: destination,
		}))
		if (destination && destination.code) {
			let awbRouting = awbDetails.awbRouting
			awbRouting[0].airport = destination.code
			setAwbDetails((prevState) => ({
				...prevState,
				awbRouting: awbRouting,
			}))
			setAwbRoutingData(destination.code)
		}
	}

	const setShippingItem = (shippingItem) => {
		setAwbDetails((prevState) => ({
			...prevState,
			shippingItemCode: shippingItem,
		}))
	}
	useEffect(() => {
		let shippingItem = awbDetails.shippingItemCode
		if (
			JSON.stringify(initShippingItem.current) === JSON.stringify(shippingItem)
		) {
			return
		}
		if (shippingItem && shippingItem.scc) {
			if (
				awbDetails.additionalSccs
					.map((item) => item.scc)
					.includes(shippingItem.scc)
			) {
				let tempAdditionalScc = awbDetails.additionalSccs.filter(function (
					obj
				) {
					return obj.scc !== shippingItem.scc
				})
				//setAdditionalSccs([...tempAdditionalScc])
				setAwbDetails((prevState) => ({
					...prevState,
					additionalSccs: [...tempAdditionalScc],
				}))
			}
		}
		const shipmentSccs =
			shippingItem && shippingItem.scc
				? [
					{
						scc: shippingItem.scc,
						sccDescription: shippingItem.sccDescription,
					},
				]
				: []
		// /shipmentSccs(shipmentSccs)
		setAwbDetails((prevState) => ({
			...prevState,
			shipmentSccs: shipmentSccs,
		}))
		setAwbDetails((prevState) => ({
			...prevState,
			additionalSccs: [],
		}))
		setAwbDetails((prevState) => ({
			...prevState,
			appliedSccs: [],
		}))
		setSccWarning(null)
		if (shippingItem && shippingItem.code === 'APHARMA') {
			setAdditionalInfoData({
				...additionalInfoData,
				type: 'pharma',
				pharma: {
					...additionalInfoData.pharma,
					shippingPharmaType: PHARMA_SHIPPING_TYPE.ACTIVE,
				},
			})
		}
	}, [awbDetails.shippingItemCode])

	const handleAddLineItem = (e) => {
		e.preventDefault()
		if (awbDetails.shippingType === SHIPPING_TYPE.LOOSE) {
			setAwbDetails((prevState) => ({
				...prevState,
				looseDetails: [
					...awbDetails.looseDetails,
					handleTilteableAndStackable(
						getInitLooseItemDetail(),
						isAllTiltable,
						isAllStackable
					),
				],
			}))
		} else {
			//setULDDetails([...ULDDetails, getInitULDItemDetail()])
			setAwbDetails((prevState) => ({
				...prevState,
				ULDDetails: [...awbDetails.ULDDetails, getInitULDItemDetail()],
			}))
		}
	}

	const handleChangeShippingDate = (e) => {
		setAwbDetails((prevState) => ({
			...prevState,
			shippingDate: e.target.value,
		}))
		if (!e.target.value) {
			error
				? setError({
					...error,
					[FORM_FIELDS.SHIPPING_DATE]: MESSAGES.INVALID_SHIPPING_DATE,
				})
				: setError({
					[FORM_FIELDS.SHIPPING_DATE]: MESSAGES.INVALID_SHIPPING_DATE,
				})
		}
	}

	const acceptFn = () => {
		handleSubmit(true)
	}

	const acceptFn2 = () => {
		handleSubmit(null, true)
	}

	const rejectFn = () => {
		setPopupDetails(null)
	}

	const handleErrorPopup = (message, errorData) => {
		const header = t('COMMON.CONFIRM')
		const primaryBtnLbl = t('COMMON.OK')
		const secondaryBtnLbl = t('COMMON.CANCEL')
		let errorDataList
		let aciValidation = false
		if (errorData && errorData.length > 0) {
			errorDataList = getErrorDataList(errorData)
			setAciError(true)
			aciValidation = true
		}

		setPopupDetails({
			display: true,
			header: header,
			message: message,
			primaryBtnLbl: primaryBtnLbl,
			acceptFn: aciValidation ? acceptFn2 : acceptFn,
			secondaryBtnLbl: secondaryBtnLbl,
			rejectFn: rejectFn,
			errorDataList: errorDataList
		})
	}

	const getErrorDataList = (errorData) => {
		return (
			<ul className='error-list'>
				{errorData.map((item) => {
					return(
						<li>{getErrorDataItem(item)}</li>
					)
				})
				}
			</ul>
		)
	}

	const getErrorDataItem = (item) => {
		if (item) {
			const words = item.split(" ");
			const newStr = words.slice(1).join(" ");
			return newStr
		} else {
			return item
		}

	}

	const handleSubmit = async (detachSpotRate, skipACIValidation) => {
		setIsLoading(true)
		const statuses = validateForm(awbDetails, setError, calculatedInitWeight, calculatedTotalWeight, bookingDetails,validationRule)
		if (statuses && statuses.includes(false)) {
			setIsLoading(false)
			console.log('form has errors')
			setStatus(false)
			setAlertMessage(MESSAGES.CORRECT_ERRORS_IN_AWB)

			setAmmendRate(true)
			setShowNotifyList(true)
			setAccordionsActive([0, 1])
			setShowDetails(true)
		} else {
			const multipleSccList = await handleMultipleScc()
			const status = await handleSaveCaptureAwb(
				awbDetails,
				setStatus,
				setAlertMessage,
				setIsLoading,
				multipleSccList,
				handleErrorPopup,
				detachSpotRate,
				skipACIValidation
			)
			setSaveStatus(status)
			if (status && awbDetails && awbDetails.awbExecutionDetails &&
				awbDetails.awbExecutionDetails.issuedBy && awbDetails.awbExecutionDetails.issuedPlace) {
				setEnableButtons(true)
			}

			if (status && awbDetails && awbDetails.shipmentDescriptionData && awbDetails.shipmentDescriptionData.shipmentDescription) {
				setEnableButtons(true)
			}
			if (status && awbDetails.ignoreContentCheck) {
				awbDetails.ignoreContentCheck = false
			}
		}
		window.scrollTo(0, 0)
	}

	const handleSaveCaptureHawb = async () => {
		if (
			awbDetails &&
			awbDetails.awb &&
			awbDetails.awb.shipmentIdentifierDetails
		) {
			setShipmentPrefix(
				awbDetails.awb.shipmentIdentifierDetails.shipmentPrefix
					? awbDetails.awb.shipmentIdentifierDetails.shipmentPrefix
					: null
			)
			setMasterDocumentNumber(
				awbDetails.awb.shipmentIdentifierDetails.awbDocumentNumber
					? awbDetails.awb.shipmentIdentifierDetails.awbDocumentNumber
					: null
			)
			handleTabChange(1)
		}
	}

	const handleSetError = (key, text) => {
		error
			? setError({
				...error,
				[key]: text,
			})
			: setError({
				[key]: text,
			})
	}

	const onAccordionClick = (itemIndex) => {
		setAccordionsActive(itemIndex)
	}

	const draftCallback = () => { }

	const handleAlertClose = () => {
		// setSccCombinationError('')
		// setSccCompatibilityError('')
	}

	const handleDisableButton = () => {
		return !enableButtons
	}

	const handleWeightChange = () => {
		let initChargeableWeightCalculated = 0;
		if (awbDetails.chargeableWeightInSelectedUnit !== 0 && awbDetails.totalWeight !== 0) {
			//let volumetricWeight = (Number(awbDetails.totalVolumeInMtrs) * 166.6667).toFixed(2)
			let listOfLooseItems = awbDetails.looseDetails;
			let calculatedVolume = listOfLooseItems.map((item) => {
				let volume = (Number(item.height) * Number(item.width) * Number(item.length)) / 5000
				let totalVolume = volume * item.pieces
				return totalVolume;
			})
			let volumetricWeight = calculatedVolume.reduce(
				(previousValue, currentValue) => previousValue + currentValue);


			if (awbDetails.totalWeight > volumetricWeight) {
				initChargeableWeightCalculated = awbDetails.totalWeight;
			}
			else {
				initChargeableWeightCalculated = volumetricWeight;
			}
		}
		setCalculatedInitWeight(initChargeableWeightCalculated)

		// validateChargeableWeight(
		// 	awbDetails.chargeableWeightInSelectedUnit,
		// 	setError,
		// 	initChargeableWeightCalculated
		// )
	}

	return (
		<div className='row'>
			<div className='col-12 col-lg-12'>
				<div className='form-row'>
					<div className='col-12'>
						<h4 className='section-heading pt-0 pl-0'>
							{t('CAPTURE_AWB.SHIP_DTLS')}
						</h4>
					</div>
				</div>
				<div className='form-row mb-lg-5'>
					<div
						className={`col-12 col-lg-4 mb-md-3 mb-4 form-item form-item--text ${error && error[FORM_FIELDS.ORIGIN] ? 'red-border' : ''
							}`}
						data-module='molecules/form-item/FormItem'
					>
						<label className='form-item__label'>{t('COMMON.SHP_FROM')}</label>
						<AirportAutocomplete
							autoCompleteType={AUTOCOMPLETE_TYPE.ORIGIN}
							selectedOrigin={awbDetails.origin}
							selectedDestination={awbDetails.destination}
							setSelectedOrigin={setSelectedOrigin}
							handleSetError={handleSetError}
							clearError={clearError}
							screen='MANAGE_BOOKING'
							draftCallback={draftCallback}
							setAlertMessage={setAlertMessage}
							setStatus={setStatus}
						/>
						<span className='form-item__error'>
							{error && error[FORM_FIELDS.ORIGIN]
								? t(error[FORM_FIELDS.ORIGIN], { ns: 'errors' })
								: ''}
						</span>
					</div>

					<div
						className={`col-12 col-lg-4 mb-md-3 mb-4 form-item form-item--text ${error && error[FORM_FIELDS.DESTINATION] ? 'red-border' : ''
							}`}
						data-module='molecules/form-item/FormItem'
					>
						<label className='form-item__label'>{t('COMMON.SHP_TO')}</label>

						<AirportAutocomplete
							autoCompleteType={AUTOCOMPLETE_TYPE.DESTINATION}
							selectedOrigin={awbDetails.origin}
							selectedDestination={awbDetails.destination}
							setSelectedDestination={setSelectedDestination}
							handleSetError={handleSetError}
							clearError={clearError}
							screen='MANAGE_BOOKING'
							draftCallback={draftCallback}
							setAlertMessage={setAlertMessage}
							setStatus={setStatus}
						/>
						<span className='form-item__error'>
							{error && error[FORM_FIELDS.DESTINATION]
								? t(error[FORM_FIELDS.DESTINATION], { ns: 'errors' })
								: ''}
						</span>
					</div>
					<div
						className={`col-12 col-lg-3 mb-md-3 mb-4 calendar-group form-item ${error && error[FORM_FIELDS.SHIPPING_DATE] ? 'red-border' : ''
							}`}
					>
						<label className='form-item__label'>{t('COMMON.SHP_DATE')}</label>

						<Calendar
							id={FORM_FIELDS.SHIPPING_DATE}
							name={FORM_FIELDS.SHIPPING_DATE}
							AutoComplete='false'
							className='form-item__field'
							value={moment(awbDetails.shippingDate, 'DD-MMM-YYYY').toDate()}
							dateFormat='dd M yy'
							onChange={handleChangeShippingDate}
							placeholder='Shipping date'
							showOtherMonths={false}
							minDate={moment().toDate()}
							maxDate={moment()
								.add(ALLOWABLE_BOOKING_DATE - 1, 'days')
								.toDate()}
							onFocus={(e) => {
								setError((prevState) => ({
									...prevState,
									[FORM_FIELDS.SHIPPING_DATE]: '',
								}))
							}}
							onBlur={({ target }) => { }}
						/>
						<span className='form-item__error'>
							{error && error[FORM_FIELDS.SHIPPING_DATE]
								? t(error[FORM_FIELDS.SHIPPING_DATE], { ns: 'errors' })
								: ''}
						</span>
					</div>
				</div>
				<div className='form-row mb-lg-5 mb-0'>
					<div className='col-12 col-lg-4 form-item'>
						<label className='form-item__label'>
							{t('CAPTURE_AWB.ROUTING')}
						</label>
						<div className='form-row routing-details'>
							{awbDetails.awbRouting && awbDetails.awbRouting ? (
								awbDetails.awbRouting.map((route, index) => (
									<React.Fragment>
										<div
											className='col-3 form-item form-item--text pr-1 pl-2'
											data-module='molecules/form-item/FormItem'
										>
											<input
												id={`airportCodeRoute${index}`}
												type='text'
												className='form-item__field'
												maxLength='3'
												value={route.airport}
												onChange={(e) => {
													let awbRouting = awbDetails.awbRouting
													awbRouting[index].airport = e.target.value.toUpperCase()
													setAwbDetails((prevState) => ({
														...prevState,
														awbRouting: awbRouting,
													}))
													setAwbRoutingData(e.target.value.toUpperCase())
												}}
												onBlur={(e) => {
													if (index === 0) {
														validateAlphaRequired(
															e.target.value,
															setError,
															`airportCodeRoute${index}`,
															'airport code'
														)
													} else {
														validateAlphaIfPresent(
															e.target.value,
															setError,
															`airportCodeRoute${index}`,
															'airport code'
														)
													}
												}}
												aria-invalid={hasError(
													`airportCodeRoute${index}`,
													error
												)}
												onFocus={(e) => {
													removeError(e, setError)
												}}
											/>
											<span className='form-item__error'>
												{error && error[`airportCodeRoute${index}`]
													? t(error[`airportCodeRoute${index}`], {
														ns: 'errors',
													})
													: ''}
											</span>
										</div>
										<div
											className='col-3 form-item form-item--text pl-1'
											data-module='molecules/form-item/FormItem'
										>
											<input
												id={`carrierCodeRoute${index}`}
												type='text'
												className='form-item__field'
												maxLength='2'
												value={route.carrierCode}
												onChange={(e) => {
													let awbRouting = awbDetails.awbRouting
													awbRouting[index].carrierCode = e.target.value.toUpperCase()
													setAwbDetails((prevState) => ({
														...prevState,
														awbRouting: awbRouting,
													}))
												}}
												onBlur={(e) => {
													if (index === 0) {
														validateAlphaNumericRequired(
															e.target.value,
															setError,
															`carrierCodeRoute${index}`,
															'carrier code'
														)
													} else {
														validateAlphaNumericIfPresent(
															e.target.value,
															setError,
															`carrierCodeRoute${index}`,
															'carrier code'
														)
													}
												}}
												aria-invalid={hasError(
													`carrierCodeRoute${index}`,
													error
												)}
												onFocus={(e) => {
													removeError(e, setError)
												}}
											/>
											<span className='form-item__error'>
												{error && error[`carrierCodeRoute${index}`]
													? t(error[`carrierCodeRoute${index}`], {
														ns: 'errors',
													})
													: ''}
											</span>
										</div>
									</React.Fragment>
								))
							) : (
								<></>
							)}
						</div>
					</div>
				</div>
				<form>
					<div className='form-row shipping-item-row'>
						<div className={`form-group col-12 col-lg-5 form-item mb-lg-0 mb-4 form-item--disabled`}>
							<label className='form-item__label'>
								{' '}
								{t('COMMON.SHP_ITEM')}
							</label>
							<div className='input-wrapper shipping-wrapper'>
								<ShippingItemAutocomplete
									fieldType='SHIPPING_ITEM'
									selectedShippingItem={awbDetails.shippingItemCode}
									setSelectedShippingItem={setShippingItem}
									error={error}
									clearError={clearError}
									setError={setError}
									shippingItems={shippingItems}
									placeholder={SHIPPING_ITEM_PLACEHOLDER}
									draftCallback={draftCallback}
									disabled={awbDetails.shippingItemCode ? true : false}
								/>
							</div>
							<span className='form-item__error'>
								{error && error[FORM_FIELDS.SHIPPING_ITEM]
									? t(error[FORM_FIELDS.SHIPPING_ITEM], { ns: 'errors' })
									: ''}
							</span>
							{(awbDetails.shipmentSccs && awbDetails.shipmentSccs.length) ||
								(awbDetails.additionalSccs && awbDetails.additionalSccs.length) ||
								(awbDetails.additionalSccs && awbDetails.additionalSccs.length) ? (
								<div className='form-row scc-row mt-2'>
									<SccListDisplay
										nonRemovableSccs={[
											...awbDetails.shipmentSccs,
											...awbDetails.appliedSccs,
										]}
										additionalSccs={awbDetails.additionalSccs}
										appliedServices={awbDetails.appliedServices}
										handleRemoveAdditionalScc={handleRemoveScc}
										services={[]}
									/>
								</div>
							) : (
								<></>
							)}
						</div>				
						<div
							className={`form-group additional_scc col-12 col-lg-3 form-item`}
						>
							<label className='form-item__label'>{t('COMMON.ADD_SHC')}</label>
							<div class='input-wrapper'>
								<SccAutoComplete
									handleAddAdditinalScc={handleAddAdditinalScc}
									clearError={clearError}
									setSccWarning={setSccWarning}
									handleSccChange={handleSccChange}
								/>
								<span className='form-item__error'>{sccWarning}</span>
							</div>
						</div>
					</div>
				</form>
				{sccCombinationError || sccCompatibilityError ? (
					<div className='row col-12 error-info mt-2'>
						{sccCombinationError ? (
							<Message
								severity='error'
								text={
									sccCombinationError
										? t(sccCombinationError, { ns: 'errors' })
										: sccCombinationError
								}
							></Message>
						) : (
							<Message severity='error' text={sccCompatibilityError}></Message>
						)}
					</div>
				) : (
					<></>
				)}

				<div className='form-row mb-md-3 mb-2 mt-md-3 mt-2'>
					<div
						className='col-12 col-lg-4 form-item form-item--text'
						data-module='molecules/form-item/FormItem'
					>
						<label className='form-item__label'>{t('COMMON.SHIP_DES')}</label>
						<input
							id='shipmentDescription'
							type='text'
							className='form-item__field'
							maxLength='50'
							value={shipmentDescriptionData.shipmentDescription ? shipmentDescriptionData.shipmentDescription.toUpperCase() : ''}
							placeholder='Shipment description'
							onChange={(e) => {
								shipmentDescriptionData.shipmentDescription = e.target.value
								setAwbDetails((prevState) => ({
									...prevState,
									shipmentDescriptionData: shipmentDescriptionData,
								}))
							}}
							onBlur={(e) => {
								validateRequired(e.target.value, setError, 'shipmentDescription', t('COMMON.SHIP_DES'))
							}}
							aria-invalid={hasError('shipmentDescription', error)}
							onFocus={(e) => {
								removeError(e, setError)
							}}
						/>
						<span className='form-item__error'>
							{error && error['shipmentDescription']
								? t(error['shipmentDescription'], { ns: 'errors' })
								: ''}
						</span>
					</div>
				</div>

				<div className='form-row mb-md-3 mb-2'>
					<div
						className='col-6 col-lg-4 col-xl-2 form-item form-item--text'
						data-module='molecules/form-item/FormItem'
					>
						<label className='form-item__label'> {awbDetails?.shippingItemCode?.code === "DOG" || awbDetails?.shippingItemCode?.code === "CAT" ? t('COMMON.CRTS') : t('COMMON.PCS')}: </label>
						<input
							id='pieces'
							type='text'
							className='form-item__field'
							maxLength='4'
							value={awbDetails.totalPieces}
							onChange={(e) => {
								setAwbDetails((prevState) => ({
									...prevState,
									totalPieces: e.target.value,
								}))
							}}
							onBlur={(e) => {
								if (
									validateNumericIfPresent(e.target.value, setError, 'pieces')
								) {
									if (!notZero(e.target.value)) {
										setError((prevState) => ({
											...prevState,
											pieces: MESSAGES.INVALID_PIECES,
										}))

									}
								}
							}}
							aria-invalid={hasError('pieces', error)}
							onFocus={(e) => {
								removeError(e, setError)
							}}
						/>
						<span className='form-item__error'>
							{error && error['pieces']
								? t(error['pieces'], { ns: 'errors' })
								: ''}
						</span>
					</div>
					<div
						className='col-6 col-lg-4 col-xl-2 mt-0 form-item form-item--text'
						data-module='molecules/form-item/FormItem'
					>
						<label className='form-item__label'>{t('COMMON.SLAC_PCS')}</label>
						<input
							id='slacPieces'
							type='text'
							className='form-item__field'
							maxLength='5'
							value={awbDetails.awb.quantityDetails.totalSlacPieces}
							// value={awbDetails.slacPieces}
							onChange={(e) => {
								awbDetails.awb.quantityDetails.totalSlacPieces = e.target.value,
									setAwbDetails((prevState) => ({
										...prevState,
										slacPieces: e.target.value,
									}))
							}}
							onBlur={(e) => {
								validateSlacPieces(
									e.target.value,
									setError,
									awbDetails.totalPieces
								)
							}}
							aria-invalid={hasError('slacPieces', error)}
							onFocus={(e) => {
								removeError(e, setError)
							}}
						/>
						<span className='form-item__error'>
							{error && error['slacPieces']
								? t(error['slacPieces'], { ns: 'errors' })
								: ''}
						</span>
					</div>
				</div>
				<div className='form-row mb-md-3 mb-2'>
					<div className='col-12 col-lg-4'>
						<div className='form-row'>
							<div
								className='col-6 col-lg-6 form-item form-item--text'
								data-module='molecules/form-item/FormItem'
							>
								<label className='form-item__label'>{t('COMMON.WT')}</label>
								<input
									id='weight'
									type='text'
									className='form-item__field'
									maxLength='7'
									value={awbDetails.totalWeight}
									onChange={(e) => {
										if (e.target.value.length <= 9 && (
											!e.target.value.split('.')[1] ||
											!e.target.value.split('.')[1].length ||
											e.target.value.split('.')[1].length < 3
										) &&
											(e.target.value.split('.').length <= 2)
										) {
											const value = e.target.value.replace(/[^0-9.]/g, '')
											setAwbDetails((prevState) => ({
												...prevState,
												totalWeight: value,
											}))
										}
									}
									}
									onBlur={(e) => {

										validateDecimalIfPresent(e.target.value, setError, 'weight');
										handleWeightChange();
									}}
									aria-invalid={hasError('weight', error)}
									onFocus={(e) => {
										removeError(e, setError)
									}}
								/>
								<span className='form-item__error'>
									{error && error['weight']
										? t(error['weight'], { ns: 'errors' })
										: ''}
								</span>
							</div>
							<div
								className='col-6 col-lg-4 form-item'
								style={{ marginTop: '26px' }}
							>
								<Dropdown
									value={awbDetails.weightUnit}
									options={WEIGHT_UNIT_SELECT_ITEMS_WO_TON}
									label=''
									onChange={(e) => {
										setAwbDetails((prevState) => ({
											...prevState,
											weightUnit: e.target.value,
										}))
									}}
									isUpdate={true}
								></Dropdown>
							</div>
						</div>
					</div>
					<div className='col-12 col-lg-4'>
						<div className='form-row'>
							<div
								className='col-6 col-lg-6 form-item form-item--text'
								data-module='molecules/form-item/FormItem'
							>
								<label className='form-item__label'>{t('COMMON.VOL')}</label>
								<input
									id='volume'
									type='text'
									className='form-item__field'
									maxLength='13'
									value={awbDetails.displayVolume}
									onChange={(e) => {
										if (
											(!e.target.value.split('.')[1] ||
												!e.target.value.split('.')[1].length ||
												e.target.value.split('.')[1].length < 3) &&
											e.target.value.split('.').length <= 2
										) {
											const value = e.target.value.replace(/[^0-9.]/g, '')
											setAwbDetails((prevState) => ({
												...prevState,
												displayVolume: value,
											}))
										}
									}}
									onBlur={(e) => {
										validateDecimalIfPresent(e.target.value, setError, 'volume')
									}}
									aria-invalid={hasError('volume', error)}
									onFocus={(e) => {
										removeError(e, setError)
									}}
								/>
								<span className='form-item__error'>
									{error && error['volume']
										? t(error['volume'], { ns: 'errors' })
										: ''}
								</span>
							</div>
							<div
								className='col-6 col-lg-4 form-item'
								style={{ marginTop: '26px' }}
							>
								<Dropdown
									className='cust-dropdown'
									value={awbDetails.volumeUnit}
									options={VOLUME_UNITS_SELECT_ITEMS}
									onChange={(e) => {
										setAwbDetails((prevState) => ({
											...prevState,
											volumeUnit: e.target.value,
										}))
									}}
									isUpdate={true}
								></Dropdown>
							</div>
						</div>
					</div>
					<div className='col-12 col-lg-4 mt-md-3 mt-lg-0'>
						<div className='form-row'>
							<div
								className='col-6 col-lg-6 form-item form-item--text'
								data-module='molecules/form-item/FormItem'
							>
								<label className='form-item__label'>{t('COMMON.CH_WT')}</label>
								<input
									id='chargeableWeight'
									type='text'
									className='form-item__field'
									maxLength='7'
									value={awbDetails.chargeableWeightInSelectedUnit}
									onChange={(e) => {
										if (e.target.value.length <= 9 && (
											!e.target.value.split('.')[1] ||
											!e.target.value.split('.')[1].length ||
											e.target.value.split('.')[1].length < 3
										) &&
											(e.target.value.split('.').length <= 2)
										) {
											const value = e.target.value.replace(/[^0-9.]/g, '')
											setAwbDetails((prevState) => ({
												...prevState,
												chargeableWeightInSelectedUnit: value,
											}))
										}
									}
									}
									onBlur={(e) => {
										handleWeightChange();
									}}
									aria-invalid={hasError('chargeableWeight', error)}
									onFocus={(e) => {
										removeError(e, setError)
									}}
								/>
								<span className='form-item__error'>
									{error && error['chargeableWeight']
										? t(error['chargeableWeight'], { ns: 'errors' })
										: ''}
								</span>
							</div>
							<div
								className='col-6 col-lg-4 form-item'
								style={{ marginTop: '26px' }}
							>
								<Dropdown
									value={awbDetails.weightUnit}
									options={WEIGHT_UNIT_SELECT_ITEMS_WO_TON}
									label=''
									onChange={(e) => {
										setAwbDetails((prevState) => ({
											...prevState,
											weightUnit: e.target.value,
										}))
									}}
									isUpdate={true}
								></Dropdown>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-4'></div>
				</div>
				<AdditionalCargoDetails
					awbDetails={awbDetails}
					setAwbDetails={setAwbDetails}
					isAllStackable={isAllStackable}
					setAllStackable={setAllStackable}
					isAllTiltable={isAllTiltable}
					setAllTiltable={setAllTiltable}
					handleAddLineItem={handleAddLineItem}
					setActualTotalIneItemWeight={setActualTotalIneItemWeight}
					setCalculatedTotalWeight={setCalculatedTotalWeight}
					error={error}
					setError={setError}
					setTotalPivoteWeightInKg={setTotalPivoteWeightInKg}
					totalPivoteWeightInKg={totalPivoteWeightInKg}
					showDetails={showDetails}
					setShowDetails={setShowDetails}
					setNotificationMessage={setNotificationMessage}
					setNotificationType={setNotificationType}
					handleWeightChange={handleWeightChange}
				/>
				<ParticipantDetails
					awbDetails={awbDetails}
					setAwbDetails={setAwbDetails}
					error={error}
					setError={setError}
					setShowNotifyList={setShowNotifyList}
					showNotifyList={showNotifyList}
				/>
				<RatesDetails
					awbDetails={awbDetails}
					setAwbDetails={setAwbDetails}
					setError={setError}
					error={error}
					setAmmendRate={setAmmendRate}
					ammendRate={ammendRate}
					initialLoading={initialLoading}
					setInitialLoading={setInitialLoading}
				/>
				<Accordion
					className='portal-accordion'
					multiple
					activeIndex={accordionsActive}
					onTabChange={(e) => onAccordionClick(e.index)}
				>
					<AccordionTab
						header={t('CAPTURE_AWB.OTHER_CUSTOMS_DTLS')}
						headerClassName='other-customs-details'
						contentClassName='customs-details-form'
					>
						<CustomsDetails
							awbDetails={awbDetails}
							setAwbDetails={setAwbDetails}
							error={error}
							setError={setError}
							bookingDetails={bookingDetails}
							validationRule={validationRule}
							awbRoutingData={awbRoutingData}
						/>
					</AccordionTab>
					<AccordionTab
						header={t('CAPTURE_AWB.EXEC_DTLS')}
						headerClassName='execution-details'
						contentClassName='execution-details-form'
					>
						<ExecutionDetails
							awbDetails={awbDetails}
							setAwbDetails={setAwbDetails}
							error={error}
							setError={setError}
						/>
					</AccordionTab>
				</Accordion>
				<div className='form-row mt-md-4 mt-5'>
					<div className='col-12 col-lg-auto mr-0 cta_wrap flex-column flex-lg-row'>
						<button className='button capture-save' onClick={handleSubmit}>
							{t('COMMON.SAVE')}
						</button>
					</div>

					<div className='col-12 col-lg-auto mr-0 ml-3 cta_wrap flex-column flex-lg-row d-none d-lg-block'>
						<button
							className='button secondary'
							onClick={handleSaveCaptureHawb}
							disabled={handleDisableButton()}
						>
							{t('CAPTURE_AWB.CAPTURE_HAWB')}
						</button>
					</div>

					<div className='col-12 col-lg-auto ml-lg-3 ml-0 pb-4 pt-4 pl-1 d-block d-lg-none'>
						<button
							className='button secondary captureHawb-secondary-button'
							onClick={handleSaveCaptureHawb}
							disabled={handleDisableButton()}
						>
							{t('CAPTURE_AWB.CAPTURE_HAWB')}
						</button>
					</div>
					<div className='col-12 pr-1 pl-2 d-flex d-lg-none cta_wrap flex-column flex-lg-row print-button-awb '>
						<SplitButton
							label={t('COMMON.PRINT')}
							onClick={() => {
								handlePrint(
									awbDetails.awb.shipmentIdentifierDetails,
									PRINT_TYPES.CAP_AWBPRINT
								)
							}}
							options={items}
							disabled={handleDisableButton()}
						/>
					</div>
					<div className='col-12 col-lg-auto d-none d-lg-block'>
						<SplitButton
							label={t('COMMON.PRINT')}
							onClick={() => {
								handlePrint(
									awbDetails.awb.shipmentIdentifierDetails,
									PRINT_TYPES.CAP_AWBPRINT
								)
							}}
							options={items}
							disabled={handleDisableButton()}
						/>
					</div>

				</div>
			</div>
		</div>
	)
}

export default BookingDetails
