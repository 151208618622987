import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import SearchPanel from './SearchPanel'
import { setSearchFormData } from '../../redux/actions/searchFormActions'
import { showPopup } from '../../redux/actions/popupActions'
import { closePopup } from '../../redux/actions/popupActions'
import { requestSaveTemplate, clearSaveTemplate } from '../../redux/actions/saveTemplateActions'
import { requestListTemplateNames } from '../../redux/actions/listTemplateNamesActions'
import {
	validateBooking,
	clearValidateBooking,
} from '../../redux/actions/validateBookingActions'
import { requestSaveDraft, clearSaveDraft } from '../../redux/actions/saveDraftActions'
import { getAviFeatureDetails } from '../../redux/actions/aviBookingActions';
import { getDgFeatureDetails } from '../../redux/actions/aviBookingActions';
import { requestListDrafts } from '../../redux/actions/listDraftsAction'
import { requestListQuotes } from '../../redux/actions/listQuotesActions'
import { getFromLocalStorage } from '../../utils/StorageUtils'
import sessionKeys from '../../constants/sessionKeys'

const SearchPanelContainer = ({
	searchFormData,
	setSearchFormDataToState,
	getLoadingRequest,
	loadingDetails,
	showErrorPopup,
	closeErrorPopup,
	getSaveTemplateResponse,
	getlistTemplateNamesResponse,
	listTemplateNamesResponse,
	saveTemplateResponse,
	saveTemplateError,
	awbNo,
	serialNo,
	templateParam,
	isModifyBooking,
	disableBeforeClosePrompt,
	validateBookingRequest,
	validateBookingResponse,
	validateBookingError,
	clearValidateBookingResponse,
	getSaveDraftResponse,
	saveDraftResponse,
	saveDraftError,
	getlistDraftsResponse,
	listDraftsResponse,
	clearSaveTemplateResponse,
	getlistQuoteResponse,
	listQuotesResponse,
	listQuoteError,
	clearSaveDraftResponse,
	setNotificationMessage,
	setNotificationType,
	listTemplateNamesError,
	listDraftsError,
	getAviFeatureResponse,
	aviBookingDetaills,

	isSeasonalBooking,
	seasonalError,
	setSeasonalError,
	startDate,
    setStartDate,
    endDate,
    setEndDate,
	flightList,
	setFlightList,
    flightSelectedOrigin,
    setFlightSelectedOrigin,
    flightsMaxShippingDate,
    setFlightsMaxShippingDate,
    flightsMinDate,
    setFlightsMinDate,

	schedulerMaxShippingDate,
    setSchedulerMaxShippingDate,
    schedulerMinDate,
    setSchedulerMinDate,
    weekdays,
    setWeekdays,
    dateScheduler,
    setDateScheduler,

	isListPageOpen,
    setListPageOpen,
    clearToken,
    isLoading,
    setLoading,
	setInitialList,
	navigateBack,
	gsaAgentDetails
}) => {

	useEffect(() => {
		getAviFeatureResponse();

	}, [])

	useEffect(() => {
		//console.log("propsss", aviBookingDetaills)
	}, [aviBookingDetaills])


	return (
		<SearchPanel
			searchFormData={searchFormData}
			setSearchFormDataToState={setSearchFormDataToState}
			getLoadingRequest={getLoadingRequest}
			loadingDetails={loadingDetails}
			getSaveTemplateResponse={getSaveTemplateResponse}
			getlistTemplateNamesResponse={getlistTemplateNamesResponse}
			listTemplateNamesResponse={listTemplateNamesResponse}
			saveTemplateResponse={saveTemplateResponse}
			saveTemplateError={saveTemplateError}
			showErrorPopup={showErrorPopup}
			closeErrorPopup={closeErrorPopup}
			awbNo={awbNo}
			templateParam={templateParam}
			serialNo={serialNo}
			isModifyBooking={isModifyBooking}
			disableBeforeClosePrompt={disableBeforeClosePrompt}
			validateBookingResponse={validateBookingResponse}
			validateBookingRequest={validateBookingRequest}
			validateBookingError={validateBookingError}
			clearValidateBookingResponse={clearValidateBookingResponse}
			getSaveDraftResponse={getSaveDraftResponse}
			saveDraftResponse={saveDraftResponse}
			saveDraftError={saveDraftError}
			getlistDraftsResponse={getlistDraftsResponse}
			listDraftsResponse={listDraftsResponse}
			clearSaveTemplateResponse={clearSaveTemplateResponse}
			getlistQuoteResponse={getlistQuoteResponse}
			listQuotesResponse={listQuotesResponse}
			listQuoteError={listQuoteError}
			clearSaveDraftResponse={clearSaveDraftResponse}
			setNotificationMessage={setNotificationMessage}
			setNotificationType={setNotificationType}
			listTemplateNamesError={listTemplateNamesError}
			listDraftsError={listDraftsError}
			aviBookingDetaills={aviBookingDetaills}

			isSeasonalBooking={isSeasonalBooking}
			seasonalError={seasonalError}
			setSeasonalError={setSeasonalError}

			startDate={startDate}
			setStartDate={setStartDate}
			endDate={endDate}
			setEndDate={setEndDate}
			flightList={flightList}
			setFlightList={setFlightList}
			flightSelectedOrigin={flightSelectedOrigin}
			setFlightSelectedOrigin={setFlightSelectedOrigin}
			flightsMaxShippingDate={flightsMaxShippingDate}
			setFlightsMaxShippingDate={setFlightsMaxShippingDate}
			flightsMinDate={flightsMinDate}
			setFlightsMinDate={setFlightsMinDate}

			schedulerMaxShippingDate={schedulerMaxShippingDate}
			setSchedulerMaxShippingDate={setSchedulerMaxShippingDate}
			schedulerMinDate={schedulerMinDate}
			setSchedulerMinDate={setSchedulerMinDate}
			weekdays={weekdays}
			setWeekdays={setWeekdays}
			dateScheduler={dateScheduler}
			setDateScheduler={setDateScheduler}

			isListPageOpen={isListPageOpen}
			setListPageOpen={setListPageOpen}
			clearToken={clearToken}
			isLoading={isLoading}
			setLoading={setLoading}
			setInitialList={setInitialList}
			navigateBack={navigateBack}

			gsaAgentDetails={gsaAgentDetails}
		/>
	)
}
const mapStateToProps = ({
	auth,
	searchForm,
	loadingDetails,
	saveTemplate,
	listTemplateNames,
	validateBooking,
	saveDraft,
	listDrafts,
	listQuotes,
	aviBooking,
	gsaAgent
}) => ({
	searchFormData: searchForm.searchFormData,
	loadingDetails: loadingDetails,
	saveTemplateResponse: saveTemplate.response,
	saveTemplateError: saveTemplate.error,
	listTemplateNamesResponse: listTemplateNames.response,
	validateBookingResponse: validateBooking.response,
	validateBookingError: validateBooking.error,
	saveDraftResponse: saveDraft.response,
	saveDraftError: saveDraft.error,
	listDraftsResponse: listDrafts.response,
	listQuotesResponse: listQuotes.response,
	listQuoteError: listQuotes.error,
	listTemplateNamesError: listTemplateNames.error,
	listDraftsError: listDrafts.error,
	aviBookingDetaills: aviBooking.response,
	gsaAgentDetails : gsaAgent
})
const mapDispatchToProps = (dispatch) => ({
	setSearchFormDataToState: (searchFormData) => {
		dispatch(setSearchFormData(searchFormData))
	},
	// getToastError: (message) => {
	// 	dispatch(getToastError(message))
	// },
	// getToastInfo: (message) => {
	// 	dispatch(getToastInfo(message))
	// },
	closeErrorPopup: () => {
		dispatch(closePopup())
	},
	getSaveTemplateResponse: (payload) => {
		dispatch(requestSaveTemplate(payload))
	},
	getlistTemplateNamesResponse: (payload) => {
		dispatch(requestListTemplateNames(payload))
	},
	validateBookingRequest: (awbNumber, searchFormData, selectedFlight) => {
		dispatch(validateBooking(awbNumber, searchFormData, selectedFlight))
	},
	clearValidateBookingResponse: () => {
		dispatch(clearValidateBooking())
	},
	getSaveDraftResponse: (payload) => {
		dispatch(requestSaveDraft(payload))
	},
	getlistDraftsResponse: (payload) => {
		dispatch(requestListDrafts(payload))
	},
	clearSaveTemplateResponse: () => {
		dispatch(clearSaveTemplate())
	},
	getlistQuoteResponse: (payload) => {
		dispatch(requestListQuotes(payload))
	},

	clearSaveDraftResponse: () => {
		dispatch(clearSaveDraft())
	},
	getAviFeatureResponse: () => {
		dispatch(getAviFeatureDetails())
	},

	showErrorPopup: (
		header,
		message,
		primaryBtnLbl,
		acceptFn,
		secondaryBtnLbl,
		rejectFn
	) => {
		dispatch(
			showPopup(
				header,
				message,
				primaryBtnLbl,
				acceptFn,
				secondaryBtnLbl,
				rejectFn
			)
		)
	},
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchPanelContainer)
