import { AutoComplete } from 'primereact/autocomplete'
import React, { useEffect, useState } from 'react'
import downArrow from '../../assets/images/down-arrow.png'
import minusIcon from '../../assets/images/minus.svg'
import plusIcon from '../../assets/images/plus.svg'
import upArrow from '../../assets/images/up-arrow.png'
import { Alert } from '../../common/Alert'
import ConfirmationPopUp from '../../common/ConfirmationPopUp'
import CustomsDetails from './CustomsDetails'
import { Accordion, AccordionTab } from 'primereact/accordion'
import {
	SAVE_OPERATIONAL_FLAG,
	SUCCESS_STATUS,
	UPDATE_OPERATIONAL_FLAG,
	VOLUME_UNIT_VALUES,
	WEIGHT_UNIT,
	PAGE_PRIMARY_CATEGORY,
	PAGE_SUB_CATEGORY1,
	PAYMENT_TYPES,
	CAPTURE_PAGE_SUB_CATEGORY2,
} from '../../constants'
import {
	deleteHAWBDetails,
	findHAWBDetails,
	saveHAWBDetails,
} from '../../services/dataCaptureService'
import LoadingIndicator from '../LoadingIndicator'
import {
	hasError,
	removeError,
	validateAWB,
	validateHAWB,
	validateHawbForm,
} from './CaptureHawbValidations'
import { validateAWBPrefix } from '../CaptureAWB/captureAwbUtils'
import { mapAWBResponse, mapHawbResponse } from './HawbResponseMapper'
import HawbShipmentDetails from './HawbShipmentDetails'
import './index.scss'
import ParticipantDetails from './ParticipantDetails'
import { getCurrencyList } from '../../services/dataCaptureService'
import { useTranslation } from 'react-i18next'
import Messages from '../../constants/Messages'
import { addError, pushPageData } from '../../utils/analytics'
import pageNames from '../../constants/pageNames'
import getCurrentUser from '../../utils/getCurrentUser'
import {getAWB} from '../../services/templateService'
import { getRandomIntInclusive } from '../../utils/HttpRequestUtils'
import { getValidationRule } from '../../services/quoteServices'

const CaptureHawbDetails = ({ shipmentPrefix, masterDocumentNumber }) => {
	//FOR MULTILINGUAL
	const { t, i18n } = useTranslation(['labels', 'errors'])

	const [error, setError] = useState(null)
	const [errorForNewHawb, setErrorForNewHawb] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [hawbDetails, setHawbDetails] = useState(null)
	const [filteredHawbNumbers, setFilteredHawbNumbers] = useState([])
	const [hawbNumbers, setHawbNumbers] = useState([])
	const [text, setText] = useState(null)
	const [hawbResponseDetails, setHawbResponseDetails] = useState(null)
	const [awbResponseDetails, setAwbResponseDetails] = useState(null)

	const [displayConfirmPopup, setDisplayConfirmPopUp] = useState(false)

	const [isExpanded, setIsExpanded] = useState(false)
	const [addAnother, setAddAnother] = useState(false)

	const [alertMessage, setAlertMessage] = useState('')
	const [success, setSuccess] = useState(null)

	const [hawbNumberNew, setHawbNumbernew] = useState(null)
	const [hawbNumberFromList, setHawbNumberFromlist] = useState(null)
	const [currencyList, setCurrencyList] = useState(null)
	const [accordionsActive, setAccordionsActive] = useState([])

	const [awbDetails, setAwbDetails] = useState({
		shipmentPrefix: shipmentPrefix ? shipmentPrefix : '607',
		masterDocumentNumber: masterDocumentNumber ? masterDocumentNumber : null,
	})
	const [awbDetailsData, setAwbDetailsData] = useState({});
	const [validationRule,setValidationRule]= useState([])

	const initialStateShipperDetails = {
		shipperName: '',
		shipperAddress1: '',
		shipperCity: '',
		shipperState: '',
		shipperCountry: null,
		shipperPostalCode: '',
		shipperAccountNumber: '',
		shipperPhone: '',
		shipperMail: '',
	}

	const initialStateConsigneeDetails = {
		consigneeName: '',
		consigneeAddress1: '',
		consigneeCity: '',
		consigneeState: '',
		consigneeCountry: null,
		consigneePostalCode: '',
		consigneeAccountNumber: '',
		consigneePhone: '',
		consigneeMail: '',
	}

	const initialStateShipmentDetails = {
		origin: null,
		destination: null,
		pieces: null,
		weight: null,
		weightUnit: WEIGHT_UNIT.KILOGRAM,
		volume: null,
		volumeUnit: VOLUME_UNIT_VALUES.CUBIC_METERS,
		slacPieces: null,
		shipmentDescription: null,
		scc: null,
		currencyCode: null,
		declaredValueForCustoms: null,
		paymentType: PAYMENT_TYPES.PP,
	}

	const initialStateAwbChargeDeclaration = {
		awbCurrencyCode: 'AED',
		declaredValueForCarriage: '',
		declaredValueForCustoms: '',
		prepaidCollectChargeDeclaration: '',
	}

	const initialStateCustomsInformationDetails = () => {
		let customsInfo = [{
			countryCode: '',
			customsInformationIdentifier: '',
			informationIdentifier: '',
			supplementaryCustomsControlInformation: '',
		}]
		return customsInfo
	}

	const initialStateOtherCustomsDetails = {
		sci: '',
		hccs: '',
	}

	const [shipmentDetails, setShipmentDetails] = useState(
		initialStateShipmentDetails
	)
	const [shipmentDetailsNew, setShipmentDetailsNew] = useState(
		initialStateShipmentDetails
	)

	const [shipperDetails, setShipperDetails] = useState(
		initialStateShipperDetails
	)
	const [consigneeDetails, setConsigneeDetails] = useState(
		initialStateConsigneeDetails
	)

	const [shipperDetailsNew, setShipperDetailsNew] = useState(
		initialStateShipperDetails
	)
	const [consigneeDetailsNew, setConsigneeDetailsNew] = useState(
		initialStateConsigneeDetails
	)

	const [awbChargeDeclaration, setAwbChargeDeclaration] = useState(
		initialStateAwbChargeDeclaration
	)

	const [awbChargeDeclarationNew, setAwbChargeDeclarationNew] = useState(
		initialStateAwbChargeDeclaration
	)

	const [customsInformationDetails, setCustomsInformationDetails] = useState(
		initialStateCustomsInformationDetails()
	)

	const [customsInformationDetailsNew, setCustomsInformationDetailsNew] = useState(
		initialStateCustomsInformationDetails()
	)
	const [otherCustomsDetails, setOtherCustomsDetails] = useState(
		initialStateOtherCustomsDetails
	)

	const [otherCustomsDetailsNew, setOtherCustomsDetailsNew] = useState(
		initialStateOtherCustomsDetails
	)

	useEffect(() => {
		let hawbNumbers = []
		if (hawbDetails && hawbDetails.houseDetails.length !== 0) {
			setHawbNumberFromlist(hawbDetails.houseDetails[0].hawbNumber)
			listHawbDetails(hawbDetails.houseDetails[0].hawbNumber)
			setIsExpanded(false)

			hawbDetails.houseDetails.filter((hawb) => {
				if (hawb && hawb.hawbNumber) {
					hawbNumbers.push(hawb.hawbNumber)
				}
			})

			setHawbNumbers(hawbNumbers)
		}
	}, [hawbDetails])

	useEffect(() => {
		; (async () => {
			try {
				if (hawbResponseDetails && hawbResponseDetails.shipmentDetails) {
					setShipmentDetails(hawbResponseDetails.shipmentDetails)
				}
				if (hawbResponseDetails && hawbResponseDetails.shipperDetails) {
					setShipperDetails(hawbResponseDetails.shipperDetails)
				}
				if (hawbResponseDetails && hawbResponseDetails.consigneeDetails) {
					setConsigneeDetails(hawbResponseDetails.consigneeDetails)
				}

				if (hawbResponseDetails && hawbResponseDetails.awbChargeDeclaration) {
					setAwbChargeDeclaration(hawbResponseDetails.awbChargeDeclaration)
				}
				if (hawbResponseDetails && hawbResponseDetails.customsInformationDetails) {
					setCustomsInformationDetails(hawbResponseDetails.customsInformationDetails)
				}
				if (hawbResponseDetails && hawbResponseDetails.otherCustomsDetails) {
					setOtherCustomsDetails(hawbResponseDetails.otherCustomsDetails)
				}
			} catch (error) {
				console.log(error.message)
			}
		})()
	}, [hawbResponseDetails])


	useEffect(() => {
		; (async () => {
			try {
				const currencyList = await getCurrencyList()
				let currencyCodes = [{ label: '', value: '' }]
				if (currencyList.length > 0) {
					currencyList.map((currency) => {
						currencyCodes.push({
							label: currency.code,
							value: currency.code,
						})
					})
				}
				setCurrencyList(currencyCodes)
				pushPageData(
					pageNames.HAWB_DETAILS,
					PAGE_PRIMARY_CATEGORY.E_SERVICES,
					PAGE_SUB_CATEGORY1.CAPTURE,
					CAPTURE_PAGE_SUB_CATEGORY2.CAPTURE_HAWB,
				)
			} catch (error) {
				addError('GET_CURRENCY_API_ERROR', error)
				pushPageData(
					pageNames.HAWB_DETAILS,
					PAGE_PRIMARY_CATEGORY.E_SERVICES,
					PAGE_SUB_CATEGORY1.CAPTURE,
					CAPTURE_PAGE_SUB_CATEGORY2.CAPTURE_HAWB,
				)
				console.log(error.message)
			}
		})()
	}, [])

	const getHAWBDetails = () => {
		let isAWBValid = validateAWB(
			awbDetails.masterDocumentNumber,
			'masterDocumentNumber',
			setError
		)
		let isAwWBPrefixValid = validateAWBPrefix(
			awbDetails.shipmentPrefix,
			setError
		)
		if (isAWBValid && isAwWBPrefixValid) {
			// setDisplayHawbBox(true)
			listHawbNumbers()
		}
	}

	const getFilteredHAWBNumbers = () => {
		const filteredHawbNumbers =
			text && text.length
				? hawbNumbers && hawbNumbers.length
					? hawbNumbers.filter((number) => {
						return `${number}`.toLowerCase().includes(text.toLowerCase())
					})
					: []
				: [...hawbNumbers]
		setFilteredHawbNumbers(filteredHawbNumbers)
	}
	const clearPopulatedHawbDetails = () => {
		setShipmentDetails({ ...initialStateShipmentDetails })
		setShipperDetails({ ...initialStateConsigneeDetails })
		setConsigneeDetails({ ...initialStateConsigneeDetails })
		setAwbChargeDeclaration({ ...initialStateAwbChargeDeclaration })
		console.log('initialStateCustomsInformationDetails', initialStateCustomsInformationDetails());
		setCustomsInformationDetails(initialStateCustomsInformationDetails())
		setOtherCustomsDetails({ ...initialStateOtherCustomsDetails })
		setError(null)
	}

	const getAWBRequest = () => {
		const docNumber = awbDetails.masterDocumentNumber
		const prefix = awbDetails.shipmentPrefix
		const userDetails = getCurrentUser()
		const request = {
			requestId: getRandomIntInclusive(10000, 99999),
			shipmentIdentifier: {
				shipmentPrefix: prefix,
				masterDocumentNumber: docNumber,
				agentCode: userDetails ? userDetails.agentCode : null,
			},
		}
		return request
	}

	const listHawbDetails = async (hawbNumberSelected) => {
		clearPopulatedHawbDetails()

		setHawbNumberFromlist(hawbNumberSelected)
		setIsLoading(true)
		const userDetails = getCurrentUser()
		let request = {
			requestID: Math.floor(Math.random() * 89999 + 10000),
			hawbDetailsFilter: {
				shipmentDetailsFilter: {
					shipmentPrefix: awbDetails.shipmentPrefix,
					masterDocumentNumber: awbDetails.masterDocumentNumber,
					agentCode: userDetails ? userDetails.agentCode : null
				},
				hawbNumber: hawbNumberSelected ? hawbNumberSelected : null,
			},
		}
		const expandedStatus = isExpanded ? true : false
		setIsExpanded(false)
		const responseData = await findHAWBDetails(request)
			.then((response) => {
				if (
					response &&
					response.awbDetails &&
					response.awbDetails.length !== 0
				) {
					setIsExpanded(expandedStatus)
					return response.awbDetails[0]
				} else {
					setIsLoading(false)
					if (response && response.errorDetails) {
						return response.errorDetails
					} else {
						return null
					}
				}
			})
			.catch((error) => {
				setSuccess(false)
				setAlertMessage('Sorry, the details could not retrieve at the moment')
				addError(
					'FIND_HAWB_ERROR',
					'Sorry the details could not retrieve at the moment'
				)
				setIsLoading(false)
				return null
			})

		if (responseData && !responseData.errorDetails) {
			const data = await mapHawbResponse(responseData)
			setHawbResponseDetails(data)
			setIsLoading(false)
		} else if (responseData && responseData.errorDetails) {
			setAlertMessage(`${responseData.errorDetails.errorDescription}`)
			addError(
				'FIND_HAWB_ERROR',
				`${responseData.errorDetails.errorDescription}`
			)
			setSuccess(false)
		}
	}

	const listHawbNumbers = async () => {
		setIsLoading(true)
		setError(null)
		setErrorForNewHawb(null)
		const userDetails = getCurrentUser()
		let request = {
			requestID: Math.floor(Math.random() * 89999 + 10000),
			hawbDetailsFilter: {
				shipmentDetailsFilter: {
					shipmentPrefix: awbDetails.shipmentPrefix,
					masterDocumentNumber: awbDetails.masterDocumentNumber,
					agentCode: userDetails ? userDetails.agentCode : null,
				},
			},
		}
		const hawbResponse = await findHAWBDetails(request)
			.then((response) => {
				setIsLoading(false)
				if (
					response &&
					response.awbDetails &&
					response.awbDetails.length !== 0
				) {
					return response.awbDetails[0]
				} else {
					setIsLoading(false)
					if (response && response.errorDetails) {
						return response.errorDetails
					} else {
						return null
					}
				}
			})
			.catch((error) => {
				setAlertMessage(
					'Sorry, the details could not retrieve at the moment: ',
					error
				)
				setSuccess(false)
				setHawbDetails(null)
				setIsLoading(false)
				addError(
					'FIND_HAWB_ERROR',
					'Sorry, the details could not retrieve at the moment: '
				)
				console.log(error)
			})

		if (hawbResponse && !hawbResponse.errorCode) {
			setHawbDetails(hawbResponse)
			const awbResponse = await mapAWBResponse(hawbResponse)
			setAwbResponseDetails(awbResponse)
		} else if (hawbResponse && hawbResponse.errorDescription) {
			setAlertMessage(`${hawbResponse.errorDescription}`)
			addError('FIND_HAWB_ERROR', `${hawbResponse.errorDescription}`)
			setSuccess(false)
		}
		const fetchHawbValidationRule = async () => {
			try {
				const captureType = "HAWB"
				const validationResponse = await getValidationRule(captureType);
				setValidationRule(validationResponse)

				const enabledFlags = validationResponse.map(item => item?.enabled);

				return enabledFlags.includes(true);

			} catch (error) {
				console.error('Failed to fetch validationRules', error);
			}
		};

		// Fetch the ValidationEnabledFlag data 
		const isHawbValid = await fetchHawbValidationRule();

		if (isHawbValid) {
			const fetchAWBDetails = async () => {
				try {
					const AwbdetailsDataResponse = await getAWB(getAWBRequest())
					setAwbDetailsData(AwbdetailsDataResponse)

				} catch (error) {
					console.error('Failed to fetch data', error);
				}
			};

			await fetchAWBDetails();

		}
	}

	const handleAlertClose = () => {
		setSuccess(null)
		setAlertMessage('')
	}

	const clearHawbDetails = () => {
		setHawbNumbernew(null)
		setErrorForNewHawb(null)
		setShipmentDetailsNew({ ...initialStateShipmentDetails })
		setShipperDetailsNew({ ...initialStateShipperDetails })
		setConsigneeDetailsNew({ ...initialStateConsigneeDetails })
		setAwbChargeDeclarationNew({ ...initialStateAwbChargeDeclaration })
		setCustomsInformationDetailsNew(initialStateCustomsInformationDetails())
		setOtherCustomsDetailsNew({ ...initialStateOtherCustomsDetails })
	}

	const saveHawb = (hawbNumberEntered, flag) => {
		let request
		if (flag === SAVE_OPERATIONAL_FLAG) {
			request = {
				requestID: Math.floor(Math.random() * 89999 + 10000),
				operationalFlag: flag,
				awbDetails: awbDetails,
				hawbNumber: hawbNumberEntered,
				shipmentDetails: shipmentDetailsNew,
				shipperDetails: shipperDetailsNew,
				consigneeDetails: consigneeDetailsNew,
				airwayBillDetails: awbResponseDetails,
				hawbResponseDetails: hawbResponseDetails,
				awbChargeDeclaration: awbChargeDeclarationNew,
				customsInformationDetails: customsInformationDetailsNew,
				otherCustomsDetails: otherCustomsDetailsNew,
				houseDetails: hawbDetails.houseDetails
			}
		}

		if (flag === UPDATE_OPERATIONAL_FLAG) {
			request = {
				requestID: Math.floor(Math.random() * 89999 + 10000),
				operationalFlag: flag,
				awbDetails: awbDetails,
				hawbNumber: hawbNumberEntered,
				shipmentDetails: shipmentDetails,
				shipperDetails: shipperDetails,
				consigneeDetails: consigneeDetails,
				airwayBillDetails: awbResponseDetails,
				hawbResponseDetails: hawbResponseDetails,
				awbChargeDeclaration: awbChargeDeclaration,
				customsInformationDetails: customsInformationDetails,
				otherCustomsDetails: otherCustomsDetails,
				houseDetails: hawbDetails.houseDetails
			}
		}

		setIsLoading(true)

		saveHAWBDetails(request)
			.then((response) => {
				setIsLoading(false)
				if (response && response.status === SUCCESS_STATUS) {
					setAlertMessage(
						t(Messages.HAWB_SAVE_SUCCESS, {
							HAWB: hawbNumberEntered,
							ns: 'errors',
						})
					)
					setSuccess(true)
					setIsExpanded(false)
					setAddAnother(false)
					listHawbNumbers()
					clearHawbDetails()
					if (flag === UPDATE_OPERATIONAL_FLAG) {
						setError(null)
					}
				} else if (
					response &&
					response.errorDetails
				) {
					if (response.errorDetails.errorCode && response.errorDetails.errorCode === 'operations.shipment.europeanstatusinvalid') {
						let errorMsg = 'The SCI specified is incorrect. '

						if (response.errorDetails.errorData) {
							errorMsg = errorMsg + 'The possible values for the shipment are ' + response.errorDetails.errorData + '.'
						}
						setAlertMessage((t(Messages.HAWB_SAVE_FAILURE_VALID_ERROR, {
							HAWB: hawbNumberEntered,
							ns: 'errors',
						}) + errorMsg))
					} else {
						setAlertMessage(response.errorDetails.errorDescription && response.errorDetails.errorDescription !== ''
							? (t(Messages.HAWB_SAVE_FAILURE_VALID_ERROR, {
								HAWB: hawbNumberEntered,
								ns: 'errors',
							}) + response.errorDetails.errorDescription)
							: t(Messages.HAWB_SAVE_FAILURE, {
								HAWB: hawbNumberEntered,
								ns: 'errors',
							}))
					}


					addError(
						'SAVE_HAWB_ERROR',
						` ${response.errorDetails.errorDescription}`
					)
					setSuccess(false)
				}
			})
			.catch((error) => {
				setIsLoading(false)
				setAlertMessage(t(Messages.HAWB_SAVE_FAILURE, {
					HAWB: hawbNumberEntered,
					ns: 'errors',
				}))
				setSuccess(false)

				addError('SAVE_HAWB_ERROR', error)
				console.log(error)
			})
	}

	const handleSaveAction = async (evnt) => {
		evnt.preventDefault()
		const statuses = validateHawbForm(
			awbDetails,
			hawbNumberNew,
			shipmentDetailsNew,
			shipperDetailsNew,
			consigneeDetailsNew,
			awbChargeDeclarationNew,
			customsInformationDetailsNew,
			otherCustomsDetailsNew,
			setErrorForNewHawb,
			awbDetailsData,
			validationRule
		)
		if (statuses && statuses.includes(false)) {
			setSuccess(false)
			setAlertMessage(t(Messages.CORRECT_ERRORS_BFR_SAVING, { ns: 'errors' }))
			setAccordionsActive([0])
		} else {
			await saveHawb(hawbNumberNew, SAVE_OPERATIONAL_FLAG)
		}
		window.scrollTo(0, 0)
	}

	const handleUpdateAction = async (evnt) => {
		evnt.preventDefault()
		const statuses = validateHawbForm(
			awbDetails,
			hawbNumberFromList,
			shipmentDetails,
			shipperDetails,
			consigneeDetails,
			awbChargeDeclaration,
			customsInformationDetails,
			otherCustomsDetails,
			setError,
			awbDetailsData,
			validationRule
		)

		if (statuses && statuses.includes(false)) {
			setSuccess(false)
			setAlertMessage(t(Messages.CORRECT_ERRORS_BFR_SAVING, { ns: 'errors' }))
		} else {
			await saveHawb(hawbNumberFromList, UPDATE_OPERATIONAL_FLAG)
		}
		window.scrollTo(0, 0)
	}

	const handleDeleteAction = () => {
		setIsLoading(true)
		const userDetails = getCurrentUser()
		let deleteRequest = {
			requestID: Math.floor(Math.random() * 89999 + 10000),
			hawbDetailsFilter: {
				shipmentDetailsFilter: {
					shipmentPrefix: awbDetails.shipmentPrefix,
					masterDocumentNumber: awbDetails.masterDocumentNumber,
					agentCode: userDetails ? userDetails.agentCode : null,
				},
				hawbNumber: hawbNumberFromList,
			},
		}

		deleteHAWBDetails(deleteRequest)
			.then((response) => {
				setIsLoading(false)
				if (response && response.status === SUCCESS_STATUS) {
					setAlertMessage(
						t(Messages.HAWB_DELETE_SUCCESS, {
							HAWB: hawbNumberFromList,
							ns: 'errors',
						})
					)
					setSuccess(true)
					setIsExpanded(false)
					setAddAnother(false)
					listHawbNumbers()
					clearHawbDetails()
				} else if (
					response &&
					(response.errorDetails !== null ||
						response.errorDetails !== undefined ||
						response.errorDetails.length !== 0)
				) {
					setAlertMessage(` ${response.errorDetails[0].errorDescription}`)
					addError(
						'DELETE_HAWB_ERROR',
						` ${response.errorDetails[0].errorDescription}`
					)
					setSuccess(false)
				}
			})
			.catch((error) => {
				setIsLoading(false)
				setAlertMessage(
					`Sorry, ${hawbNumberFromList} could not be deleted at the moment`
				)
				setSuccess(false)
				addError(
					'DELETE_HAWB_ERROR',
					`Sorry, ${hawbNumberFromList} could not be deleted at the moment`
				)
				console.log(error)
			})
	}

	const itemTemplate = (hawbNumber) => {
		return (
			<div className=''>
				<span className=''>{hawbNumber}</span>
			</div>
		)
	}

	const showConfirmPopUp = (evnt) => {
		evnt.preventDefault()
		setDisplayConfirmPopUp(true)
	}

	const addNewHAWB = () => {
		const addNewHAWB =
			(hawbDetails && hawbDetails.houseDetails.length === 0) || addAnother
		return addNewHAWB
	}

	const onAccordionClick = (itemIndex) => {
		setAccordionsActive(itemIndex)
	}
	return (
		<div className='capture-hawb-wrapper'>
			{isLoading ? <LoadingIndicator /> : <></>}
			{alertMessage !== '' && success !== null && (
				<Alert
					isError={!success}
					showAlert={true}
					message={alertMessage}
					setTimeout={true}
					onAlertClose={handleAlertClose}
				/>
			)}
			<div>
				<div className='form-row pl-1'>
					<div className='col-12'>
						<h4 className='page-sub-title'>{t('COMMON.AWB_NUMBER')}</h4>
					</div>
				</div>
				<div className='form-row m-t-20 mb-3'>
					<div
						className='col-12 col-lg-4 pb-3 pb-lg-0 form-item form-item--text'
						data-module='molecules/form-item/FormItem'
					>
						<div className='form-row'>
							<div className='col-3'>
								<input
									id='awbPrefix'
									name='awbPrefix'
									type='text'
									className='form-item__field'
									value={awbDetails.shipmentPrefix}
									maxLength='3'
									onChange={(e) => {
										setAwbDetails({
											...awbDetails,
											shipmentPrefix: e.target.value,
										})
									}}
									onBlur={(e) => {
										validateAWBPrefix(e.target.value, setError)
									}}
									aria-invalid={hasError('awbPrefix', error)}
									onFocus={(e) => {
										removeError(e, setError)
									}}
								/>
								<span className='form-item__error'>
									{error && error['awbPrefix']
										? t(error['awbPrefix'], { ns: 'errors' })
										: ''}
								</span>
							</div>
							<div className='col-9'>
								<input
									id='masterDocumentNumber'
									name='masterDocumentNumber'
									type='text'
									className='form-item__field'
									placeholder='AWB'
									autoComplete='off'
									maxLength='8'
									value={awbDetails.masterDocumentNumber}
									onChange={(e) => {
										if (e.target.value.length <= 8) {
											setAwbDetails({
												...awbDetails,
												masterDocumentNumber: e.target.value,
											})
										}
									}}
									aria-invalid={hasError('masterDocumentNumber', error)}
									onFocus={(evnt) => removeError(evnt, setError, error)}
									onBlur={(e) => {
										validateAWB(
											e.target.value,
											'masterDocumentNumber',
											setError
										)
									}}
								/>
								<span className='form-item__error'>
									{error && error['masterDocumentNumber']
										? t(error['masterDocumentNumber'], { ns: 'errors' })
										: ''}
								</span>
							</div>
						</div>
					</div>
					<div className='col-12 col-lg-4 cta_wrap flex-column flex-lg-row go-button'>
						<button type='button' className='button' onClick={getHAWBDetails}>
							{t('COMMON.GO')}
						</button>
					</div>
				</div>

				{hawbDetails && hawbDetails.houseDetails.length > 0 && (
					<div className='form-row pt-4'>
						<div className='col-8 col-md-6 house-total-display'>
							{t('CAPTURE_HAWB.TOTAL_HOUSE_AWB', {
								total: hawbDetails.houseDetails.length,
							})}
							{/* {hawbDetails.houseDetails.length} House
							{hawbDetails.houseDetails.length > 1 ? 's' : ''} in total */}
						</div>
						{!isExpanded ? (
							<div
								className='col-4 col-md-6 text-right'
								onClick={() => {
									setIsExpanded(true)
								}}
							>
								<i>
									<img src={downArrow} className='icon-wrapper' />
								</i>
							</div>
						) : (
							<div
								className='col-4 col-md-6 text-right'
								onClick={() => {
									setIsExpanded(false)
								}}
							>
								<i>
									<img src={upArrow} className='icon-wrapper' />
								</i>
							</div>
						)}
					</div>
				)}
			</div>

			{hawbDetails && hawbDetails.houseDetails.length > 0 && isExpanded && (
				<div id='capture-hawb-details' className='mt-3'>
					<div className='form-row mb-3'>
						<div
							className={`col-12 col-lg-4 form-item form-item--text
                           ${error && error['hawbNumber'] ? 'red-border' : ''}`}
						>
							<label className='form-item__label'>
								{t('COMMON.HAWB_NUMBER')}
							</label>
							<AutoComplete
								id='hawbNumber'
								value={hawbNumberFromList}
								suggestions={filteredHawbNumbers}
								dropdown
								completeMethod={getFilteredHAWBNumbers}
								onSelect={(e) => {
									setText(null)
									listHawbDetails(e.value)
								}}
								onChange={(e) => {
									setText(e.value)
									setHawbNumberFromlist(e.target.value)
								}}
								className='form-item__field hawb-autocomplete-inner-wrapper'
								itemTemplate={itemTemplate}
								aria-invalid={hasError('hawbNumber', error)}
								onFocus={(evnt) => removeError(evnt, setError)}
								onBlur={(e) => {
									validateHAWB(e.target.value, 'hawbNumber', setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['hawbNumber']
									? t(error['hawbNumber'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>

					<div className='section-border'></div>
					<HawbShipmentDetails
						hawbResponseDetails={hawbResponseDetails}
						shipmentDetails={shipmentDetails}
						setShipmentDetails={setShipmentDetails}
						error={error}
						setError={setError}
						currencyList={currencyList}
						setAlertMessage={setAlertMessage}
						setSuccess={setSuccess}
					/>
					<div className='section-border'></div>
					<ParticipantDetails
						hawbResponseDetails={hawbResponseDetails}
						shipperDetails={shipperDetails}
						setShipperDetails={setShipperDetails}
						consigneeDetails={consigneeDetails}
						setConsigneeDetails={setConsigneeDetails}
						awbResponseDetails={awbResponseDetails}
						initialStateShipperDetails={initialStateShipperDetails}
						initialStateConsigneeDetails={initialStateConsigneeDetails}
						error={error}
						setError={setError}
					/>

					<Accordion
						className='portal-accordion'
						multiple
						activeIndex={accordionsActive}
						onTabChange={(e) => onAccordionClick(e.index)}
					>
						<AccordionTab
							header={t('CAPTURE_AWB.OTHER_CUSTOMS_DTLS')}
							headerClassName='other-customs-details'
							contentClassName='customs-details-form'
						>
							<CustomsDetails
								hawbResponseDetails={hawbResponseDetails}
								setHawbResponseDetails={setHawbResponseDetails}
								awbChargeDeclaration={awbChargeDeclaration}
								setAwbChargeDeclaration={setAwbChargeDeclaration}
								customsInformationDetails={customsInformationDetails}
								setCustomsInformationDetails={setCustomsInformationDetails}
								otherCustomsDetails={otherCustomsDetails}
								setOtherCustomsDetails={setOtherCustomsDetails}
								error={error}
								setError={setError}
								awbDetailsData={awbDetailsData}
								validationRule={validationRule}
								shipmentDetails={shipmentDetails}
							/>
						</AccordionTab>
					</Accordion>

					<div className='form-row mt-lg-4 hawb-action-wrapper mb-4'>
						<div className='p-l-10 cta_wrap flex-column flex-lg-row'>
							<button
								type='button'
								className='button'
								onClick={(evnt) => {
									handleUpdateAction(evnt)
								}}
							>
								{t('COMMON.SAVE')}
							</button>
							<button
								type='button'
								className='button secondary mt-4 mt-lg-0 ml-lg-4'
								onClick={(evnt) => showConfirmPopUp(evnt)}
							>
								{t('COMMON.DELETE')}
							</button>
						</div>
					</div>
				</div>
			)}
			{hawbDetails && hawbDetails.houseDetails.length > 0 && (
				<div>
					<div className='form-row'>
						<div className='col-12 section-border mt-md-1 mt-xs-0'></div>
					</div>

					<div className='form-row m-t-25 mb-4'>
						{!addAnother ? (
							<div
								className='col-12 col-lg-6'
								onClick={() => {
									setAddAnother(true)
								}}
							>
								<i>
									<img src={plusIcon} className='icon-wrapper'></img>
								</i>
								<a className='link'>
									<span>{t('COMMON.ADD_ANOTHER')}</span>
								</a>
							</div>
						) : (
							<div
								onClick={() => {
									setAddAnother(false)
									clearHawbDetails()
								}}
							>
								<i>
									<img src={minusIcon} className='icon-wrapper'></img>
								</i>
								<a className='link'>
									<span>{t('COMMON.ADD_ANOTHER')}</span>
								</a>
							</div>
						)}
					</div>
				</div>
			)}

			{addNewHAWB() ? (
				<div id='capture-hawb-details'>
					<div className='form-row mb-lg-3'>
						<div
							className='col-12 col-lg-4 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label'>
								{t('COMMON.HAWB_NUMBER')}
							</label>
							<input
								id='hawbNumber'
								name='hawbNumber'
								type='text'
								className='form-item__field'
								placeholder='HAWB number'
								value={hawbNumberNew ? hawbNumberNew.toUpperCase() : ''}
								onChange={(e) => {
									setHawbNumbernew(e.target.value)
								}}
								aria-invalid={hasError('hawbNumber', errorForNewHawb)}
								onFocus={(evnt) => removeError(evnt, setErrorForNewHawb)}
								onBlur={(e) => {
									validateHAWB(e.target.value, 'hawbNumber', setErrorForNewHawb)
								}}
							/>
							<span className='form-item__error'>
								{errorForNewHawb && errorForNewHawb['hawbNumber']
									? t(errorForNewHawb['hawbNumber'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='section-border'></div>
					<HawbShipmentDetails
						shipmentDetails={shipmentDetailsNew}
						setShipmentDetails={setShipmentDetailsNew}
						error={errorForNewHawb}
						setError={setErrorForNewHawb}
						currencyList={currencyList}
						setAlertMessage={setAlertMessage}
						setSuccess={setSuccess}
					/>

					<div className='section-border'></div>

					<ParticipantDetails
						isExpanded={isExpanded}
						addAnother={addAnother}
						shipperDetails={shipperDetailsNew}
						setShipperDetails={setShipperDetailsNew}
						consigneeDetails={consigneeDetailsNew}
						setConsigneeDetails={setConsigneeDetailsNew}
						awbResponseDetails={awbResponseDetails}
						initialStateShipperDetails={initialStateShipperDetails}
						initialStateConsigneeDetails={initialStateConsigneeDetails}
						error={errorForNewHawb}
						setError={setErrorForNewHawb}
					/>
					<Accordion
						className='portal-accordion'
						multiple
						activeIndex={accordionsActive}
						onTabChange={(e) => onAccordionClick(e.index)}
					>
						<AccordionTab
							header={t('CAPTURE_AWB.OTHER_CUSTOMS_DTLS')}
							headerClassName='other-customs-details'
							contentClassName='customs-details-form'
						>
							<CustomsDetails
								hawbResponseDetails={hawbResponseDetails}
								setHawbResponseDetails={setHawbResponseDetails}
								awbChargeDeclaration={awbChargeDeclarationNew}
								setAwbChargeDeclaration={setAwbChargeDeclarationNew}
								customsInformationDetails={customsInformationDetailsNew}
								setCustomsInformationDetails={setCustomsInformationDetailsNew}
								otherCustomsDetails={otherCustomsDetailsNew}
								setOtherCustomsDetails={setOtherCustomsDetailsNew}
								error={errorForNewHawb}
								setError={setErrorForNewHawb}
								awbDetailsData={awbDetailsData}
								validationRule={validationRule}
								shipmentDetails={shipmentDetailsNew}
							/>
						</AccordionTab>
					</Accordion>

					<div className='form-row mt-lg-4  hawb-action-wrapper'>
						<div className='p-l-10 cta_wrap flex-column flex-lg-row '>
							<button
								className='button '
								onClick={(evnt) => {
									handleSaveAction(evnt)
								}}
							>
								{t('COMMON.SAVE')}
							</button>
						</div>
					</div>
				</div>
			) : (
				<></>
			)}

			{displayConfirmPopup ? (
				<ConfirmationPopUp
					displayPopUp
					setDisplayPopUp={setDisplayConfirmPopUp}
					message={t('ER201', {
						HAWB: hawbNumberFromList,
						ns: 'errors',
					})}
					primaryBtnLbl={t('COMMON.DELETE')}
					secondaryBtnLbl={t('COMMON.CANCEL')}
					acceptFn={handleDeleteAction}
				/>
			) : (
				<></>
			)}
		</div>
	)
}

export default CaptureHawbDetails
