import { Card } from "react-bootstrap";
import { setAgentDetails } from "../../redux/actions/gsaAgentAction";
import { connect } from "react-redux";
import "./GsaUserAgentSelect.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Messages from "../../constants/Messages";
import {
  doGsaAgentSelect,
  isStringEmpty,
  validatAgentCodePattern,
} from "./GsaUserAgentSelectUtil";
import { useHistory } from 'react-router-dom'
import LoadingIndicator from "../LoadingIndicator";

const GsaUserAgentSelect = ({ gsaAgentData, setToGsaAgent }) => {
  const { t } = useTranslation(["labels", "errors"]);
  const history = useHistory()

  const [agentCode, setAgentCode] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validateAgentCode = (AgentCode) => {
    if (AgentCode && !validatAgentCodePattern(AgentCode)) {
      setError(t(Messages.VALID_AGENT_CODE, { ns: "errors" }));
    }
  };

  const handleAgentLoginClick = async () => {
   await doGsaAgentSelect(
      agentCode,
      gsaAgentData,
      setIsLoading,
      setError,
      setToGsaAgent,
      history,
      t,
      false
    );
  };

  return (
    <>
      <div className="gsa-useragent-select-main-container cargo-portal-container">
        <div className="gsa-select-heading mb-5">
         {t('COMMON.GSA_USERSELECT_INFO')}
        </div>
        <div className="gsa-select-inputsection">
          <div className="gsa-select-inputsection-heading mb-4">
          {t('COMMON.AGENT_LOGIN')}
          </div>
          <div className="gsa-select-inputsection-textbox w-100 mb-4">
            <span className="p-input-icon-right w-100">
              <input
                id="AgentCode"
                className="form-item__field"
                value={agentCode}
                maxLength={11}
                placeholder="Agent code"
                onChange={(e) => {
                  if (e.target.value.length <= 11) {
                    setAgentCode(e.target.value);
                  }
                }}
                aria-invalid={!isStringEmpty(error)}
                onBlur={(e) => {
                  validateAgentCode(e.target.value);
                }}
                onFocus={() => {
                  setError("");
                }}
              />
            </span>
          </div>
          <button
            type="button"
            className="button gsa-select-inputsection-button"
            onClick={handleAgentLoginClick}
          >
            {t('COMMON.LOG_IN')}
          </button>
          <span className="error-show-area form-item__error mt-3">
            {error ? error : " "}
          </span>
        </div>
      </div>
      {isLoading && <LoadingIndicator />}
    </>
  );
};

const mapStateToProps = ({ gsaAgent }) => ({
  gsaAgentData: gsaAgent,
});

const mapDispatchToProps = (dispatch) => ({
  setToGsaAgent: (agentDetails) => {
    dispatch(setAgentDetails(agentDetails));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GsaUserAgentSelect);
