import moment from 'moment'
import { Calendar } from 'primereact/calendar'
import React, { useEffect, useRef, useState } from 'react'
import flightIcon from '../../../assets/images/cargo_flight.svg'
import flightIconFilled from '../../../assets/images/Freighter_Grey.svg'
import minusIcon from '../../../assets/images/minus.svg'
import plusIcon from '../../../assets/images/plus.svg'
import truckIcon from '../../../assets/images/cargo_truck.svg'
import { Dropdown } from '../../../common/DropDown'
import { ALLOWABLE_BOOKING_DATE } from '../../../configs/appConfigs'
import {
	AIRCRAFT_CLASSIFICATIONS,
	AUTOCOMPLETE_TYPE,
	FLIGHT_ENQUIRY_FORM_FIELDS,
	FLIGHT_TYPES,
	PAGE_SUB_CATEGORY1,
	PAGE_PRIMARY_CATEGORY,
	BOOKING_PAGE_SUB_CATEGORY2
} from '../../../constants'
import MESSAGES from '../../../constants/Messages'
import { getAirportsByCode } from '../../../services/searchPanelServices'
import getCurrentUser from '../../../utils/getCurrentUser'
import { clearItemFromSessionStorage, setToSessionStorage, getBase64Decoded, getBase64Encoded } from '../../../utils/StorageUtils'
import { checkFlightFormat, padLeadingZeros } from '../../../utils/Validations'
import AirportAutocomplete from '../../AirportAutocomplete'
import { allowAlphaOnly, restrictSpaceSpecial, validateCarrierCodeRequired, removeError, hasError } from '../../ManageBooking/ListBooking/ListbookingValidations'
import FlightItem from '../FlightItem'
import { validateFormData } from '../formUtils'
import './flight-search.scss'
import { Alert } from '../../../common/Alert'
import { useTranslation } from 'react-i18next'
import { addError, pushPageData } from '../../../utils/analytics'
import pageNames from '../../../constants/pageNames'
const FlightSearch = ({
	getFlightEnquiryList,
	showErrorPopup,
	flightEnquiryError,
	flightEnquiryResponse,
	gsaAgentDetails
}) => {
	const [flightDate, setFlightDate] = useState(moment())
	const [currentResponseNumber, setCurrentresponseNumber] = useState(5);
	const [selectedOrigin, setSelectedOrigin] = useState(null)
	const [selectedDestination, setSelectedDestination] = useState(null)
	const [flightNo, setFlightNo] = useState(null)
	const [carrierCode, setCarrierCode] = useState('EY');
	const calendarRef = useRef(null)
	const [error, setError] = useState(null)
	const { t, i18n } = useTranslation(['labels', 'errors'])
	const [flightType, setFlightType] = useState('All')
	const [aircraftClassification, setAircraftClassification] = useState('All')
	const [showFlightNoValidation, setShowFlightNoValidation] = useState(false)
	const [advancedSearch, setAdvancedSearch] = useState(false);
	const [notificationType, setNotificationType] = useState(null)
	const [notificationMessage, setNotificationMessage] = useState('')
	const [searchRequest, setSearchRequest] = useState(null)

	const { agentDetails } = gsaAgentDetails

	useEffect(() => {
		if (
			flightEnquiryResponse &&
			flightEnquiryResponse.flightItineraryList.length === 0
		) {
			addError('ER039', i18n.getResource('en', 'errors', MESSAGES.NO_FLIGHTS))
		}
		if (flightEnquiryError) {
			addError(
				'ER076',
				i18n.getResource('en', 'errors', MESSAGES.PROCESS_REQUEST_FAILED)
			)
		}
		if (flightEnquiryResponse) {
			pushPageData(
				pageNames.FLIGHT_ENQUIRY,
				PAGE_PRIMARY_CATEGORY.E_SERVICES,
				PAGE_SUB_CATEGORY1.BOOKING,
				BOOKING_PAGE_SUB_CATEGORY2.ROUTING_ENQUIRY,
				null,
				searchRequest,
			)
			setSearchRequest(null)
		}
	}, [flightEnquiryError, flightEnquiryResponse])

	const validateFlightNoRegex = (flightNumber) => {
		if (!checkFlightFormat(flightNumber)) {
			setShowFlightNoValidation(true)
			setError({
				...error,
				[FLIGHT_ENQUIRY_FORM_FIELDS.FLIGHTNUMBER]:
					MESSAGES.INVALID_FLIGHT_NUMBER,
			})
		}
	}

	const validateFlightNo = (flightNo) => {
		setShowFlightNoValidation(false)
		let newFlightNumber
		if (flightNo) {
			if (flightNo.length < 4) {
				newFlightNumber = padLeadingZeros(flightNo, 4)
				setFlightNo(
					newFlightNumber
				)
				validateFlightNoRegex(newFlightNumber)
			}

			if (flightNo.length === 4 || flightNo.length === 5) {
				validateFlightNoRegex(flightNo)
			}
		}
	}

	const handleChangeOfFlightNo = (flightNo) => {
		setShowFlightNoValidation(false)
		if (flightNo.length <= 5) {
			setFlightNo(flightNo);
		}
	}

	const clearError = (key) => {
		const tempError = error ? { ...error } : null
		if (tempError) {
			tempError[key] = null
			setError(tempError)
		}
	}
	const [searchClicked, setsearchClicked] = useState(false)
	const userDetails = getCurrentUser()
	const searchFlights = () => {
		const error = validateFormData(
			selectedOrigin,
			selectedDestination,
			flightDate,
			flightNo,
			setShowFlightNoValidation
		)
		setCurrentresponseNumber(5)
		setError(error)

		if (!error && !showFlightNoValidation) {
			setsearchClicked(true)
			let request = {
				origin: selectedOrigin ? selectedOrigin.code : null,
				destination: selectedDestination ? selectedDestination.code : null,
				flightNumber: flightNo ? flightNo : null,
				carrierCode: flightNo && carrierCode ? carrierCode : null,
				flightType: flightType && flightType === 'T'
					? 'T,ET,IT'
					: flightType === 'All'
						? null
						: flightType,
				aircraftClassification:
					aircraftClassification && aircraftClassification != 'All'
						? aircraftClassification
						: null,
				flightDate: flightDate
					? moment(flightDate).format('DD-MMM-YYYY')
					: null,
				agentCode: userDetails ? userDetails.agentCode : null,
				currency: userDetails ? userDetails.currencyCode : null,
			}
			getFlightEnquiryList(request)
			const searchInput = request
			setSearchRequest(searchInput)
		}
	}

	useEffect(() => {
		setTimeout(() => {
			clearItemFromSessionStorage('flightParameterObj')
		}, 1000)
	}, [])


	const handleShowMore = (val) => {
		setCurrentresponseNumber(currentResponseNumber + 5)
	}

	const handleAdvanceSearch = (advancedSearch) => {
		setAdvancedSearch(!advancedSearch)
	}

	const handleSetError = (key, text) => {
		error
			? setError({
				...error,
				[key]: text,
			})
			: setError({
				[key]: text,
			})
	}

	const draftCallback = () => { }

	useEffect(() => {
		const userDetails = getCurrentUser()
		let defaultAirport = userDetails && userDetails.defaultAirportCode ?  userDetails.defaultAirportCode : userDetails?.stationCode
		if(agentDetails?.agentStationCode){
			defaultAirport = agentDetails?.agentStationCode
		}
		if (userDetails && defaultAirport) {
			getAirportsByCode(defaultAirport)
				.then((defaultOrigin) => {
					setSelectedOrigin(defaultOrigin)
					pushPageData(
						pageNames.FLIGHT_ENQUIRY,
						PAGE_PRIMARY_CATEGORY.E_SERVICES,
						PAGE_SUB_CATEGORY1.BOOKING,
						BOOKING_PAGE_SUB_CATEGORY2.ROUTING_ENQUIRY
					)
				})
				.catch((error) => {
					addError('API_ERROR', error)
					pushPageData(
						pageNames.FLIGHT_ENQUIRY,
						PAGE_PRIMARY_CATEGORY.E_SERVICES,
						PAGE_SUB_CATEGORY1.BOOKING,
						BOOKING_PAGE_SUB_CATEGORY2.ROUTING_ENQUIRY
					)
					console.log(error)
				})
		}
	}, [])

	useEffect(() => {
		if (agentDetails?.agentStationCode) {
			getAirportsByCode(agentDetails.agentStationCode)
				.then((defaultOrigin) => {
					setSelectedOrigin(defaultOrigin)
					pushPageData(
						pageNames.FLIGHT_ENQUIRY,
						PAGE_PRIMARY_CATEGORY.E_SERVICES,
						PAGE_SUB_CATEGORY1.BOOKING,
						BOOKING_PAGE_SUB_CATEGORY2.ROUTING_ENQUIRY
					)
				})
				.catch((error) => {
					addError('API_ERROR', error)
					pushPageData(
						pageNames.FLIGHT_ENQUIRY,
						PAGE_PRIMARY_CATEGORY.E_SERVICES,
						PAGE_SUB_CATEGORY1.BOOKING,
						BOOKING_PAGE_SUB_CATEGORY2.ROUTING_ENQUIRY
					)
					console.log(error)
				})
		}
	}, [agentDetails])

	const handleAlertClose = () => {
		setNotificationMessage('')
		setNotificationType(null)
	}

	return (
		<div className='flight-search '>
			{notificationMessage && notificationMessage !== '' && (
				<div className='cargo-portal-container pb-2'>
					<Alert
						showAlert={true}
						message={notificationMessage}
						setTimeout={true}
						onAlertClose={handleAlertClose}
						type={notificationType}
					/>
				</div>
			)}
			<div className='flight-enquiry-form-panel'>
				<div className='form-row'>
					<div
						className={`form-group col-lg-3 ${error && error[FLIGHT_ENQUIRY_FORM_FIELDS.ORIGIN]
							? 'red-border'
							: ''
							}`}
					>
						<label className='f-15'>{t('COMMON.SHP_FROM')}</label>
						<div class='input-wrapper f-15'>
							<AirportAutocomplete
								autoCompleteType={AUTOCOMPLETE_TYPE.ORIGIN}
								selectedOrigin={selectedOrigin}
								selectedDestination={selectedDestination}
								setSelectedOrigin={setSelectedOrigin}
								handleSetError={handleSetError}
								clearError={clearError}
								draftCallback={draftCallback}
								setNotificationMessage={setNotificationMessage}
								setNotificationType={setNotificationType}
							/>
							<span className='search-panel__errormsg'>
								{error && error[FLIGHT_ENQUIRY_FORM_FIELDS.ORIGIN]
									? t(error[FLIGHT_ENQUIRY_FORM_FIELDS.ORIGIN], {
										ns: 'errors',
									})
									: ''}
							</span>
						</div>
					</div>
					<div
						className={`form-group col-lg-3 ${error && error[FLIGHT_ENQUIRY_FORM_FIELDS.DESTINATION]
							? 'red-border'
							: ''
							}`}
					>
						<label className='f-15'>{t('COMMON.SHP_TO')}</label>
						<div class='input-wrapper f-15'>
							<AirportAutocomplete
								autoCompleteType={AUTOCOMPLETE_TYPE.DESTINATION}
								selectedOrigin={selectedOrigin}
								selectedDestination={selectedDestination}
								setSelectedDestination={setSelectedDestination}
								handleSetError={handleSetError}
								clearError={clearError}
								draftCallback={draftCallback}
								setNotificationMessage={setNotificationMessage}
								setNotificationType={setNotificationType}
							/>
							<span className='search-panel__errormsg'>
								{error && error[FLIGHT_ENQUIRY_FORM_FIELDS.DESTINATION]
									? t(error[FLIGHT_ENQUIRY_FORM_FIELDS.DESTINATION], {
										ns: 'errors',
									})
									: ''}
							</span>
						</div>
					</div>
					<div
						className={`form-group col-lg-3 calendar-group ${error && error[FLIGHT_ENQUIRY_FORM_FIELDS.FLIGHTDATE]
							? 'red-border'
							: ''
							}`}
					>
						<label className='f-15'>{t('COMMON.FLT_DATE')}</label>
						<div class='input-wrapper f-15'>
							<Calendar
								className='form-control'
								ref={calendarRef}
								dateFormat='dd M yy'
								value={moment(flightDate).toDate()}
								onFocus={(e) => clearError(FLIGHT_ENQUIRY_FORM_FIELDS.FLIGHTDATE)}
								onChange={(e) => {
									setFlightDate(e.value)
								}}
								minDate={moment().toDate()}
								maxDate={moment()
									.add(ALLOWABLE_BOOKING_DATE - 1, 'days')
									.toDate()}
								placeholder='Flight date'
								onBlur={({ target }) => {
									if (!target.value) {
										error
											? setError({
												...error,
												[FLIGHT_ENQUIRY_FORM_FIELDS.FLIGHTDATE]:
													MESSAGES.VALID_SHIPPING_DATE,
											})
											: setError({
												[FLIGHT_ENQUIRY_FORM_FIELDS.FLIGHTDATE]:
													MESSAGES.VALID_SHIPPING_DATE,
											})
									}
								}}
							/>
							<span className='search-panel__errormsg'>
								{error && error[FLIGHT_ENQUIRY_FORM_FIELDS.FLIGHTDATE]
									? t(error[FLIGHT_ENQUIRY_FORM_FIELDS.FLIGHTDATE], {
										ns: 'errors',
									})
									: ''}
							</span>
						</div>
					</div>
					<div className='form-group col-lg-3 calendar-group'>
						<label htmlFor='carrierCode' className='f-15'>
							{t('COMMON.FLT_NO')}
						</label>
						<div className='form-row'>
							<div className='col-3'>
								<input
									type='text'
									className={`form-item__field pr-2 ${error && error['carrierCode']
										? 'form-item__field__red-border'
										: ''
										}`}
									aria-invalid={hasError('carrierCode', error)}
									maxLength='2'
									value={carrierCode}
									onKeyPress={(envt) => {
										return allowAlphaOnly(envt)
									}}
									onChange={({ target }) => setCarrierCode(target.value)}
									onBlur={(e) => {
										validateCarrierCodeRequired(
											flightNo,
											e.target.value,
											setError,
											'carrierCode'
										)
									}}
									onFocus={(e) => {
										removeError(e, setError)
									}}
								/>
								<span className='search-panel__errormsg'>
									{error && error['carrierCode']
										? error['carrierCode']
										: ''}
								</span>
							</div>
							<div class='flightNumber_input_box col-9'>
								<input
									type='text'
									placeholder='Flight number'
									className={`form-item__field ${error && error[FLIGHT_ENQUIRY_FORM_FIELDS.FLIGHTNUMBER]
										? 'form-item__field__red-border'
										: ''
										}`}
									aria-invalid={showFlightNoValidation}
									value={flightNo}
									onKeyPress={(e) => {
										return restrictSpaceSpecial(e)
									}}
									onFocus={(e) => {
										if (error && error[FLIGHT_ENQUIRY_FORM_FIELDS.FLIGHTNUMBER] && error[FLIGHT_ENQUIRY_FORM_FIELDS.FLIGHTNUMBER] != null) {
											error[FLIGHT_ENQUIRY_FORM_FIELDS.FLIGHTNUMBER] = null
										} else {
											return ''
										}
									}}
									onChange={(e) => handleChangeOfFlightNo(e.target.value)}
									onBlur={(e) => {
										validateFlightNo(e.target.value)
									}}
								/>
								<span className='search-panel__errormsg'>
									{error && error[FLIGHT_ENQUIRY_FORM_FIELDS.FLIGHTNUMBER]
										? error[FLIGHT_ENQUIRY_FORM_FIELDS.FLIGHTNUMBER]
										: ''}
								</span>
							</div>
						</div>
					</div>
					<div className='advance-link-wrap'>
						{/* <i>
							<img src={advancedSearch ? minusIcon : plusIcon} className='image-wrapper'>
							</img>
						</i> */}
						<a
							className='advance-link-wrap link'
							onClick={() => handleAdvanceSearch(advancedSearch)}
						>
							<span>{t('COMMON.ADV_SEARCH')}</span>
						</a>

					</div>
					{advancedSearch &&
						<>
							<div className='form-group col-lg-3 '>
								<div className='form-group'>
									<label htmlFor='flightType' className='f-15'>
										{t('ROUTING_ENQUIRY.FLT_TYP')}
									</label>
									<Dropdown
										id='flightType'
										className='dropdown'
										value={flightType}
										options={FLIGHT_TYPES}
										onChange={(e) => {
											setFlightType(e.target.value)
										}}>

									</Dropdown>
								</div>
							</div>
							<div className='form-group col-lg-3'>
								<div className='form-group'>
									<label htmlFor='aircraftClassification' className='f-15'>
										{t('ROUTING_ENQUIRY.AIRCRAFT_CLASS')}
									</label>
									<Dropdown
										id='aircraftClassification'
										className='form-group'
										value={aircraftClassification}
										options={AIRCRAFT_CLASSIFICATIONS}
										onChange={(e) => {
											setAircraftClassification(e.target.value)
										}}
									></Dropdown>
								</div>
							</div>
						</>}
				</div>
				<div className='form-row'>
					<div className='col-12 flight-enquiry__search__button cta_wrap flex-column'>
						<button
							type='button'
							className='l-btn l-btn--primary'
							onClick={() => searchFlights()}
						>
							{t('COMMON.SEARCH')}
						</button>
					</div>
				</div>
			</div>

			<div class='form-row flight-enquiry__search__title_scheduled'>
				{searchClicked &&
					flightEnquiryResponse &&
					flightEnquiryResponse.flightItineraryList.length > 0 &&
					<div class='form-row flight-enquiry__search__title_scheduled'>
						<h4 className='col-12 col-lg-4 pl-3 flight-result'>{t('ROUTING_ENQUIRY.SCD_FLT_RSLT')}</h4>
						<div className='col-12 col-lg-auto float-right'>
							<div className="icons-container" >
								<div className='icons-item'>
									<img src={flightIcon} alt='Flight' title='Flight' />  {t('ROUTING_ENQUIRY.PASSENGER_FLIGHT')}
								</div>
								<div className='icons-item'>
									<span className='freighterSpan'>
										<img src={flightIconFilled} alt='Flight' title='Flight' />
									</span>
									<span> {t('ROUTING_ENQUIRY.FREIGHTER_FLIGHT')}</span>

								</div>
								<div className='icons-item'>
									<img src={truckIcon} alt='Flight' title='Flight' />  {t('ROUTING_ENQUIRY.TRUCK')}
								</div>
							</div>
						</div>
					</div>}
			</div>
			<div className='flight-details__list'>
				{searchClicked &&
					flightEnquiryResponse &&
					flightEnquiryResponse.flightItineraryList.length === 0 ? (
					<div className='no-data'>
						{t(MESSAGES.NO_FLIGHTS, { ns: 'errors' })}
					</div>
				) : (
					<></>
				)}
				{searchClicked &&
					flightEnquiryResponse &&
					flightEnquiryResponse.flightItineraryList.length ? (
					flightEnquiryResponse.flightItineraryList.slice(0, currentResponseNumber).map(
						(destinationAirport, index) => (
							<FlightItem
								destinationAirport={destinationAirport}
								key={index}
								selectedOrigin={selectedOrigin}
								selectedDestination={selectedDestination}
								flightDate={flightDate} />
						)
					)
				) : (
					<></>
				)}
				{searchClicked &&
					flightEnquiryResponse &&
					(flightEnquiryResponse.flightItineraryList.length > 5 && flightEnquiryResponse.flightItineraryList.length >= currentResponseNumber) ?
					<p style={{ textAlign: "end", cursor: "pointer", padding: "10px" }} onClick={() => handleShowMore()}>{t('ROUTING_ENQUIRY.SHOW_MORE')}</p> : <></>}

				{searchClicked && flightEnquiryError &&
					<div className='no-data'>
						{t(MESSAGES.PROCESS_REQUEST_FAILED, { ns: 'errors' })}
					</div>}
			</div>
		</div>
	)
}

export default FlightSearch
