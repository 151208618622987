import moment from 'moment'
import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'
import { NOTIFICATION_MESSAGE_TYPE } from '../../constants'
import MESSAGES from '../../constants/Messages'
import { sendEmailForAssistance } from '../../utils/ExceptionHandlingUtil'
import { useTranslation } from 'react-i18next'
import LoadingIndicator from '../LoadingIndicator'


const AlternateRouteEnquiryPopup = ({
	setShowEmailEnquiryPopup,
	selectedDestination,
	selectedOrigin,
	shippingDate,
	shippingItem,
	setNotificationMessage,
	setNotificationType,
	additionalSccs,
	errorCode,
	totalGrossWeight,
	totalVolume,
}) => {
	const { t } = useTranslation(['labels'])
	const [isLoading, setIsLoading] = useState(false);

	const handleCancel = () => {
		setShowEmailEnquiryPopup({
			isOpen: false,
			errorCode: null
		})
	}

	const handleConfirm = async () => {
		const getReason = () => {
			switch (errorCode) {
				case 'ER044':
					return 'capacity restrictions.'
				case 'ER047':
					return 'shipment dimensions restrictions.'
				case 'ER046':
					return 'other restrictions.'
				default:
					return 'restrictions'

			}
		}

		const attributes = [
			{
				key: 'origin',
				value: selectedOrigin ? selectedOrigin.code : null,
			},
			{
				key: 'destination',
				value: selectedDestination ? selectedDestination.code : null,
			},
			{
				key: 'shippingItem',
				value: shippingItem &&
					shippingItem?.description &&
					shippingItem?.product
					? `${shippingItem?.description}-${shippingItem?.product}`
					: null,
			},
			{
				key: 'shippingDate',
				value: shippingDate ? moment(shippingDate).format('DD MMM YYYY') : null,
			},
			{
				key: 'sccs',
				value: getSccs(additionalSccs),
			},
			{
				key: 'totalGrossWeight',
				value: totalGrossWeight
			},
			{
				key: 'totalVolume',
				value: totalVolume
			}
		]
		
		const replacements = {
			'%ORG%': selectedOrigin ? selectedOrigin.code : '',
			'%DES%': selectedDestination ? selectedDestination.code : '',
			'%REASON%': getReason()
		}

		const response = await sendEmailForAssistance(
			'ALTERNATE_ROUTING_ENQUIRY',
			setIsLoading,
			attributes,
			replacements
		)
		setShowEmailEnquiryPopup({
			isOpen: false,
			errorCode: null
		})

		if (response) {
			setNotificationMessage(t(MESSAGES.ASSISTANCE_MSG, { ns: 'errors' }))
			setNotificationType(NOTIFICATION_MESSAGE_TYPE.INFO)
		} else {
			setIsLoading(false)
			setNotificationMessage(
				t(MESSAGES.MAIL_ASSISTANCE_FAILS, { ns: 'errors' })
			)
			setNotificationType(NOTIFICATION_MESSAGE_TYPE.ERROR)
		}
	}

	const getSccs = (additionalSccs) => {
		if (additionalSccs && additionalSccs.length) {
			return additionalSccs.map((e) => e.scc).toString()
		} else {
			return null
		}
	}

	const RenderFooter = () => {
		return (
			<div>
				<button icon='' className='button' onClick={handleCancel} >
					{t('COMMON.GO_BACK')}
				</button>
				<button icon='' className='button secondary' onClick={handleConfirm}>
					{t('COMMON.SEND_ENQUIRY')}
				</button>
			</div>
		)
	}

	return (
		<div className='cargo-portal-container dialog-container'>
			<div className='portal-dialog p-fluid'>
				<Dialog
					footer={<RenderFooter />}
					visible
					modal
					closable={true}
					header=''
					onHide={handleCancel}
				>
					<h3 className='mt-1 mb-4'>{t('BOOKING.ASSISTANCE_HEAD')}</h3>
					<p className='mt-2'>
						{t('BOOKING.ALTERNATE_ROUTE_ENQUIRY')}
					</p>
				</Dialog>
			</div>
			{isLoading && <LoadingIndicator />}
		</div>
	)
}

export default AlternateRouteEnquiryPopup
