import React from 'react'
import { connect } from 'react-redux'
import { setAccessToken, setRefreshToken } from '../../redux/actions/authAction'
import PageHeading from './PageHeading'
import { setAgentDetails } from '../../redux/actions/gsaAgentAction'

const PageHeadingContainer = ({ 
		principal,
		accessToken,
		setRefreshTokenToState,
		setAccessTokenToState,
		isLoading,
		gsaAgentData,
		setToGsaAgent
	}) => (
		<PageHeading 
			principal={principal}
			accessToken={accessToken}
			setAccessToken={setAccessTokenToState}
			setRefreshToken={setRefreshTokenToState}
			isLoading={isLoading}
			gsaAgentData={gsaAgentData}	
			setToGsaAgent={setToGsaAgent}
		/>
	)

const mapStateToProps = ({ auth, loader ,gsaAgent}) => ({
	principal: auth.principal,
	accessToken: auth.accessToken,
	isLoading: loader.isLoading,
	gsaAgentData : gsaAgent
})

const mapDispatchToProps = (dispatch) => ({
	setRefreshTokenToState: (refreshToken) => {
		dispatch(setRefreshToken(refreshToken))
	},
	setAccessTokenToState: (accessToken) => {
		dispatch(setAccessToken(accessToken))
	},
	setToGsaAgent: (agentData)=>{
		dispatch(setAgentDetails(agentData))
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(PageHeadingContainer)
