import { TabPanel, TabView } from 'primereact/tabview'
import React, { useEffect, useState } from 'react'
import { USER_PRIVLILEGES } from '../../constants'
import getCurrentUser from '../../utils/getCurrentUser'
import CreateUser from './CreateUser'
import './index.scss'
import ListUser from './ListUser/ListUser'
import UpdateUser from './UpdateUser'
import ViewUserProfile from './ViewUserProfile'
import { useTranslation } from 'react-i18next'
import { getFromLocalStorage } from '../../utils/StorageUtils'
import sessionKeys from '../../constants/sessionKeys'
import { getRegionByAirportCode } from '../../services/userProfileService'
import { GSA_USER_CONST } from '../../constants';
import LoadingIndicator from "../../components/LoadingIndicator";

const UserManagement = ({ listUserResponse, getuser, principal }) => {
	//for multilingual
	const { t, i18n } = useTranslation(['labels', 'errors'])
	const [activeIndex, setActiveIndex] = useState(0)
	const [updateUser, setUpdateUser] = useState(false)
	const [userId, setUserId] = useState('')
	const [userList, setUserList] = useState([])
	const [addUser, setAddUser] = useState(false)

	const [showMessage,setShowMessage]=useState(false)

	const [updateUserSuccess, setUpdateUserSuccess] = useState({
		success: false,
		message: '',
	})
	const [addUserSuccess, setAddUserSuccess] = useState({
		success: false,
		message: '',
	})
	
	const [agentCode, setAgentCode] = useState(null)
	const [customerCode, setCustomerCode] = useState(null)
	const [companyName, setCompanyName] = useState(null)
	const [companyCode, setCompanyCode] = useState(null)
	const [isEnterpriseUser, setIsEnterpriseUser] = useState(null)
	const [isGsaUser, setGsaUser ] = useState(false)
	const [stationCode, setStationCode] = useState(null)
	const [defaultAirportCode, setDefaultAirportCode] = useState(null)
	const [hideUserList, setHideUserList] = useState(false);
	const [userRegion, setUserRegion] = useState(false);
	const [agentRegion, setAgentRegion] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const userDetails = getCurrentUser()
	const isAdmin =
		userDetails &&
		(userDetails.userType === 'KU' || userDetails.userType === 'PA')
			? true
			: false

	useEffect(() => {
		const isEnterpriseUser = getFromLocalStorage('SSOType') === 'E' ? true : false
		setIsEnterpriseUser(isEnterpriseUser)
		// if (!isEnterpriseUser && userDetails.userType != 'PA') {
		// 	listUsers()
		// }

		/* if(!isEnterpriseUser || (isEnterpriseUser && userDetails.userType != 'PA')) { */
		if(!isEnterpriseUser){
			listUsers() 
		}
	}, [])

	useEffect(() => {
		const localStorageSub = getFromLocalStorage(sessionKeys.SUBJECT)
		const userDataSub = JSON.parse(localStorageSub)
		const enterpriseUser = getFromLocalStorage('SSOType') === 'E' ? true : false
		// if(userDataSub.type === GSA_USER_CONST.CONSUMER && userDataSub.groups === GSA_USER_CONST.PROCUREMENT_GROUP){
		// 	setGsaUser(true);
		// }
		if(userDataSub.roles?.includes('GSA_USER') || userDataSub.roles?.includes('GSA_ADMIN_USER')){
			setGsaUser(true)
		} else if (enterpriseUser && !userDetails && userDataSub.roles?.includes('CSN_USER')) {
			setGsaUser(true)
		}
	},[])

	useEffect(() => {
		if (listUserResponse) {
			let filteredList
			if (userDetails != null && listUserResponse.userListType) {
				filteredList = listUserResponse.userListType.filter((user) => {
					return userDetails.userId != user.userId
				})
				setUserList(filteredList)
				if (listUserResponse.userListType.length === 0 || filteredList.length === 0){
					setShowMessage(true)
				}
			} else if (listUserResponse.userListType) {
				setUserList(listUserResponse.userListType)
				 if (listUserResponse.userListType.length === 0){
					setShowMessage(true)
				}
			}
		}
	}, [listUserResponse])

	useEffect(() => {
		if(userRegion && agentRegion){
			userRegion !== agentRegion ? setHideUserList(true) : setHideUserList(false)
		}
	},[userRegion,agentRegion])

	const listRegionBasedUsers = (airportCode) => {
		setUserRegion(false);
		setAgentRegion(false);
		if(airportCode && userDetails){
			setHideUserList(false)
			//get user region by airportCode
			getRegionByAirportCode(userDetails.defaultAirportCode).then((res) => {
				setUserRegion(res)
				setIsLoading(false)
			})

			//get agent region by airportCode
			getRegionByAirportCode(airportCode).then((res) => {
				setAgentRegion(res)
				setIsLoading(false)
			})
		}
	}
	

	const GsalistUsers = (userData) => {
		const request = {
			customer: agentCode,
			companyCode: userData && userData.companyCode ? userData.companyCode : 'EY',
			status: null,
		}
			getuser(request)
	}

	const listUsers = () => {
		const request = {
			customer:
				userDetails && userDetails.agentCode ? userDetails.agentCode : null,
			companyCode:
				userDetails && userDetails.companyCode ? userDetails.companyCode : 'EY',
			status: null,
		}
		if (request.customer != null || request.customer != undefined) {
			getuser(request)
		}
	}

	const handleUpdateUser = (userCode, show) => {
		if (userCode && show) {
			setUserId(userCode)
			setUpdateUser(true)
		} else {
			setUpdateUser(false)
		}
	}

	const handleTabChange = (index) => {
		setActiveIndex(index)
		setUpdateUser(false)
		setAddUser(false)
	}

	return (
		<div className='container-lg portal-tabs tabs-user cargo-portal-container'>
			{isLoading ? <LoadingIndicator /> : ''}
			{userDetails && activeIndex === 0 ? (
				<>
					<h3 className='welcome-head-wrapper'>
						{`${t('UM.WELCOME')}, `}
						{userDetails.firstName !== null &&
							userDetails.firstName !== undefined &&
							userDetails.firstName.length > 0 && (
								<span className='custom-pl-2'>{userDetails.firstName}</span>
							)}
						{userDetails.middleName !== null &&
							userDetails.firstName !== undefined &&
							userDetails.middleName.length > 0 && (
								<span className='pl-2'>{userDetails.middleName}</span>
							)}
						{userDetails.lastName !== null &&
							userDetails.firstName !== undefined &&
							userDetails.lastName.length > 0 && (
								<span className='pl-2'>{userDetails.lastName}</span>
							)}
					</h3>
					{isAdmin ? (
						<h3 className='welcome-sub-head-wrapper mt-0 mb-4'>
							{t('UM.WELCOME_USER_INFO')}
						</h3>
					) : (
						<></>
					)}
				</>
			) : (
				<></>
			)}

			{isGsaUser ?

			<TabView
				activeIndex={activeIndex}
				onTabChange={(e) => handleTabChange(e.index)}
			>

				<TabPanel
					header={`${t('UM.USERS')} ${(userList && userList.length && !hideUserList && !isLoading) ? `(${userList.length})` : ''}`}
					//headerClassName={!isAdmin ? 'sub-user-tab' : ''}
				>
					{!updateUser && !addUser && (
						<ListUser
							userList={userList}
							setUserList={setUserList}
							showMessage={showMessage}
							setShowMessage={setShowMessage}
							getuser={getuser}
							handleUpdateUser={handleUpdateUser}
							userDetails={userDetails}
							setAddUser={setAddUser}
							updateUserSuccess={updateUserSuccess}
							setUpdateUserSuccess={setUpdateUserSuccess}
							addUserSuccess={addUserSuccess}
							setAddUserSuccess={setAddUserSuccess}
							agentCode={agentCode}
							setAgentCode={setAgentCode}
							isGsaUser={isGsaUser}
							setStationCode={setStationCode}
							setDefaultAirportCode={setDefaultAirportCode}
							listRegionBasedUsers = {listRegionBasedUsers}
							hideUserList={hideUserList}
							setIsLoading={setIsLoading}
							isLoading={isLoading}
							setHideUserList={setHideUserList}
							setCustomerCode={setCustomerCode}
							setCompanyName={setCompanyName}
							setCompanyCode={setCompanyCode}
						/>
					)}
					{updateUser && (
						<UpdateUser
							userId={userId}
							setUpdateUser={setUpdateUser}
							setUpdateUserSuccess={setUpdateUserSuccess}
							updateUserSuccess={updateUserSuccess}
							listUsers={GsalistUsers}
						/>
					)}
					{addUser && (
						<CreateUser
							setAddUser={setAddUser}
							setAddUserSuccess={setAddUserSuccess}
							addUserSuccess={addUserSuccess}
							agentCode={agentCode}
							setAgentCode={setAgentCode}
							stationCode={stationCode}
							defaultAirportCode={defaultAirportCode}
							listUsers={GsalistUsers}
							isGsaUser={isGsaUser}
							customerCode={customerCode}
							companyName={companyName}
							companyCode={companyCode}
						/>
					)}
				</TabPanel>
			</TabView>
			:
			<TabView
				activeIndex={activeIndex}
				onTabChange={(e) => handleTabChange(e.index)}
			>
				<TabPanel header={t('UM.PROFILE')} headerClassName='my-profile-tab'>
					<ViewUserProfile />
				</TabPanel>

				<TabPanel
					header={`${t('UM.USERS')} ${(userList && userList.length) ? `(${userList.length})` : ''}`}
					headerClassName={!isAdmin ? 'sub-user-tab' : ''}
				>
					{!updateUser && !addUser && (
						<ListUser
							userList={userList}
							setUserList={setUserList}
							showMessage={showMessage}
							setShowMessage={setShowMessage}
							getuser={getuser}
							handleUpdateUser={handleUpdateUser}
							userDetails={userDetails}
							setAddUser={setAddUser}
							updateUserSuccess={updateUserSuccess}
							setUpdateUserSuccess={setUpdateUserSuccess}
							addUserSuccess={addUserSuccess}
							setAddUserSuccess={setAddUserSuccess}
							agentCode={agentCode}
							setAgentCode={setAgentCode}
							setIsLoading={setIsLoading}
							isLoading={isLoading}
							setCustomerCode={setCustomerCode}
							setCompanyName={setCompanyName}
							setCompanyCode={setCompanyCode}
							setStationCode={setStationCode}
							setDefaultAirportCode ={setDefaultAirportCode}
							listRegionBasedUsers = {listRegionBasedUsers}
							isGsaUser={isGsaUser}
						/>
					)}
					{updateUser && (
						<UpdateUser
							userId={userId}
							setUpdateUser={setUpdateUser}
							setUpdateUserSuccess={setUpdateUserSuccess}
							updateUserSuccess={updateUserSuccess}
						/>
					)}
					{addUser && (
						<CreateUser
							setAddUser={setAddUser}
							setAddUserSuccess={setAddUserSuccess}
							addUserSuccess={addUserSuccess}
							agentCode={agentCode}
							setAgentCode={setAgentCode}
							isGsaUser={isGsaUser}
							customerCode={customerCode}
							companyName={companyName}
							companyCode={companyCode}
							stationCode={stationCode}
							defaultAirportCode={defaultAirportCode}
						/>
					)}
				</TabPanel>
			</TabView>
			}
		</div>
	)
}

export default UserManagement
