import React, { useEffect, useRef, useState } from 'react'
import FilterOptions from '../FilterOptions'
import FlightDetails from '../FlightDetails'
import './flight-details-list.scss'
import MESSAGES from '../../constants/Messages'
import { useTranslation } from 'react-i18next'
import { addError,pushEventInstantConfirmation } from '../../utils/analytics'
import AlternateRouteEnquiryPopup from './AlternateRouteEnquiryPopup'
import { Alert } from '../../common/Alert'
const FlightDetailsList = ({
	setEnquiredPresent,
	airportList,
	selectedDate,
	response,
	setInstantConfimation,
	selectedDestination,
	selectedOrigin,
	shippingDate,
	shippingItem,
	additionalSccs,
	totalGrossWeight,
	totalVolume,
}) => {

	const { t, i18n } = useTranslation(['labels','errors'])
	const prevAirportList = usePrevious(airportList);
	const[showEmailEnquiryPopup,setShowEmailEnquiryPopup] = useState({
		isOpen: false,
		errorCode: null
	});
	const [notificationType, setNotificationType] = useState(null)
	const [notificationMessage, setNotificationMessage] = useState('')

	function usePrevious(value) {
		const ref = useRef();
		useEffect(() => {
		  ref.current = value;
		}, [value]);
		return ref.current;
	  }

	/*useEffect(() => {
		console.log('airportList', airportList, prevAirportList);
		if(JSON.stringify(airportList) !== JSON.stringify(prevAirportList)){
		if(airportList.length > 0 && response && response.isLoading === false){
			let airportsFilteredByValidTotalRate = airportList.filter((airport => airport.ratingDetails[0].totalRate !== 0))
			if(airportsFilteredByValidTotalRate.length === 0){
				pushEventInstantConfirmation('No');
			}
			else{
				let airportsFilteredByNoInstantData = airportList.filter((airport) => 
				airport.overBookingAllowed !== false || airport.overHangShipment !== false 
				|| airport.bigShipment !== false || airport.isForceQueued !== false)
				if(airportsFilteredByNoInstantData.length !== 0){
					pushEventInstantConfirmation('No');
				}
				else{
					pushEventInstantConfirmation('Yes');
				}
			}
		}
		else if(airportList.length === 0 && response && response.isLoading === false){
			console.log('pushEventInstantConfirmation - No');
			pushEventInstantConfirmation('No');
		}
	}
	},[airportList])*/

	const getFlightDetails = (flightSelected) => {
		let selectedFlight = flightSelected
		const totalSurcharge = calculateTotalSurcharge(selectedFlight)
		if (totalSurcharge !== 0) {
			selectedFlight.ratingDetails[0].totalSurcharge = totalSurcharge
		}
		return selectedFlight
	}

	const calculateTotalSurcharge = (flightSelected) => {
		let totalSurcharge = 0.0
		if (
			flightSelected.ratingDetails &&
			flightSelected.ratingDetails.length > 0 &&
			flightSelected.ratingDetails[0].otherChargeDetails &&
			flightSelected.ratingDetails[0].otherChargeDetails.length > 0
		) {
			flightSelected.ratingDetails[0].otherChargeDetails.forEach(function (
				otherCharge
			) {
				if (otherCharge.chargeAmount) {
					totalSurcharge = totalSurcharge + otherCharge.chargeAmount
				}
			})
		}
		return totalSurcharge
	}

	const handleAlertClose = () => {
		setNotificationMessage('')
		setNotificationType(null)
	}

	const getNoFlightErrorMsgWithHyperLink = (response) => {
		const errorMsgToDisplay = response.error
			? t(response.error.description, { ns: "errors" })
			: t(MESSAGES.NO_FLIGHTS, { ns: "errors" });
		const errorCodeFromResponse = response?.error?.description
		const isEnquiryHyperLinkShowCase =
			response.error &&
			["ER044", "ER047", "ER046"].includes(errorCodeFromResponse);
		return (
			<div className="no-flights-error">
				{errorMsgToDisplay}
				{isEnquiryHyperLinkShowCase && (
					<button
						className="enquiry-hyperlink"
						onClick={() => setShowEmailEnquiryPopup({ isOpen: true, errorCode: errorCodeFromResponse })}
					>
						{t('BOOKING.ALTERNATE_ROUTE_ENQUIRY_HYPERLINK')}
					</button>
				)}
			</div>
		);
	};
	
	return (
		<>
			<div className='flight-details col-12 mb-2'>
				{/* <div className='fd-header'> */}
					{/* <div className='fd-header__left'> */}
					{/* <div className='fd-header__results'>{`${
						airportList && airportList.length ? airportList.length : 0
					}`}</div>
					&nbsp;
					<div className='fd-header__results'>{t('CHOOSE_FLT.NO_OF_FLTS')}</div> */}
					{/* <div className='flights_count'>
						<div className='fd-header__results'>{`${
							airportList && airportList.length ? airportList.length : 0
						}`}</div>
						&nbsp;
						<div className='fd-header__results'>{`Flights`}</div>
					</div> */}
					{/* <div> */}
				<FilterOptions airportList={airportList} selectedDate={selectedDate} />
					{/* </div> */}
				{/* </div> */}
				{/*<div className="fd-header__right">
					<a className='l-link'>
						<span>Clear Selection</span>
					</a>
				</div>*/}
			</div>
			<div className='flight-details__list col-12'>
				{airportList && airportList.length ? (
					airportList.map((destinationAirport, index) => (
						<FlightDetails
							setEnquiredPresent={setEnquiredPresent}
							destinationAirport={destinationAirport}
							key={index}
						/>
					))
				) : (
					<></>
				)}
			</div>
			{/* </div> */}
			{notificationMessage && notificationMessage !== '' && (
				<div className='cargo-portal-container pl-3 pr-3 pb-2 pt-0'>
					<Alert
						type={notificationType}
						showAlert={true}
						message={notificationMessage}
						setTimeout={true}
						onAlertClose={handleAlertClose}
					/>
				</div>
			)}
			{response && airportList && airportList.length === 0 ? (
				<div className='col-12'>
					<div className='no-data'>
						{response && response.isLoading
							? t(MESSAGES.SEARCHING, { ns: 'errors' })
							: getNoFlightErrorMsgWithHyperLink(response)
						}
					</div>
				</div>
			) : (
				<></>
			)}
			{showEmailEnquiryPopup && showEmailEnquiryPopup.isOpen && <AlternateRouteEnquiryPopup
				setShowEmailEnquiryPopup={setShowEmailEnquiryPopup}
				additionalSccs={additionalSccs}
				selectedDestination={selectedDestination}
				selectedOrigin={selectedOrigin}
				shippingDate={shippingDate}
				shippingItem={shippingItem}
				totalGrossWeight={totalGrossWeight}
				totalVolume={totalVolume}
				setNotificationMessage={setNotificationMessage}
				setNotificationType={setNotificationType}
				errorCode={showEmailEnquiryPopup?.errorCode || ''}
			/>}
		</>
	)
}

export default FlightDetailsList
