import sessionKeys from "../../constants/sessionKeys";
import getCurrentUser from "../../utils/getCurrentUser";
import {
	clearItemFromLocalStorage,
	getFromLocalStorage,
	setToLocalStorage,
} from "../../utils/StorageUtils";
import { GSA_AGENT_SELECT } from "../constants";

const initialState = {
	agentDetails: JSON.parse(getFromLocalStorage(sessionKeys.GSA_AGENT_DETAILS)),
};

export default function (state = initialState, action) {
	switch (action.type) {
		case GSA_AGENT_SELECT.SET_AGENT_DETAILS:
			const { agentDetails } = action;
			setToLocalStorage(
				sessionKeys.GSA_AGENT_DETAILS,
				JSON.stringify(agentDetails)
			  );
			return {
				...state,
				agentDetails,
			};
		case GSA_AGENT_SELECT.CLEAR:
			clearItemFromLocalStorage(sessionKeys.GSA_AGENT_DETAILS);
			const currentUserDetails = {
				...getCurrentUser(),
				agentCode: null,
				customerCode: null,
			};
			setToLocalStorage(
				sessionKeys.USER_DETAILS,
				JSON.stringify(currentUserDetails)
			);
			return {
				agentDetails: null,
			};
		default:
			return state;
	}
}
